import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import EditorComponent from "../EditorComponent";
import {Box, Container} from "@mui/material";
import AddSection from "../AddSection";
import {useDispatch, useSelector} from "react-redux";
import {
    addSummary,
    removeSummary,
} from "../../../../redux/features/offerings/manageOfferingsSlice";

function Summary(props, ref) {

    const [sections, setSections] = useState([]);
    const editorRefs = useRef([]);
    const {venture: {summary}} = useSelector(state => state.manageOfferings);
    const dispatch = useDispatch();

    useEffect(() => {

    },[summary]);

    const submit = async () => {
        return Promise.all(editorRefs.current.map((ref) => {
            return ref.current?.submit();
        })).then((result) => {
            // let isFormValid = result.indexOf(false) === -1;
            // dispatch(setFormValidation({field: 'summary', value: isFormValid}));
            // return isFormValid;
        });
    }

    useImperativeHandle(ref, () => ({
       async submit() {
            return await submit();
        },
    }), [submit]);
    const removeSection = (id) => {
        editorRefs.current.splice(id, 1);
        dispatch(removeSummary(id));
    }
    const addSection = ({expand, id}) => {
        // editorRefs.current.push(React.createRef());
        dispatch(addSummary({id: sections.length}));
    };

    return (
        <Container maxWidth={'lg'}>
            <Box>
                {summary.map((section, index) => {

                    if (!editorRefs.current[index]) editorRefs.current.push(React.createRef());
                    const ref = editorRefs.current[index];
                    return <Box key={index}>
                        <EditorComponent ref={ref} removeSection={removeSection} id={index} key={index}/>
                    </Box>
                })}
                <AddSection id={sections.length} addSection={addSection}/>

            </Box>
        </Container>
    );
}

export default React.forwardRef(Summary);