import React, {useEffect, useImperativeHandle} from 'react';
import {Container, FormControl, } from "@mui/material";
import {Box} from "@mui/system";
import UploadImage from "../../../../common/UploadImage";
import {Controller, useForm} from "react-hook-form";
import InputField from "../../../../common/styled-components/InputField";
import {
    setFormValidation,
    setOfferingField,
    setVentureValue
} from "../../../../redux/features/offerings/manageOfferingsSlice";
import {useDispatch, useSelector} from "react-redux";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker} from "@mui/x-date-pickers";
import BasicSelect from "../../../../common/styled-components/SelectOption";
import industries from "../../../../utils/industries";
import MultipleSelectChip from "../../../../common/styled-components/MultipleSelectChip";
import OutlinedAdornmentInput from "../../../../common/styled-components/OutlinedAdornmentInput";
import {REGEX_POSITIVE_NUMBERS} from "../../../../utils/validateData";
import moment from "moment";
import ventureType from "../../../../utils/ventureType";

function General(props, ref) {
    const {venture} = useSelector(state => state.manageOfferings);
    const dispatch = useDispatch();
    const {
        control,
        handleSubmit,
        formState: {errors, isValid},
        setValue,
        setError,
        clearErrors,
        watch,
        trigger,
    } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        const formFields = watch();
        Object.keys(formFields).forEach((key) => {
            if (key in venture.offeringDetails) {
                setValue(key, venture.offeringDetails[key]);
            } else {
                setValue(key, venture[key]);
            }
        });

        if (props.isDraft) trigger();
    }, []);

    useEffect(() => {
        dispatch(setFormValidation({
            field: 'general',
            value: isValid,
        }));
    }, [isValid]);

    const submitFormAct = () => {
        handleSubmit(onSubmit)();
    };

    useImperativeHandle(ref, () => ({
        submit() {
            return submitFormAct();
        },
    }), [submitFormAct, errors]);

    const onSubmit = () => {};

    const handleLogoUpload = (event) => {
        dispatch(setVentureValue({field: 'logoUrl', value: event.logoUrl}));

        if (!event.logoUrl) {
            setError('logoUrl', { type: 'required', message: 'Logo is required' });
            setValue('logoUrl', null);
        } else {
            clearErrors('logoUrl');
            setValue('logoUrl', event);
        }
    }

    const handleCoverUpload = (event) => {
        dispatch(setVentureValue({field: 'coverUrl', value: event.coverUrl}));

        if (!event.coverUrl) {
            setError('coverUrl', { type: 'required', message: 'Cover is required' });
            setValue('coverUrl', null);
        } else {
            clearErrors('coverUrl');
            setValue('coverUrl', event);
        }
    }

    return (
        <Container maxWidth={'md'}>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2, marginBottom: 2}}>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off" sx={{maxWidth: 'sm', width: '100%', marginBottom: 2}}
                >
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'name'}
                                        value={venture.name}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setVentureValue({field: e.target.name, value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.name)}
                                        label="Venture name"
                                        id="outlined-start-adornment"
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="logoUrl"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <UploadImage
                                        onChange={(e) => handleLogoUpload(e)}
                                        existingImage={venture?.logoUrl}
                                        propName={'logoUrl'}
                                        title={'Upload logo'}
                                        name={'venture-logo'}
                                        propUrl={'logourl'}
                                        setValue={setValue}
                                        error={errors.logoUrl}
                                        sx={{mb: 2}}
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="coverUrl"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <UploadImage
                                        onChange={(e) => handleCoverUpload(e)}
                                        existingImage={venture?.coverUrl}
                                        propName={'coverUrl'}
                                        title={'Upload cover '}
                                        name={'venture-cover'}
                                        secondaryText={'min 1500px width'}
                                        propUrl={'coverUrl'}
                                        setValue={setValue}
                                        error={errors.coverUrl}
                                        sx={{mb: 2}}
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="description"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'description'}
                                        value={venture.description}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setVentureValue({field: e.target.name, value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.description)}
                                        label="Short summary (Max '' characters)"
                                        id="outlined-start-adornment"
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="industry"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <BasicSelect
                                        options={industries}
                                        name={'industry'}
                                        value={venture?.industry}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setVentureValue({field: 'industry', value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.industry)}
                                        label={'Industries'}
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="tags"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <MultipleSelectChip
                                        name={'tags'}
                                        options={industries}
                                        value={venture?.tags}
                                        onChange={
                                            (e, value) => {
                                                field.onChange(value.length > 0 ? value : null);
                                                dispatch(setVentureValue({field: 'tags', value: e.target.value}));
                                            }
                                        }
                                        onDelete={
                                            (e, value) => {
                                                field.onChange(value.length > 0 ? value : null);
                                                dispatch(setVentureValue({field: 'tags', value: value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.tags)}
                                        label="Tags (e. g. Fintech, SaaS, AI)"
                                        id="outlined-start-adornment"
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="valuation"
                            control={control}
                            rules={{required: true, min: 0, pattern: REGEX_POSITIVE_NUMBERS}}
                            render={
                                ({field}) =>
                                    <OutlinedAdornmentInput
                                        name={'valuation'}
                                        label="Valuation"
                                        error={Boolean(errors.valuation)}
                                        value={venture?.offeringDetails?.valuation}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setOfferingField({field: 'valuation', value: e.target.value}));
                                            }
                                        }
                                    />
                            }
                        />
                    </FormControl>
                    <div style={{display: 'flex'}}>
                        <FormControl sx={{flex: 1, mt: 1, mb: 1}}>
                            <Controller
                                name="offeringSize"
                                control={control}
                                rules={{required: true, min: 0, pattern: REGEX_POSITIVE_NUMBERS}}
                                render={
                                    ({field}) =>
                                        <OutlinedAdornmentInput
                                            name={'offeringSize'}
                                            label="Allocation amount"
                                            error={Boolean(errors.offeringSize)}
                                            value={venture?.offeringDetails?.offeringSize}
                                            onChange={
                                                (e) => {
                                                    field.onChange(e.target.value);
                                                    dispatch(setOfferingField({field: 'offeringSize', value: e.target.value}));
                                                }
                                            }
                                        />
                                }
                            />
                        </FormControl>
                        <FormControl sx={{flex: 1, mt: 1, mb: 1, marginLeft: '10px'}}>
                            <Controller
                                name="equity"
                                control={control}
                                rules={{required: true, min: 0, pattern: REGEX_POSITIVE_NUMBERS}}
                                render={
                                    ({field}) =>
                                        <OutlinedAdornmentInput
                                            name={'equity'}
                                            label="Allocation equity"
                                            adorment={'PERCENT'}
                                            error={Boolean(errors.equity)}
                                            value={venture?.offeringDetails?.equity}
                                            onChange={
                                                (e) => {
                                                    field.onChange(e.target.value);
                                                    dispatch(setOfferingField({field: 'equity', value: e.target.value}));
                                                }
                                            }
                                        />
                                }
                            />
                        </FormControl>
                    </div>
                    <div style={{display: 'flex'}}>
                        <FormControl sx={{flex: 1, mt: 1, mb: 1}}>
                            <Controller
                                name="ticketsTotal"
                                control={control}
                                rules={{required: true, min: 0, pattern: REGEX_POSITIVE_NUMBERS}}
                                render={
                                    ({field}) =>
                                        <InputField
                                            name={'ticketsTotal'}
                                            label="Allocated tokens amount"
                                            error={Boolean(errors.ticketsTotal)}
                                            value={venture?.offeringDetails?.ticketsTotal}
                                            onChange={
                                                (e) => {
                                                    field.onChange(e.target.value);
                                                    dispatch(setOfferingField({field: 'ticketsTotal', value: e.target.value}));
                                                }
                                            }
                                        />
                                }
                            />
                        </FormControl>
                        <FormControl sx={{flex: 1, mt: 1, mb: 1, marginLeft: '10px'}}>
                            <Controller
                                name="ticketSize"
                                control={control}
                                rules={{required: true, min: 0, pattern: REGEX_POSITIVE_NUMBERS}}
                                render={
                                    ({field}) =>
                                        <InputField
                                            name={'ticketSize'}
                                            label="Token price"
                                            error={Boolean(errors.ticketSize)}
                                            value={venture?.offeringDetails?.ticketSize}
                                            onChange={
                                                (e) => {
                                                    field.onChange(e.target.value);
                                                    dispatch(setOfferingField({field: 'ticketSize', value: e.target.value}));
                                                }
                                            }
                                        />
                                }
                            />
                        </FormControl>
                    </div>
                    <div style={{display: 'flex'}}>
                        <FormControl sx={{flex: 1, mt: 1, mb: 1}}>
                            <Controller
                                name="tokensPrefix"
                                control={control}
                                rules={{required: true}}
                                render={
                                    ({field}) =>
                                        <InputField
                                            name={'tokensPrefix'}
                                            label="Tokens prefix"
                                            error={Boolean(errors.tokensPrefix)}
                                            value={venture?.offeringDetails?.tokensPrefix}
                                            onChange={
                                                (e) => {
                                                    field.onChange(e.target.value);
                                                    dispatch(setOfferingField({field: 'tokensPrefix', value: e.target.value}));
                                                }
                                            }
                                        />
                                }
                            />
                        </FormControl>
                        <FormControl sx={{flex: 1, mt: 1, mb: 1, marginLeft: '10px'}}>
                            <Controller
                                name="ticketsPerPerson"
                                control={control}
                                rules={{required: true, min: 0, pattern: REGEX_POSITIVE_NUMBERS}}
                                render={
                                    ({field}) =>
                                        <InputField
                                            name={'ticketsPerPerson'}
                                            label="Max tokens per person"
                                            error={Boolean(errors.ticketsPerPerson)}
                                            value={venture?.offeringDetails?.ticketsPerPerson}
                                            onChange={
                                                (e) => {
                                                    field.onChange(e.target.value);
                                                    dispatch(setOfferingField({field: 'ticketsPerPerson', value: e.target.value}));
                                                }
                                            }
                                        />
                                }
                            />
                        </FormControl>
                    </div>
                    <div style={{display: 'flex'}}>
                        <FormControl sx={{flex: 1, mt: 1, mb: 1}}>
                            <Controller
                                name="startDate"
                                control={control}
                                rules={{required: true}}
                                render={
                                    ({field}) =>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                slotProps={{
                                                    textField: {
                                                        error: Boolean(errors.startDate),
                                                        InputProps: {
                                                            style: {
                                                                borderRadius: 0,
                                                            },
                                                        },
                                                    },
                                                }}
                                                value={venture?.startDate ? moment(venture?.startDate) : null}
                                                error={errors.startDate}
                                                onChange={(e) => {
                                                    field.onChange(e.format());
                                                    dispatch(setVentureValue({field: 'startDate', value: e.format()}));
                                                }}
                                                label={"Start date"}
                                            />
                                        </LocalizationProvider>
                                }
                            />
                        </FormControl>
                        <FormControl sx={{flex: 1, mt: 1, mb: 1, marginLeft: '10px'}}>
                            <Controller
                                name="dueDate"
                                control={control}
                                rules={{required: true}}
                                render={
                                    ({field}) =>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                slotProps={{
                                                    textField: {
                                                        error: Boolean(errors.dueDate),
                                                        InputProps: {
                                                            style: {
                                                                borderRadius: 0,
                                                            },
                                                        },
                                                    },
                                                }}
                                                value={venture?.dueDate ? moment(venture?.dueDate) : null}
                                                error={errors.dueDate}
                                                onChange={(e) => {
                                                    field.onChange(e.format());
                                                    dispatch(setVentureValue({field: 'dueDate', value: e.format()}));
                                                }}
                                                label={'Due date'}
                                            />
                                        </LocalizationProvider>
                                }
                            />
                        </FormControl>
                    </div>
                </Box>
            </Box>
        </Container>
    );
}

export default React.forwardRef(General);