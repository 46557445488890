import * as React from 'react';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Box} from "@mui/system";
import {useDispatch, useSelector} from "react-redux";
import { useState} from "react";

export default function AddIconModal(props) {

    const {onClose, selectedValue, open, setIcon} = props;
    const dispatch = useDispatch();
    const {icons} = useSelector(state => state.app);
    const [selectedIcon, setSelectedIcon] = useState(selectedValue);

    const handleClose = () => {
        onClose(selectedValue);
    };

    const selectIcon = (event) => {
        const name = event.target.dataset.tag;
        if (name === selectedIcon) {
            setSelectedIcon('');
        } else {
            setIcon(icons?.find(icon => icon.name === name));
            setSelectedIcon(name);
        }
        handleClose();
    }

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                Chose section icon
            </DialogTitle>
            <List sx={{
                p: 3,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: 2,
                flexWrap: 'wrap',
            }}>
                {icons?.map((icon, key) => (
                    <Box  key={key} sx={{
                        display: 'block',
                        position: 'relative',
                        width: '50px',
                        height: '50px',
                        zIndex: 111,
                        '&:hover> div': {
                            cursor: 'pointer',
                            backgroundColor: '#0000000f',
                            transition: 'all 0.3s ease-in-out',
                        },

                    }}>
                        <Box data-tag={icon.name} onClick={selectIcon} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            width: '55px',
                            height: '55px',
                            top: -5,
                            left: -5,
                            border: selectedIcon === icon.name ? '1px solid #000000' : '1px solid transparent',
                        }}></Box>

                        <img name={icon.name} onClick={()=>{}} src={icon.url} alt="" style={{
                            width: '45px',
                            height: '45px',
                        }}/>

                    </Box>
                ))}
            </List>
        </Dialog>
    );
}