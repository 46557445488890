import React, {useEffect, useState} from 'react';
import { Box, TextField, Typography, Divider, Container, CssBaseline, Link as LinkMui } from '@mui/material';
import { ReactComponent as ArrowForwardIcon } from '../../style/icons/arrow-right.svg';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import { useFormUniversal } from '../../utils/useFormUniversal';
import { ReactComponent as Logo } from '../../style/images/logo-dracon-club.svg';
import { sxStyles } from './sxStyles';
import { validateLinkedin } from '../../utils/validateData';
import ActionButton from '../../common/ActionButton';
import { registerUser } from "../../redux/features/auth/authUserSlice";
import { motion } from "framer-motion";
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { wait } from "@testing-library/user-event/dist/utils";
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import {sha512} from "js-sha512";
import {Theme_colors} from "../../style/theme_colors";
import {COMPANY_NAME} from "../../helpers/globals";

export default function SignUp() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const { isLoading, hasError } = useSelector(state => state.authUser);
    const [isChecked, setIsChecked] = useState(false);
    const { captchaToken } = useContext(AppContext);
    const initialFormState = {
        name: {
            value: null,
            validationRules: {
                required: true,
                validateName: true,
            }
        },
        lastName: {
            value: null,
            validationRules: {
                required: true,
                validateName: true,
            }
        },
        email: {
            value: null,
            validationRules: {
                required: true,
                validateEmail: true,
            }
        },
        password: {
            value: null,
            helperText: '',
            validationRules: {
                required: true,
                validatePassword: true,
            }
        },
        linkedin: {
            value: "",
            validationRules: {
                required: false,
            }
        }
    }
    const [
        formValues,
        setFormValues
    ] = useState(initialFormState);
    const { isFormValid, errors, updateValues } = useFormUniversal(formValues, setFormValues);
    const [sendMeEmails, setSendMeEmails] = useState(false);
    const [agreement, setAgreement] = useState(true);
    let validLinkedin = formValues.linkedin.value !== "" ? validateLinkedin(formValues.linkedin.value) : null;


    const handleChange = (e) => {
        const { name, value } = e.target;
        updateValues({ [name]: { ...formValues[name], value } });
        setFormValues(prev => {
            return {
                ...prev, ...{
                    [name]: { ...prev[name], value: value }
                }
            }
        })
    }

    const submitRegistration = (e) => {
        e.preventDefault();
        setIsChecked(true);
        if (isFormValid && captchaToken) {
            setDisabled(true);

            let data = {
                agreement: agreement,
                name: formValues.name.value,
                lastName: formValues.lastName.value,
                email: formValues.email.value,
                password: sha512(formValues.password.value),
                sendMeEmails: sendMeEmails,
                linkedin: (validLinkedin !== null && !validLinkedin.error) ? formValues.linkedin.value : "",
            }

            dispatch(registerUser(data))
                .then(async (resp) => {
                    if (!resp.error) {
                        setFormValues(initialFormState);
                    }
                    await wait(500);
                    navigate('/')
                })
                .then(() => {
                    setDisabled(false);
                })
        } else {
            for (let field in formValues) {
                if (formValues[field].value === null) {
                    updateValues({ [field]: { ...formValues[field], value: '' } });
                }
            }
        }
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={sxStyles.motionDiv}
        >
            <Container maxWidth="xs" sx={{pt: '32px'}}>
                <CssBaseline />
                <Box sx={sxStyles.logo}><Logo /></Box>
                <Typography
                    sx={sxStyles.subTitle}
                    display="block"
                >
                    {COMPANY_NAME} grants you access to the best
                    ventures and cherry-picked startups that we work with.
                </Typography>
                <Typography variant='h5' align="left" sx={sxStyles.title}>Sign up</Typography>

                <form style={sxStyles.formItem} onSubmit={submitRegistration}>
                    <TextField
                        name={'name'}
                        sx={sxStyles.inputItem}
                        fullWidth
                        variant="outlined"
                        label={"First name"}
                        autoComplete="First name"
                        autoFocus
                        error={isChecked && errors.name.error}
                        helperText={(isChecked && errors.name.helperText) ? errors.name.helperText : null}
                        value={formValues.name.value || ""}
                        onChange={handleChange}
                        InputProps={{ inputProps: { style: { textAlign: 'start', }, }, style: { borderRadius: 0, }, }}
                    />
                    <TextField
                        name={'lastName'}
                        sx={sxStyles.inputItem}
                        fullWidth
                        variant="outlined"
                        label={"Last name"}
                        autoComplete="Last name"
                        error={isChecked && errors.lastName.error}
                        helperText={(isChecked && errors.lastName.helperText) ? errors.lastName.helperText : null}
                        value={formValues.lastName.value || ""}
                        onChange={handleChange}
                        InputProps={{ inputProps: { style: { textAlign: 'start', }, }, style: { borderRadius: 0 }, }}
                    />
                    <TextField
                        name={'email'}
                        error={isChecked && errors.email.error}
                        helperText={(isChecked && errors.email.helperText) ? errors.email.helperText : null}
                        sx={sxStyles.inputItem}
                        fullWidth
                        variant="outlined"
                        label={"Email"}
                        autoComplete="email"
                        value={formValues.email.value || ""}
                        onChange={handleChange}
                        InputProps={{ inputProps: { style: { textAlign: 'start', }, }, style: { borderRadius: 0 }, }}
                    />
                    <TextField
                        name={'password'}
                        sx={sxStyles.inputItem}
                        fullWidth
                        variant="outlined"
                        label={"Password"}
                        autoComplete="password"
                        type='password'
                        helperText={(isChecked && errors.password.helperText) ? errors.password.helperText : null}
                        error={isChecked && errors.password.error}
                        value={formValues.password.value || ""}
                        onChange={handleChange}
                        InputProps={{ inputProps: { style: { textAlign: 'start', }, }, style: { borderRadius: 0 }, }}
                    />
                    <TextField
                        name={'linkedin'}
                        sx={{ ...sxStyles.inputItem, mb: 3 }}
                        fullWidth
                        variant="outlined"
                        label={"Linkedin profile link (Optional)"}
                        autoComplete="confirm password"
                        helperText={validLinkedin?.helperText}
                        error={validLinkedin?.error}
                        value={formValues.linkedin.value || ""}
                        onChange={handleChange}
                        InputProps={{ inputProps: { style: { textAlign: 'start', }, }, style: { borderRadius: 0, }, }}
                    />
                    <Box sx={sxStyles.checkbox}>
                        <FormControlLabel
                            control={<Checkbox
                                icon={<CheckBoxOutlineBlankSharpIcon style={{ color: Theme_colors.blackSecond }} />}
                                checkedIcon={<CheckBoxSharpIcon />}
                                size="small"
                                required={true}
                                defaultChecked
                                onChange={(e) => setAgreement(e.target.checked)}
                            />}
                        />
                        <Typography
                            variant="caption"
                            display="block"
                            sx={sxStyles.captions}
                        >
                            I agree to the
                            <Link to='/auth/legal'
                                style={sxStyles.linkTerms}>Terms of Use</Link>
                            and 
                            <Link to='/auth/privacy-policy'
                                style={sxStyles.linkTerms}>Privacy Policy</Link>
                        </Typography>
                    </Box>
                    <Box sx={{ ...sxStyles.checkbox, '& .MuiFormControlLabel-root': { marginTop: -3, marginRight: 0 }, }}>
                        <FormControlLabel
                            control={<Checkbox
                                icon={<CheckBoxOutlineBlankSharpIcon style={{ color: Theme_colors.blackSecond }} />}
                                checkedIcon={<CheckBoxSharpIcon />}
                                size="small"
                                onChange={(e) => setSendMeEmails(e.target.checked)}
                            />}
                        />
                        <Typography
                            variant="caption"
                            display="block"
                            sx={sxStyles.captions}
                        >
                            Yes, I would like to receive informational emails for new
                            Startups and Services by {COMPANY_NAME}

                        </Typography>
                    </Box>
                    <ActionButton
                        type="submit"
                        isLoading={isLoading}
                        variant={"contained"}
                        sx={sxStyles.button}
                        disabled={!agreement || disabled}
                        onClick={submitRegistration}>
                        CONTINUE
                    </ActionButton>

                </form>
                <Typography
                    sx={sxStyles.formItem}
                    variant="caption"
                    display="block"
                >
                    This site is protected by reCAPTCHA and the Google
                    <LinkMui href='https://policies.google.com/privacy' target={'_blank'}
                        style={sxStyles.linkDecoration}> Privacy Policy </LinkMui>
                    and
                    <LinkMui href='https://policies.google.com/terms' target={'_blank'}
                        style={sxStyles.linkDecoration}>Terms of service </LinkMui>
                    apply.
                </Typography>

                <Divider sx={sxStyles.formItem} />

                <Typography
                    sx={sxStyles.linkItem}
                >
                    Already have an account?
                    <Link to='/auth/sign-in' style={{ ...sxStyles.link, margin: '0 5px' }}>Log in
                        <ArrowForwardIcon style={sxStyles.fixArrow} />
                    </Link>
                </Typography>
            </Container>
        </motion.div>
    );
}

SignUp.propTypes = {};

