export const publishedColumns = [
    {field: 'name', headerName: 'Ventures', sortable: false, width: 160},
    {field: 'valuation', headerName: 'Valuation', sortable: false, width: 160},
    {field: 'ticketSize', headerName: 'Token price', sortable: false, width: 160},
    {field: 'offeringSize', headerName: 'Offering size', sortable: false, width: 160},
    {field: 'startDate', headerName: 'Start date', sortable: false, width: 125},
    {field: 'dueDate', headerName: 'Due date', sortable: false, width: 125},
    {field: 'submittedInterestAmount', headerName: 'Submitted interest', align: "right", headerAlign: "right", sortable: false, width: 160},
    {field: 'investedAmount', headerName: 'Invested', sortable: false, align: "right", headerAlign: "right", width: 160},
    {field: 'ticketsSold', headerName: 'Tokens sold', sortable: false, align: "right", headerAlign: "right", width: 160},
    {field: 'investors', headerName: 'Investors', sortable: false, align: "right", headerAlign: "right", width: 140},
    {field: 'status', headerName: 'Status', sortable: false, align: "right", width: 110},
    {field: 'publishedMenu', headerName: '', sortable: false, width: 72},
];

export const draftColumns = [
    {field: 'name', headerName: 'Ventures', sortable: false, width: 160},
    {field: 'draftMenu', headerName: '', sortable: false, width: 72},
];

export const archivedColumns = [
    {field: 'name', headerName: 'Ventures', sortable: false, width: 160},
    {field: 'startDate', headerName: 'Start date', sortable: false, width: 125},
    {field: 'dueDate', headerName: 'Due date', sortable: false, width: 125},
    {field: 'ticketsSold', headerName: 'Tokens sold', sortable: false, width: 125},
    {field: 'investors', headerName: 'Investments', sortable: false, width: 120},
    {field: 'submittedInterestAmount', headerName: 'Submitted interest', align: "right", headerAlign: "right", sortable: false, width: 140},
    {field: 'investedAmount', headerName: 'Invested', sortable: false, align: "right", headerAlign: "right", width: 140},
];

export const investorsColumns = [
    {field: 'address', headerName: 'Address', width: 320, sortable: false},
    {field: 'date', headerName: 'Date', width: 160, sortable: true},
    {field: 'twitter', headerName: 'Twitter (X)', sortable: false, width: 160},
    {field: 'telegram', headerName: 'Telegram', sortable: false, width: 160},
    {field: 'tickets', headerName: 'Tokens', sortable: false, width: 160, align: "right"},
    {field: 'equity', headerName: 'Allocation share', width: 160, align: "right", headerAlign: "right", sortable: false},
    {field: 'saft', headerName: 'SAFT', width: 100, align: "right", headerAlign: "right", sortable: false},
    {field: 'submittedInterest', headerName: 'Submitted interest', width: 160, align: "right", headerAlign: "right", sortable: false},
    {field: 'invested', headerName: 'Invested', width: 160, align: "right", headerAlign: "right", sortable: false},
    {field: 'reference', headerName: 'Txn hash', width: 100, sortable: false, align: "right", headerAlign: "right"},
    {field: 'status', headerName: 'Status', width: 160, align: "right", headerAlign: "right", sortable: true},
    {field: 'action', headerName: 'Actions', width: 72, sortable: false, align: "right", headerAlign: "right"},
];
