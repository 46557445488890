import React, {createContext, useReducer} from "react";
import appReducer, {initialState} from "./appReducer";
import {useMediaQuery, useTheme} from "@mui/material";

export const AppContext = createContext({});

export function AppProvider(props) {
    const [state, dispatch] = useReducer(appReducer, initialState);
    const theme = useTheme();
    // xs, extra-small: 0px
    // sm, small: 600px
    // md, medium: 900px
    // lg, large: 1200px
    // xl, extra-large: 1536px

    const screen = {
        sm: useMediaQuery(theme.breakpoints.down("sm")),
        md: useMediaQuery(theme.breakpoints.down("md")),
        lg: useMediaQuery(theme.breakpoints.down("lg")),
        xl: useMediaQuery(theme.breakpoints.down("xl")),
        xxl: useMediaQuery(theme.breakpoints.down("xxl")),
    }

    const setTheme = (themeId) => {
        dispatch({type: 'setThemeId', payload: themeId});
    }

    const setEnv = (env) => {
        dispatch({type: 'setEnv', payload: env});
    }


    const value = {
        screen,
        themeId: state.themeId,
        setTheme,
        env: state.env,
        setEnv,
    }
    return (<AppContext.Provider {...props} value={value}> {props.children}</AppContext.Provider>)
}

export default AppProvider;

