import {Theme_colors} from "../../../../style/theme_colors";

export default {
    ventureWrapper: {
        display: 'flex', flexDirection: 'row', alignItems: 'center',
    }, logo: {
        borderRadius: '40px 40px 40px 40px', marginRight: '8px'
    }, date: {
        fontSize: '16px', color: Theme_colors.black,
    }, badge: {
        fontSize: '13px',
    }, actionButton: {
        fontSize: '14px',
        color: Theme_colors.black,
        backgroundColor: Theme_colors.white,
        fontStyle: 'normal',
        fontWeight: '500',
        borderRadius: 0,
        border: `1px solid ${Theme_colors.greyThird}`,
        boxShadow: 'none',
        height: '40px',
        "&:hover": {
            backgroundColor: Theme_colors.white, boxShadow: 'none', border: `1px solid ${Theme_colors.greyThird}`,
        },
    }, logoUrl: {
        border: '1px solid '+Theme_colors.greyThird,
        width: '32px',
        height: '32px',
        textAlign: 'center',
        verticalAlign: 'middle',
        borderRadius: '25px',
        display: 'inline-block',
        lineHeight: '32px',
    }, name: {
        ml: 1, fontSize: '14px', fontWeight: 500, color: Theme_colors.black
    },
}