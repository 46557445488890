import React from 'react';
import {InputLabel} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import {USDT} from "../../helpers/globals";

function OutlinedAdornmentInput({name, value, error, venture, onChange, label, adorment = 'USDT', ...props }) {
    const adorments = {
        USDT: USDT,
        PERCENT: '%',
    }

    return (
        <>
            <InputLabel htmlFor="outlined-adornment-valuation">{label}</InputLabel>
            <OutlinedInput
                sx={{
                    borderRadius: 0,
                }}
                name={name}
                value={value}
                onChange={onChange}
                fullWidth={true}
                error={error}
                id="outlined-adornment-valuation"
                startAdornment={<InputAdornment position="start">{adorments[adorment]}</InputAdornment>}
                label={label}
            />
        </>
    );
}

export default OutlinedAdornmentInput;