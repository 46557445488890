const months = ["Jan","Feb","March","April","May","June","July","Aug","Sept","Oct","Nov","Dec"];

export const calcDate = (date) => {
    const d = new Date(date);
    let day = d.getDate();
    let month = months[d.getMonth()];
    let year = d.getFullYear();

    let format = `${day} ${month} ${year}`; 
    return format;
}

export const calcDateAndTime = (date) => {
    const d = new Date(date);
    let day = d.getDate();
    let month = months[d.getMonth()];
    let year = d.getFullYear();
    let hours = d.getHours();
    let minutes = d.getMinutes();
    if(hours < 10){
        hours = `0${hours}`;
    }
    if(minutes < 10){
        minutes = `0${minutes}`;
    }
    let format = `${day} ${month} ${year}, ${hours}:${minutes}`;
    return format;
}