import React, {useState} from 'react';
import {Box, Button, Divider, MenuList} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Theme_colors} from "../style/theme_colors";
import { ReactComponent as Dots } from "../style/icons/dots.svg";
import { ReactComponent as BlackDots } from "../style/icons/black-dots.svg";

const sxStyles = {
    listItem: {
        '&:hover': { backgroundColor: 'inherit', },
        p: '0 16px',
        fontWeight: '500',
        lineHeight: '16px',
        fontSize: '16px',
        borderBottom: '1px solid #E5E5E5',
        width: '200px',
        pt: 2,
        pb: 2,

    },
    button: {
        borderRadius: 0,
        minWidth: '40px',
        width: '40px',
        height: '40px',
        border: `1px solid ${Theme_colors.greyThird}`,
        '&:hover': { backgroundColor: 'inherit', }
    },
}
function MenuButton({elements, rowId, id = 'button'}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const opp = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ position: 'absolute', right: '16px' }}>
            <Button
                disableRipple
                sx={sxStyles.button}
                id={id}
                aria-controls={opp ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={opp ? 'true' : undefined}
                onClick={handleClick}
            >
                {!opp ? <BlackDots /> : <Dots />}
            </Button>
            <Menu
                MenuListProps={{ disablePadding: true }}
                id="menu"
                anchorEl={anchorEl}
                open={opp}
                onClose={handleClose}
            >
                <MenuList sx={{ boxShadow: false, padding: 0 }}>
                    {
                        elements.map((el, index) => {

                            return (
                                <MenuItem key={index} onClick={()=>{
                                    el.onClick(rowId)
                                    handleClose()
                                }} sx={{ ...sxStyles.listItem,     '&:hover': { backgroundColor: 'inherit', color: Theme_colors.dragonOrange, } }}>
                                    {
                                        el.title
                                    }
                                </MenuItem>
                            )
                        })
                    }


                </MenuList>
            </Menu>
        </Box>
    );
}

export default MenuButton;