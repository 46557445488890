import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from 'axios';
import {showNotification} from "../notifications/notificationSlice";
import {serviceEnvBaseUrl} from "../../../helpers/helpers";
import {useJwt} from "react-jwt";

const initialState = {
    accessToken: sessionStorage.getItem('token'), isLoading: false, hasError: false
}
export const login = createAsyncThunk('auth/sign-in', async ({email, password}, {dispatch, rejectWithValue}) => {
        const signInOutputObj = {
            email: email.trim(), password: password.trim(),
        }

        return await axios.post(serviceEnvBaseUrl() + `/auth-admin`, {
            ...signInOutputObj
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                dispatch(showNotification({message: "Successful login", type: "success"}));
                return response.data;
            })
            .catch((error) => {
                dispatch(showNotification({message: error.response.data.message || error.message, type: "error"}));
                return rejectWithValue(error.message);
            });
    }

    //TODO After release we have to find out do we have to change authentication with cognito
    //     const {signIn} = useAws();
    //     return await signIn(email.trim(), password.trim())
    //         .catch((error) => {
    //             console.log(error)
    //             dispatch(showNotification({message: error?.response?.data?.error || error.message, type: "error"}));
    //             return rejectWithValue(error.message);
    //         });
    // }
)

export const registerUser = createAsyncThunk('auth/sign-up', async (data, {dispatch, rejectWithValue}) => {
    const signUpOutputObj = {
        email: data.email.trim(),
        firstName: data.name.trim(),
        lastName: data.lastName.trim(),
        sendMeEmails: data.sendMeEmails,
        linkedInUrl: data.linkedin.trim(),
        password: data.password.trim(),
    }

    return await axios.post(serviceEnvBaseUrl() + `/investors`, {
        ...signUpOutputObj
    }, {
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            dispatch(showNotification({message: "Successful registration", type: "success"}));
            return response.data;
        })
        .catch((error) => {
            dispatch(showNotification({message: error.response.data.message || error.message, type: "error"}));
            return rejectWithValue(error.message);
        });

})
export const logout = createAction('logout');

export const authUserSlice = createSlice({
    name: 'auth', initialState, reducers: {}, extraReducers: (builder) => {
        builder.addCase(logout, (state, action) => {
            sessionStorage.clear();
            state.accessToken = sessionStorage.getItem('token');
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.accessToken = action.payload?.token;
            console.log(sessionStorage.getItem('token'))
            sessionStorage.setItem('token', state.accessToken);
            state.isLoading = false;
            state.hasError = false;
        })
        builder.addCase(login.rejected, (state, action) => {
            state.hasError = true;
            state.isLoading = false;
        })
        builder.addCase(login.pending, (state, action) => {
            state.hasError = false;
            state.isLoading = true;
        })
    }
});

export const {} = authUserSlice.actions

export default authUserSlice.reducer