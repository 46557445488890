import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
    closeModal,
} from "../../redux/features/notifications/modalSlice";
import {DialogActions, DialogContent} from "@mui/material";
import {Theme_colors} from "../../style/theme_colors";

export default function Modal({content, view, actions, title, setOpen, maxWidth, info,...other}) {
    const dispatch = useDispatch();
    const {open} = useSelector(state => state.modal);
    const Content = content;
    const View = view;
    const Actions = actions;
    const handleClose = async (event, reason) => {
        if (setOpen) {
            setOpen(false);
        }
        if (reason === 'clickaway') {
            return;
        }
        await dispatch(closeModal());
    }

    return (<BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}>
            {content ? <Content info={info}/> : <>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {View ? <View/> : null}
                </DialogContent>
                {Actions ? <DialogActions ><Actions/></DialogActions> : null}
            </>}


        </BootstrapDialog>);
}

function BootstrapDialogTitle({children, onClose, ...other}) {

    return (<DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (<IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>) : null}
        </DialogTitle>);
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node, onClose: PropTypes.func.isRequired,
};
const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2), borderBottom: 0,
    }, '& .MuiDialogActions-root': {
        padding: theme.spacing(1), backgroundColor: Theme_colors.greySeventh,
    }, '& .MuiPaper-rounded': {
        borderRadius: 0,
    },'& .MuiDialog-paper': {
        width: '100%', maxWidth: 'sm',
    },
}));