import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {decodeToken, isExpired, useJwt} from "react-jwt";
import {useEffect} from "react";


const RequireAuth = ({allowedRoles, routeName}) => {

    const location = useLocation();
    const navigate = useNavigate();
    const {accessToken} = useSelector(state => state.authUser);
    const decodedToken = decodeToken(accessToken);
    const isMyTokenExpired = isExpired(accessToken);

    useEffect(() => {
        if (accessToken) {
            if (decodedToken && isMyTokenExpired) {
                navigate('/auth/sign-in')
            } else if (decodedToken && !isMyTokenExpired && sessionStorage.redirect) {
                const redirectLink = sessionStorage.redirect;
                sessionStorage.redirect = '';
                navigate(redirectLink);
            }
        }else {
            navigate('/auth/sign-in')
        }
    }, [accessToken, decodeToken, isExpired])

    return <Outlet/>
}

export default RequireAuth