export const initialState = {
    env: '',
    themeId: 1,
    captchaToken: null,
};

const appReducer = (state, action) => {
    switch (action.type) {
        case 'setEnv':
            return {...state, env: action.payload};
        case 'setThemeId':
            return {...state, themeId: action.payload};
        default:
            return state;
    }
}

export default appReducer;