import React, {useEffect, useImperativeHandle, useState} from 'react';
import {Container, Divider, FormControl, Typography} from "@mui/material";
import {Box} from "@mui/system";
import UploadDocument from "../../../../common/UploadDocument";
import VerticalLine from "../../../../common/VerticalLine";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {setWorkflowSettingsDirect, setFormValidation} from "../../../../redux/features/offerings/manageOfferingsSlice";
import {Theme_colors} from "../../../../style/theme_colors";
import InputField from "../../../../common/styled-components/InputField";
import ventureType from "../../../../utils/ventureType";

const sxStyles = {
    uploadDocument : {
        maxWidth: 'sm',
        width: '100%',
        marginBottom: 2,
        border: 'none',
    },
};

function Workflow(props, ref) {
    const dispatch = useDispatch();
    const {
        control,
        handleSubmit,
        formState: {errors, isValid},
        setValue,
        setError,
        clearErrors,
        watch,
        trigger,
    } = useForm({
        mode: "onChange"
    });
    const {venture: {workflowSettings}} = useSelector(state => state.manageOfferings);
    const [switchState, setSwitchState] = useState(true);

    useEffect(() => {
        const formFields = watch();
        Object.keys(formFields).forEach((key) => {
            setValue(key, workflowSettings[key]);
        });

        if (props.isDraft) trigger();
    }, []);

    useEffect(() => {
        dispatch(setFormValidation({
            field: 'workflowSettings',
            value: isValid,
        }));
    }, [isValid]);

    const submitFormAct = () => {
        handleSubmit(onSubmit)();
    };

    useImperativeHandle(ref, () => ({
        submit() {
            return submitFormAct();
        },
    }), [submitFormAct, errors]);

    const onSubmit = () => {};

    const handleSaftDocumentUpload = (event) => {
        if (!event.saftContract) {
            setError('saftContract', { type: 'required', message: 'Saft contract is required' });
            setValue('saftContract', null);
        } else {
            clearErrors('saftContract');
            setValue('saftContract', event);
        }
    }

    return (
        <Container maxWidth={'md'}>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2, marginBottom: 2}}>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off" sx={{maxWidth: 'sm', width: '100%', marginBottom: 2}}
                >
                    <Typography sx={{ textAlign: 'center', marginTop: 2, marginBottom: 5 }}>
                        By following the indicated workflow, you can effectively manage and guide the investor's experience
                        throughout the project.
                    </Typography>
                    <Divider/>
                    <Typography variant={'h6'} sx={{ textAlign: 'center', marginTop: 5, marginBottom: 0 }}>
                        User Sign up & Connect wallet
                    </Typography>
                    <VerticalLine/>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #E0E0E0', maxWidth: 'sm', width: '100%' }}>
                            <Typography variant={'h6'} sx={{ textAlign: 'center', margin: 3 }}>Admin approval</Typography>
                            {/*<FormControlLabel*/}
                            {/*    control={*/}
                            {/*        <Switch*/}
                            {/*            sx={{display: 'flex', alignItems: 'center' }}*/}
                            {/*            size={'small'}*/}
                            {/*            width={56}*/}
                            {/*            height={24}*/}
                            {/*            handleDiameter={14}*/}
                            {/*            checkedIcon={<div style={{ color: 'white', paddingTop: '2px', paddingLeft: '10px', fontSize: '14px' }}>ON</div>}*/}
                            {/*            uncheckedIcon={<div style={{ color: 'white', paddingTop: '2px', paddingLeft: '3px', fontSize: '14px' }}>OFF</div>}*/}
                            {/*            checked={workflowSettings?.adminApprovalRequired}*/}
                            {/*            onChange={() => dispatch(setWorkflowSettingsDirect({field: 'adminApprovalRequired', value: !workflowSettings?.adminApprovalRequired}))}*/}
                            {/*        />*/}
                            {/*    }*/}
                            {/*/>*/}
                        </Box>
                    </Box>
                    <VerticalLine/>
                    <Typography variant={'h6'} sx={{ textAlign: 'center', marginTop: 2, marginBottom: 0 }}>
                        Investment flow
                    </Typography>
                    <VerticalLine/>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: !('saftContract' in errors)
                                ? '1px solid ' + Theme_colors.greyThird
                                : '1px solid ' + Theme_colors.alert,
                        }}
                    >
                        <Controller
                            name="saftContract"
                            control={control}
                            rules={{required: workflowSettings.saftContractRequired}}
                            render={
                                ({field}) =>
                                    <UploadDocument
                                        active={workflowSettings.saftContractRequired}
                                        sx={sxStyles.uploadDocument}
                                        onChange={(event) => handleSaftDocumentUpload(event)}
                                        existingDocument={workflowSettings.saftContract}
                                        propName={'saftContract'}
                                        name={'SAFT document upload'}
                                        setValue={setValue}
                                        error={errors.saftContract}
                                        border={false}
                                    />
                            }
                        />
                        {/*<FormControlLabel*/}
                        {/*    control={*/}
                        {/*        <Switch*/}
                        {/*            sx={{ display: 'flex', alignItems: 'center' }}*/}
                        {/*            size={'small'}*/}
                        {/*            width={56}*/}
                        {/*            height={24}*/}
                        {/*            handleDiameter={14}*/}
                        {/*            checkedIcon={<div style={{*/}
                        {/*                color: 'white',*/}
                        {/*                paddingTop: '2px',*/}
                        {/*                paddingLeft: '10px',*/}
                        {/*                fontSize: '14px'*/}
                        {/*            }}>ON</div>} // Custom text for ON state*/}
                        {/*            uncheckedIcon={<div style={{*/}
                        {/*                color: 'white',*/}
                        {/*                paddingTop: '2px',*/}
                        {/*                paddingLeft: '3px',*/}
                        {/*                fontSize: '14px'*/}
                        {/*            }}>OFF</div>} // Custom text for OFF state*/}
                        {/*            checked={workflowSettings.saftContractRequired}*/}
                        {/*            onChange={() => dispatch(setWorkflowSettingsDirect({field: 'saftContractRequired', value: !workflowSettings?.saftContractRequired}))}*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*/>*/}
                    </Box>
                    <VerticalLine/>
                    <Typography variant={'h6'} sx={{ textAlign: 'center', marginTop: 2, marginBottom: 0 }}>
                        Payment bill
                    </Typography>
                    <VerticalLine/>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="walletForTransfer"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'walletForTransfer'}
                                        value={workflowSettings.walletForTransfer}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setWorkflowSettingsDirect({field: 'walletForTransfer', value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.walletForTransfer)}
                                        label="Wallet for transfer"
                                        id="outlined-start-adornment"
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="stepOne"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'stepOne'}
                                        value={workflowSettings.stepOne}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setWorkflowSettingsDirect({field: 'stepOne', value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.stepOne)}
                                        label="TGE"
                                        id="outlined-start-adornment"
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="stepTwo"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'stepTwo'}
                                        value={workflowSettings.stepTwo}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setWorkflowSettingsDirect({field: 'stepTwo', value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.stepTwo)}
                                        label="Token distribution event (based on claiming)"
                                        id="outlined-start-adornment"
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="stepThree"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'stepThree'}
                                        value={workflowSettings.stepThree}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setWorkflowSettingsDirect({field: 'stepThree', value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.stepThree)}
                                        label="Vesting period"
                                        id="outlined-start-adornment"
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="stepFour"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'stepFour'}
                                        value={workflowSettings.stepFour}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setWorkflowSettingsDirect({field: 'stepFour', value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.stepFour)}
                                        label="Cliff"
                                        id="outlined-start-adornment"
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="trnDueTime"
                            control={control}
                            rules={{required: true}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'trnDueTime'}
                                        value={workflowSettings.trnDueTime}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setWorkflowSettingsDirect({field: 'trnDueTime', value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.trnDueTime)}
                                        label="Trn due time (minutes)"
                                        id="outlined-start-adornment"
                                    />
                            }
                        />
                    </FormControl>
                </Box>
            </Box>
        </Container>
    );
}

export default React.forwardRef(Workflow);