import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Box} from "@mui/system";
import {Container} from "@mui/material";
import './Documents.scss';
import AddDocumentView from "../AddDocumentView";
import AddSection from "../AddSection";
import {useDispatch, useSelector} from "react-redux";
import {
    removeDocument,
    setDocument,
} from "../../../../redux/features/offerings/manageOfferingsSlice";


function Documents(props, ref) {
    const [documentSections, setDocumentSections] = useState([]);
    const sectionsRefs = useRef([]);
    const dispatch = useDispatch();
    const {venture: {documents}} = useSelector(state => state.manageOfferings);
    const [invalidDocuments, setInvalidDocuments] = useState([]);

    useImperativeHandle(ref, () => ({
        submit() {
            return submit();
        },
    }), []);

    useEffect(() => {
        if (documents && documents.length > 0) {
            sectionsRefs.current = [];
            documents.forEach((document, index) => {
                if (!document.isValidated) {
                    setInvalidDocuments([...invalidDocuments, index]);
                }
                sectionsRefs.current.push(React.createRef());
            })
        }
    }, [documents])

    const removeDocumentSection = (id) => {
        dispatch(removeDocument(id));
        const docRefs = [...sectionsRefs.current];
        docRefs.splice(id, 1);
        sectionsRefs.current = docRefs;
    }
    const submit = () => {
        sectionsRefs.current.forEach((ref) => {
            ref.current.submit();
        })
    }
    const addDocumentSection = (props) => {
        const id = props.id;
        const ref = React.createRef();
        sectionsRefs.current.push(ref);
        dispatch(setDocument())
    }

    return (
        <Container maxWidth={'md'}>
            <Box>
                {
                    documents?.map((ref, index) => {
                        return (
                            <Box key={index}>
                                <AddDocumentView ref={sectionsRefs.current[index]} sectionsRefs={sectionsRefs}
                                                 key={index}
                                                 id={index}
                                                 removeDocumentSection={removeDocumentSection}/>
                            </Box>
                        )
                    })
                }
                <AddSection id={documentSections.length} addSection={addDocumentSection}/>
            </Box>
        </Container>
    );
}

export default forwardRef(Documents)