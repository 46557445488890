import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Box} from "@mui/system";
import {DialogActions, DialogContent, TextField} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import ActionButton from "../../../common/ActionButton";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch} from "react-redux";
import {closeModal} from "../../../redux/features/notifications/modalSlice";
import {useNavigate} from "react-router-dom";
import {addVenture, editVenture, showVentureModal} from "../../../redux/features/offerings/manageOfferingsSlice";
import ventureType from "../../../utils/ventureType";

function BootstrapDialogTitle({children, onClose, ...other}) {

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

function AddVentureView(props) {

    const dispatch = useDispatch();
    const [name, setName] = React.useState('');
    const disabled = name?.length < 5;

    const handleSetName = (event) => {
        setName(event.target.value);
    }
    const handleAddVenture = () => {
        dispatch(closeModal());
        dispatch(addVenture({name: name})).then((resp) => {
            dispatch(showVentureModal({id: resp.payload.id, name: resp.payload.name, type: ventureType.NEW}));
        });
    }

    return (
        <Box>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => dispatch(closeModal())}>
                Add new venture
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <TextField onChange={handleSetName} fullWidth={true} id="venture-name" label="Venture name"
                           variant="outlined"/>
            </DialogContent>

            <DialogActions>
                <ActionButton variant={'outlined'} onClick={() => dispatch(closeModal())}>CANCEL</ActionButton>
                <ActionButton disabled={disabled} onClick={handleAddVenture} autoFocus>
                    ADD VENTURE
                </ActionButton>
            </DialogActions>
        </Box>
    );
}

export default AddVentureView;