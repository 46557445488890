import {useCallback, useEffect, useRef, useState} from "react";
import {checkForError, validationRulesData} from './validateData';

export function useFormUniversal(inputValues) {
    const [isFormValid, setIsFormValid] = useState(false);
    const [errors, setErrors] = useState(inputValues);
    const [formValues, setFormValues] = useState(inputValues);
    const formRef = useRef(formValues);
    const validRef = useRef(isFormValid);

    useEffect(() => {
        validRef.current = isFormValid;
    }, [isFormValid]);

    const updateField = (field, value) => {
        setFormValues(prev => {
            return {
                ...prev, ...{
                    [field]: {...prev[field], value: value}
                }
            }
        })
        formRef.current = {...formRef.current, ...{[field]: {...formRef.current[field], value: value}}};
    }

    const updateValues = (updatedValues) => {
        setFormValues(prev => {
            return {
                ...prev, ...updatedValues
            }
        })
        formRef.current = {...formRef.current, ...updatedValues};
    }
    const submit = useCallback(() => {
        // This function can access the updated value of `count`
        for (let field in formRef.current) {
            if (formRef.current[field].value === null) {
                updateValues({[field]: {...formValues[field], value: ''}});
            }
        }
        return {isFormValid: validRef.current};
    }, [formRef.current]);

    useEffect(() => {
        for (const validationFiledName in formValues) {
            const validationRules = formValues[validationFiledName].validationRules;

            if (validationRules) {
                let errorObject = {
                    helperText: null, error: false, value: null,
                };

                for (const rule of Object.keys(validationRules)) {
                    //validation rule is active
                    if (validationRules[rule] === true && formValues[validationFiledName].value !== null) {
                        let validationResponse = validationRulesData[rule](formValues[validationFiledName], formValues);
                        if (validationResponse.error) {
                            errorObject = {...validationResponse, value: formValues[validationFiledName].value};
                            break;
                        }
                    }
                }

                setErrors(prev => {
                    return {
                        ...prev, ...{
                            [validationFiledName]: errorObject
                        }
                    }
                })
            }
        }
    }, [formValues])


    useEffect(() => {
        checkForError(errors, setIsFormValid, formValues);
    }, [errors])


    return {isFormValid, errors, updateValues, setIsFormValid, setErrors, submit,updateField};
} 