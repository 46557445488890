import {REACT_APP_ENV, MAIN_CURRENCY} from "./globals";
import Decimal from 'decimal.js';

export const getXApiKey = () => {
    const env = REACT_APP_ENV;

    switch (env) {
        case 'dev':
            return '{DevXApiKey}';
        case 'stg':
            return '{StgXApiKey}';
        case 'prod':
            return '{ProdXApiKey}';
        default:
            return process.env.REACT_APP_X_API_KEY;
    }
}

export const serviceEnvBaseUrl = () => {

    const env = REACT_APP_ENV;
    switch (env) {
        case 'dev':
            return '{DevBaseURL}';
        case 'stg':
            return '{StgBaseURL}';
        case 'prod':
            return '{ProdBaseURL}';
        default:
            // return 'https://hpk8zzocs7.execute-api.eu-central-1.amazonaws.com/dev';
            // return 'https://i8xny1dk8c.execute-api.eu-central-1.amazonaws.com/dev'; // Trailblaze
            return 'https://4951gvflt9.execute-api.eu-central-1.amazonaws.com/dev'; // d3c
    }
}

export const priceFormatter = (price, notation) => {
    const isUSDT = MAIN_CURRENCY === 'USDT';
    const currency = isUSDT ? 'USD' : MAIN_CURRENCY;

    const options = {
        style: 'currency',
        notation: 'compact',
        compactDisplay: 'short',
        currency: currency,
        maximumSignificantDigits: 3,
    };

    if (notation === false) delete options.notation;
    const formattedPrice = Intl.NumberFormat('en', options).format(price);
    if (isUSDT) return formattedPrice.replace('$', 'USDT ');

    return formattedPrice;
}

export const dateFormatter = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}

export const walletAddressFormatter = (address) => {
    const firstPart = address.slice(0, 6); // "0x" + first 4 characters
    const lastPart = address.slice(-4); // last 4 characters

    return firstPart + "...." + lastPart;
}

export const formatNumberToMillions = (number) => {
    // Check if the number is greater than or equal to one million
    if (number >= 1000000) {
        // Convert the number to millions and round to one decimal place
        const millions = Math.round((number / 1000000) * 10) / 10;
        // Return the formatted string with 'M' appended
        return millions.toFixed(1) + 'M';
    }
    // Return the original number if it's less than one million
    return number.toString();
}

export const copyToClipboard = async (target, setIsCopied) => {
    const type = 'text/plain';
    const blob = new Blob([target], {type});
    const cbi = new ClipboardItem({
        [type]: blob
    });
    navigator.clipboard.write([cbi])
        .then(function () {
            console.log('copied');
        })
        .catch(function (error) {
            console.log(error);
        });
    if( setIsCopied) {
        setIsCopied(true);

        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    }
};

export const numberWithSeparator = (numberString) => {
    const decimalNumber = numberString && numberString !== '' ? new Decimal(numberString) : 0;
    const formattedNumber = numberString ? decimalNumber.toNumber() : 0;
    return formattedNumber.toLocaleString();
}

export const changeFaviconByEnvironment = () => {
    const env = REACT_APP_ENV;

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    switch (env) {
        case 'dev':
            link.href = '/dev-favicon.ico';
            return;
        case 'stg':
            link.href = '/stg-favicon.ico';
            return;
        case 'prod':
            //when the real production environment is ready, we will delete stg favicon for this one;
            link.href = '/stg-favicon.ico';
            return;
        default:
            link.href = '/dev-favicon.ico';
            return;
    }
}

export const errorMessageHandler = (code) => {
    switch (code) {
        case 400:
            return 'Bad Request';
        case 401:
            return 'Your provided token is expired';
        case 403:
            return 'Forbidden';
        case 404:
            return 'Not Found';
        case 405:
            return 'Method Not Allowed';
        case 500:
            return 'Internal Server Error';
        case 501:
            return 'Not Implemented';
        default:
            return 'Something went wrong';
    }
}

export const localToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbi10eXBlIjoiY2xpZW50LXRva2VuIiwiZ2FtZS1pZCI6ImFhMDljMDdmLWRiNGYtNDU2ZC04ZTQ3LTVlZmQ2Mzg3NDFkZCIsInBsYXllci1pZCI6IkFzZDYiLCJwbGF5ZXItdXVpZCI6ImFmMWIyZThkLTRiNWItNDFhZC1hY2UyLTkwZGFjZTZhZGMwMiIsInN1YiI6IkpXVFNlcnZpY2VBY2Nlc3NUb2tlbiIsImp0aSI6ImY1Y2E1MTlkLWE0OTgtNGE0MC1hOTRmLWIwMTYwMWRlOWQxMCIsImlhdCI6IjEwLzMvMjAyMiA4OjQ2OjE1IEFNIiwiZXhwIjoxNjY2NTg2Nzc1LCJpc3MiOiJKV1RBdXRoZW50aWNhdGlvblNlcnZlciIsImF1ZCI6IkpXVFNlcnZpY2VQb3N0bWFuQ2xpZW50In0.emzK_XDqQEdoTOYNt_8CdNqXkBj1FhGAmxjbBlhCCAc';
