import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import {Box, Container, Link, Stack} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {closeVentureInvestorsModal} from "../../../../redux/features/offerings/manageOfferingsSlice";
import {ReactComponent as BackIcon} from "../../../../style/icons/arrow-left.svg";
import DataTable from '../../../../common/DataTable';
import {Theme_colors} from "../../../../style/theme_colors";
import {investorsColumns} from '../tables/columns';
import {useContext, useEffect, useState} from "react";
import {copyToClipboard, dateFormatter, priceFormatter, walletAddressFormatter, numberWithSeparator} from "../../../../helpers/helpers";
import MenuButton from "../../../../common/MenuButton";
import {calcDateAndTime} from "../../../../utils/calulateDate";
import {AppContext} from "../../../../context/AppContext";
import IconButton from "@mui/material/IconButton";
import {ReactComponent as CopyIcon} from '../../../../style/icons/copy-icon.svg';
import {showModal} from "../../../../redux/features/notifications/modalSlice";
import BillActionsView from "../../../investors/components/modal/components/BillActionsView";
import Modal from "../../../../common/modal/Modal";
import Decimal from 'decimal.js';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const sxStyles = {
    ventureWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        borderRadius: '40px 40px 40px 40px',
        marginRight: '8px'
    },
    typo: {
        fontSize: '16px',
        color: Theme_colors.default,
    },
    button: {
        borderRadius: 0,
        minWidth: '40px',
        width: '40px',
        height: '40px',
        border: `1px solid ${Theme_colors.greyThird}`,
        '&:hover': { backgroundColor: 'inherit', }
    },
    listItem: {
        fontSize: '16px',
        fontWeight: 500,
    },
    statusSubmitted: {
        color: Theme_colors.black,
        backgroundColor: Theme_colors.greyNinth,
        fontSize: '13px',
        fontWeight: 500,
        padding: '3px 8px',
    },
    Pending: {
        color: Theme_colors.aquaThird,
        backgroundColor: Theme_colors.aqua,
        fontSize: '13px',
        fontWeight: 500,
        padding: '3px 8px',
    },
    Paid: {
        color: Theme_colors.successDark,
        backgroundColor: Theme_colors.successLight,
        fontSize: '13px',
        fontWeight: 500,
        padding: '3px 8px',
    },
    Expired: {
        color: Theme_colors.dangerDark,
        backgroundColor: Theme_colors.dangerLight,
        fontSize: '13px',
        fontWeight: 500,
        padding: '3px 8px',
    },
    Rejected: {
        color: Theme_colors.black,
        backgroundColor: Theme_colors.greyThird,
        fontSize: '13px',
        fontWeight: 500,
        padding: '3px 8px',
    },
};

function getInvestors(investors, rows) {
    investors.investors
        .filter((item) => item.investor !== null)
        .map((item, index) => {
            rows.push({
                id: Math.random().toString(),
                address: item.investor.walletAddress,
                date: calcDateAndTime(item.investment.investment.date),
                twitter: item.investor.twitterUrl,
                telegram: item.investor.telegramUrl,
                tickets: numberWithSeparator(Decimal(item.investment.investment.amount).div(investors.venture[0].offeringDetails.ticketSize)
                    .toString()),
                equity: Decimal((item.investment.investment.amount / investors.venture[0].offeringDetails.valuation) * 100)
                    .toDP(4, Decimal.ROUND_HALF_UP).toString(),
                saft: item.investment.investment.saftLink ?? '-',
                submittedInterest: item.investment.investment.amount ? priceFormatter(item.investment.investment.amount) : '-',
                invested: item.investment.state.result === 'PaymentConfirmed' ? priceFormatter(item.investment.investment.amount) : 0,
                reference: item.investment.investment.bill && item.investment.investment.bill.reference
                    ? item.investment.investment.bill.reference
                    : '-',
                status: item.investment.state.result,
                venture: investors.venture[0],
                investor: item.investor,
                investment: item.investment,
                isPaymentConfirmedByInvestor: !!(item.investment.state.result === 'AwaitingPayment'
                    && item.investment.investment.bill && item.investment.investment.bill.reference),
            }
        );
    });

    return rows;
}

function getStatusText(status) {
    switch (status) {
        case 'InterestSubmitted':
            return 'Submitted interest';
        case 'InterestApproved':
            return 'Approved interest';
        case 'AwaitingPayment':
            return 'Investment intent';
        case 'PaymentConfirmed':
            return 'Invested';
    }
}

export default function VentureInvestorsModal() {
    const {screen} = useContext(AppContext);
    const {openVentureInvestorsModal, ventureInvestors} = useSelector(state => state.manageOfferings);
    const [rows, setRows] = useState(getInvestors(ventureInvestors, []));
    const [investmentRow, setInvestmentRow] = useState({});
    const dispatch = useDispatch();
    const [columnTitles, setColumnTitles] = useState(investorsColumns);

    useEffect(() => {
        setRows(getInvestors(ventureInvestors, []));
    }, [ventureInvestors]);

    const handleClose = () => {
        dispatch(closeVentureInvestorsModal());
    };

    const handleStatusColumnSort = () => {
        const updatedRows = [...rows];
        const isColumnSorted = columnTitles.find((column) => column.field === 'status').sortable;
        const isColumnSortedAscending = columnTitles.find((column) => column.field === 'status').sortDirection === 'asc';

        updatedRows.sort((a, b) => {
            const statusOrder = {
                'Submitted interest': 1,
                'Investment intent': 2,
                'Invested': 3,
                'Expired': 4,
                'Rejected': 5,
            };

            if (isColumnSorted && isColumnSortedAscending) {
                return statusOrder[getStatusText(b.status)] - statusOrder[getStatusText(a.status)];
            } else {
                return statusOrder[getStatusText(a.status)] - statusOrder[getStatusText(b.status)];
            }
        });
        const updatedColumnTitles = columnTitles.map((column) => {
            if (column.field === 'status') {
                return {
                    ...column,
                    sortable: true,
                    sortDirection: isColumnSortedAscending ? 'desc' : 'asc',
                };
            }
            return column;
        });
        setRows(updatedRows);
        setColumnTitles(updatedColumnTitles);
    };

    const confirmOrReject = (row, action) => {
        // console.log('row: ', row);
        setInvestmentRow(
            {
                action: action,
                venture: {
                    id: row.venture.id,
                    name: row.venture.name,
                },
                investment: {
                    transactionId: row.investment.investment.transactionId,
                    amount: priceFormatter(row.investment.investment.amount),
                    status: row.investment.state.result,
                },
                investor: {
                    id: row.investor.id,
                    walletAddress: row.investor.walletAddress,
                },
            }
        );
        dispatch(showModal());
    };

    const menuElements = (menuElementsRow) => {
        const elements = [];

        if (['InterestSubmitted'].includes(menuElementsRow.status)) {
            elements.push({
                title: 'Approve',
                onClick: (row) => confirmOrReject(row, 'approve'),
            });
        }

        if (['InterestSubmitted', 'InterestApproved'].includes(menuElementsRow.status)) {
            elements.push({
                title: 'Adjust amount',
                onClick: (row) => confirmOrReject(row, 'adjust'),
            });
        }

        if (
            ['InterestSubmitted', 'InterestApproved', 'AwaitingPayment'].includes(menuElementsRow.status)
            &&
            !menuElementsRow.isPaymentConfirmedByInvestor
        ) {
            elements.push({
                title: 'Reject',
                onClick: (row) => confirmOrReject(row, 'reject'),
            });
        }

        // if (!['InterestSubmitted', 'PaymentConfirmed'].includes(menuElementsRow.status)) {
        //     elements.push({
        //         title: 'Renew',
        //         onClick: (row) => confirmOrReject(row, 'generate'),
        //     });
        // }

        if (menuElementsRow.status === 'AwaitingPayment' && menuElementsRow.isPaymentConfirmedByInvestor) {
            elements.push({
                title: 'Confirm',
                onClick: (row) => confirmOrReject(row, 'confirm'),
            });
        }

        return elements;
    }

    const columns = columnTitles.map((title) => ({
        ...title,
        renderCell: (params) => {
            switch (title.field) {
                case 'address':
                    return (
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                            {/*<Link*/}
                            {/*    href={`https://etherscan.io/address/${params.row.address}`}*/}
                            {/*    target={'_blank'}*/}
                            {/*    style={sxStyles.link}*/}
                            {/*    sx={{'&:hover': {cursor: 'pointer'}, textDecoration: 'none'}}*/}
                            {/*>*/}
                            <Typography
                                sx={{
                                    ...sxStyles.typo,
                                    color: Theme_colors.primaryColor,
                                    '&:hover': {cursor: 'pointer'},
                                }}
                            >
                                {walletAddressFormatter(params.row.address)}
                            </Typography>
                            {/*</Link>*/}
                            <IconButton
                                disableRipple
                                onClick={() => copyToClipboard(params.row.address)}
                            >
                                <CopyIcon />
                            </IconButton>
                        </Box>
                    );
                case 'date':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {dateFormatter(params.row.date)}
                        </Typography>
                    );
                case 'twitter':
                    return (
                        <Link
                            href={params.row.twitter.includes('http') ? params.row.twitter : `https://${params.row.twitter}`}
                            target={'_blank'}
                            style={sxStyles.link}
                            sx={{'&:hover': {cursor: 'pointer'}, textDecoration: 'none'}}
                        >
                            <Typography sx={{...sxStyles.typo, color: Theme_colors.primaryColor}}>
                                {params.row.twitter}
                            </Typography>
                        </Link>
                    );
                case 'telegram':
                    return (
                        <Link
                            href={`https://t.me/${params.row.telegram}`}
                            target={'_blank'}
                            style={sxStyles.link}
                            sx={{'&:hover': {cursor: 'pointer'}, textDecoration: 'none'}}
                        >
                            <Typography sx={{...sxStyles.typo, color: Theme_colors.primaryColor}}>
                                {params.row.telegram}
                            </Typography>
                        </Link>
                    );
                case 'tickets':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {params.row.tickets}
                        </Typography>
                    );
                case 'equity':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {params.row.equity}%
                        </Typography>
                    );
                case 'saft':
                    return (
                        <Link
                            href={params.row.saft}
                            target={'_blank'}
                            style={sxStyles.link}
                            sx={{'&:hover': {cursor: 'pointer'}, textDecoration: 'none'}}
                        >
                            <Typography sx={{...sxStyles.typo, color: Theme_colors.primaryColor}}>
                                Link
                            </Typography>
                        </Link>
                    )
                case 'submittedInterest':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {params.row.submittedInterest}
                        </Typography>);
                case 'invested':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {params.row.invested}
                        </Typography>);
                case 'reference':
                    return (
                        params.row.reference !== '-'
                            ?
                            <Link
                                href={params.row.reference}
                                target={'_blank'}
                                style={sxStyles.link}
                                sx={{'&:hover': {cursor: 'pointer'}, textDecoration: 'none'}}
                            >
                                <Typography sx={{...sxStyles.typo, color: Theme_colors.primaryColor}}>
                                    Link
                                </Typography>
                            </Link>
                            :
                            <Typography sx={sxStyles.typo}>
                                {params.row.reference}
                            </Typography>
                    );
                case 'status':
                    return (
                        <Typography
                            sx={{
                                ...sxStyles.typo,
                                color: params.row.status === 'PaymentConfirmed'
                                    ? Theme_colors.successDark
                                    : (
                                        params.row.status === 'InterestApproved' || params.row.status === 'AwaitingPayment'
                                            ? Theme_colors.blueDark
                                            : Theme_colors.black
                                    ),
                                backgroundColor:
                                    params.row.status === 'PaymentConfirmed'
                                        ? Theme_colors.successLight
                                        : (
                                            params.row.status === 'InterestApproved' || params.row.status === 'AwaitingPayment'
                                                ? Theme_colors.yellowLight
                                                : Theme_colors.greySixth
                                        ),
                                fontSize: '13px',
                                fontWeight: 500,
                                padding: '3px 8px',
                            }}
                        >
                            {getStatusText(params.row.status)}
                        </Typography>
                    );
                case 'action':
                    return (
                        params.row.status !== 'PaymentConfirmed'
                        &&
                        <MenuButton
                            rowId={params.row}
                            elements={menuElements(params.row)}
                        />
                    );
                default:
                    return null;
            }
        },
    }));

    return (
        <div>
            <Dialog
                fullScreen
                sx={{ '& .MuiDialog-paper': { pl: !screen.sm ? 10 : 1, pr: !screen.sm ? 10 : 1 } }}
                open={openVentureInvestorsModal}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Container maxWidth={'xl'} sx={{p: 0}}>
                    <AppBar elevation={0} sx={{position: 'relative', backgroundColor: 'transparent !important'}}>
                        <Toolbar sx={{
                            backgroundColor: 'transparent !important',
                        }}>
                            <Stack  sx={{flex: 1}} direction="row" alignItems="center" gap={1}>
                                <BackIcon onClick={handleClose} style={{
                                    cursor: 'pointer',
                                }} />
                                <Typography sx={{ml: 1}} variant="h6" component="div">
                                    Investors
                                </Typography>
                            </Stack>
                        </Toolbar>
                    </AppBar>
                    {
                        rows.length > 0 &&
                        <>
                            <DataTable
                                sx={{
                                    position: 'relative',
                                    zIndex: 1150,
                                    mt: '40px',
                                    '&.MuiDataGrid-root': {
                                        color: Theme_colors.greySixth,
                                        border: 'none',
                                        borderRadius: 'none',
                                        fontSize: '16px',
                                    },
                                    '& .MuiDataGrid-virtualScroller': {
                                        padding: '0px 0px 130px 0px',
                                    },
                                }}
                                pageSize={100}
                                columns={columns}
                                rows={rows}
                                handleStatusColumnSort={handleStatusColumnSort}
                            />
                            <Modal
                                content={BillActionsView}
                                info={investmentRow}
                            />
                        </>
                    }
                </Container>
            </Dialog>
        </div>
    );
}
