import {createTheme} from "@mui/material";
import {Theme_colors} from "./theme_colors";

const existingTheme = createTheme({
    // Your existing theme configuration
});

const newBreakpointValue = 1920;

export const theme = createTheme({
    typography: {
        allVariants: {
            color: Theme_colors.black,
            fontFamily: ["Ppneuemontreal", 'sans-serif'].join(","),
            components: {
              MuiCssBaseline: {
                // styleOverrides: {
                //   "@font-face": {
                //     fontFamily: "Ppneuemontreal",
                //     src: `url('https://uploads-ssl.webflow.com/63c54ac5c1d7481b594c8473/63c54ac5c1d7482e864c84c3_PPNeueMontreal-Book.otf') format("truetype")`
                //   },
                //   body: {
                //     fontSize: "3rem",
                //     color: "purple"
                //   }
                // }
              }
            },
        },
    },
    palette: {
        primary: {
            main: Theme_colors.dragonOrange,
        },
        secondary: {
            main: Theme_colors.greyFirst,
        },
        error:{
            main: Theme_colors.alert,
        }
        // warning: {
        //   main: Theme_colors.warningBackground,
        // },
        // neutral: {
        //   main: Theme_colors.black,
        // },
        // secondary: {
        //  main: '',
        //  light: '',
        //  main: '',
        //  dark: '',
        //  contrastText: '',
        // },
        // success:{
        //
        // },
        // info: {
        //
        //   },


    },
    breakpoints: {
        ...existingTheme.breakpoints,
        keys: [...existingTheme.breakpoints.keys, 'xxl'],
        values: {
            ...existingTheme.breakpoints.values,
            xxl: newBreakpointValue,
        },
    },
})

