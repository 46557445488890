import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Container } from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Theme_colors } from '../../../../style/theme_colors';
import { ReactComponent as ArrowLeft } from "../../../../style/icons/arrow-left.svg";
import InvestmentsAndInterest from '../InvestmentsAndInterest';
import Profile from '../Profile';
import ActivityLog from '../ActivityLog';
import { useContext } from 'react';
import { AppContext } from '../../../../context/AppContext';

const sxStyles = {
    tabs: {
        textTransform: 'none', mt: '24px',
        color: Theme_colors.black,
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '1em',
        '&:hover': {
            color: Theme_colors.dragonOrange,
        }
    },
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function InvestorProfileModal(props) {
    const { setInvestorId, open, setOpen, } = props;
    const { investorInfo } = useSelector((state) => state.investors);
    const { screen } = useContext(AppContext);
    const [value, setValue] = React.useState('0');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleClose = () => {
        setOpen(false);
        setInvestorId(null);
    };

    return (<div>
        <Dialog
            fullScreen
            sx={{ '& .MuiDialog-paper': { pl: !screen.sm ? 10 : 1, pr: !screen.sm ? 10 : 1 } }}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <Container maxWidth={'xl'} sx={{ p: 0 }}>
                <AppBar elevation={0} sx={{ position: 'relative', backgroundColor: 'transparent !important' }}>
                    <Toolbar sx={{
                        backgroundColor: 'transparent !important', '&.MuiToolbar-root': { pr: 0 }, mt: 4
                    }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <ArrowLeft style={{
                                width: '24px',
                                height: '24px',
                                marginRight: '10px',
                            }} />
                        </IconButton>
                        <Typography sx={{ flex: 1, fontSize: '28px', fontWeight: '700' }} component="div">
                            {investorInfo ? investorInfo.investor.walletAddress : ''}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box sx={{ width: '100%' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Profile" value="0" sx={sxStyles.tabs} />
                                <Tab label="Investments & Interest" value="1" sx={sxStyles.tabs} />
                                <Tab label="Activity log" value="2" sx={sxStyles.tabs} />
                            </TabList>
                        </Box>
                        <TabPanel value="0">
                            <Profile />
                        </TabPanel>
                        <TabPanel value="1">
                            <InvestmentsAndInterest investments={investorInfo?.investments} />
                        </TabPanel>
                        <TabPanel value="2">
                            <ActivityLog />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Container>
        </Dialog>
    </div>);
}