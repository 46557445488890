import {Button, CircularProgress} from '@mui/material';
import {Theme_colors} from '../style/theme_colors';
import {Box} from "@mui/system";

const sxStyles = {
    contained: {
        width: 'auto',
        color: Theme_colors.white,
        letterSpacing: '0.0025em',
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '0.9em',
        borderRadius: 0,
        boxShadow: 'none',
        padding: '0.9em 1.5em',
        "&:hover": {backgroundColor: Theme_colors.dragonOrange, boxShadow: 'none',},
        "&.Mui-focusVisible": {backgroundColor: Theme_colors.dragonOrange, boxShadow: 'none',},
        "&:disabled": {color: Theme_colors.white,},
        position: 'relative',
    }, outlined: {
        borderRadius: 0,
        boxShadow: 'none',
        fontWeight: '500',
        border: '1px solid #C6C6C6',
        color: Theme_colors.black,
        "&:hover": {boxShadow: 'none', border: '1px solid #C6C6C6', backgroundColor: Theme_colors.white,}
    },
}

export default function ActionButton(props) {

    let inputProps = {...props};
    delete inputProps.isLoading;
    return <Button
        disableRipple
        type="submit"
        variant={props.variant ? props.variant : 'contained'}
        size="large"
        {...inputProps}
        sx={props.sx ? {...sxStyles[props.variant ? props.variant : 'contained'], ...props.sx} : (sxStyles[props.variant] ? sxStyles[props.variant] : sxStyles['contained'])}
        disabled={props.isLoading || props.disabled}
    >
        <Box sx={{
            position: 'relative',
        }}>
            <span>{props.children}</span>
            {props.isLoading && <CircularProgress size={20} color={'secondary'} sx={{
                position: 'absolute', right: '-30px', top: '-2px', width: '20px', height: '20px',
            }}/>}
        </Box>
    </Button>
}

ActionButton.propTypes = {};
