import appReducer from "./features/app/appSlice";
import authUserReducer from "./features/auth/authUserSlice";
import notificationReducer from "./features/notifications/notificationSlice";
import modalReducer from "./features/notifications/modalSlice";
import investorsReducer from "./features/investors/investorsSlice";
import offeringsReducer from "./features/offerings/offeringsSlice";
import manageOfferingsReducer from "./features/offerings/manageOfferingsSlice";
import fileUploadReducer from "./features/upload-document/fileUploadReducer";
import paymentsReducer from "./features/investors/paymentsSlice";
import offeringActionsReducer from "./features/offerings/offeringActions";

import {configureStore} from "@reduxjs/toolkit";

export const store = configureStore({
    reducer: {
        offeringActions: offeringActionsReducer,
        uploadDocument: fileUploadReducer,
        manageOfferings: manageOfferingsReducer,
        offerings: offeringsReducer,
        investors: investorsReducer,
        modal: modalReducer,
        notification: notificationReducer,
        authUser: authUserReducer,
        payments: paymentsReducer,
        app: appReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});