import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {store} from "../../store";
import axios from "axios";
import {showNotification} from "../notifications/notificationSlice";
import {getCurrentState, getNextState} from "../../../utils/validateData";
import {serviceEnvBaseUrl} from "../../../helpers/helpers";

const initialState = {
    data: [], investorVentureWorkflow: null, venture: null, isLoading: true, hasError: false
}


export const publishVenture = createAsyncThunk('offeringActions/publish', async (id, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;

    return await axios.post(serviceEnvBaseUrl() + `/ventures/` + id + `/publish`, {},{
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const archiveVenture = createAsyncThunk('offeringActions/archive', async (id, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;

    const token = accessToken;

    return await axios.put(serviceEnvBaseUrl() + `/ventures/` + id + `/archive`, {},{
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const unpublishVenture = createAsyncThunk('offeringActions/unpublish', async (id, {
    dispatch,
    rejectWithValue
}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;

    return await axios.put(serviceEnvBaseUrl() + `/ventures/` + id + `/unpublish`, {},{
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const deleteVenture = createAsyncThunk('offeringActions/delete', async ({id, status = 'published'}, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;

    return await axios.delete(serviceEnvBaseUrl() + `/admin/ventures/` + id + `?status=${status}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});


export const offeringActionsSlice = createSlice({
    name: 'offeringActions', initialState,
    extraReducers: (builder) => {
        const pendingAndRejectedCases = (state, action) => {
            state.hasError = action.error;
            state.isLoading = action.meta.requestStatus === 'pending';
        };
        builder.addCase(publishVenture.fulfilled, (state, action) => {
            // state.data = action.payload;
            state.isLoading = false;
            state.hasError = false;
        })
        builder.addCase(archiveVenture.fulfilled, (state, action) => {
            // state.data = action.payload;
            state.isLoading = false;
            state.hasError = false;
        })
        builder.addCase(unpublishVenture.fulfilled, (state, action) => {
            // state.data = action.payload;
            state.isLoading = false;
            state.hasError = false;
        })
        builder
            .addMatcher(
                (action) =>
                    [publishVenture.pending.type, unpublishVenture.pending.type, deleteVenture.pending.type, archiveVenture.pending.type,].includes(action.type),
                pendingAndRejectedCases
            )
        builder
            .addMatcher(
                (action) =>
                    [publishVenture.rejected.type, unpublishVenture.rejected.type, deleteVenture.rejected.type, archiveVenture.rejected.type,].includes(action.type),
                pendingAndRejectedCases
            )
    },
    reducers: {}
});

export const {} = offeringActionsSlice.actions

export default offeringActionsSlice.reducer