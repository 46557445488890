import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Theme_colors } from '../../style/theme_colors';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion"
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import './tabs.scss';
import TabPanel from "../../common/TabPanel";
import InvestorsTable from "./components/InvestorsTable";
import InvestorProfileModal from './components/modal/InvestorProfileModal';
import { getInvestorById } from '../../redux/features/investors/investorsSlice';

const sxStyles = {
    title: {
        fontWeight: '700',
        fontSize: '1.75em',
        fontStyle: 'normal',
        color: Theme_colors.black,
        mt: '8px',
        ml: 1,
    }, description: {
        fontWeight: '400',
        fontSize: '1.125em',
        color: Theme_colors.black,
        lineHeight: '1.5em',
        mt: 5,
    }, fieldWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: `1px solid ${Theme_colors.greyFirst}`,
        p: '0.75em 0.75em',
        m: '1.5em 0',
        height: '96px',
    }, field: {
        fontWeight: '500',
        fontSize: '0.875em',
        fontStyle: 'normal',
        lineHeight: '1.125em',
        color: Theme_colors.black,
        m: '0.5em 0',
    }, link: {
        color: Theme_colors.dragonOrange, margin: '2px 2px',
    }, accredited: {
        backgroundColor: Theme_colors.greySecond, p: "1em 1em", display: 'flex', flexDirection: 'row', gap: 0.5,
    }, alertTitle: {
        fontSize: '1em', fontWeight: '400',
    }, alertContent: {
        color: Theme_colors.black, backgroundColor: Theme_colors.warning, m: '0em 2em 1em 2em',
    }, tabs: {
        textTransform: 'none', mt: '24px',
        color: Theme_colors.black,
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '1em',
        '&:hover': {
            color: Theme_colors.dragonOrange,
        }
    }, tabsBox: {
        borderBottom: 1,
        borderColor: 'divider',
    }, buttons: {
        height: '56px',
        color: Theme_colors.white,
        borderRadius: 0,
        mt: 2,
        boxShadow: 'none',
        fontSize: '0.875em',
        fontWeight: '500',
        width: '200px',
        "&:hover": { backgroundColor: Theme_colors.dragonOrange, boxShadow: 'none', },
        "&.Mui-disabled": { color: Theme_colors.white, boxShadow: 'none', backgroundColor: Theme_colors.greyThird, },
    },
    dialog: {
        '& .MuiPaper-root': { borderRadius: 0, alignItems: 'center', },
    },
}

export default function Investors(props) {
    const { screen } = useContext(AppContext);
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [investorId, setInvestorId] = useState(null);
    const [openInvestorModal, setOpenInvestorModal] = useState(false);
    const location = useLocation();
    let selectedTab = location.search.split('=').pop();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleInvestorId = (id) => {
        setInvestorId(id);
    }

    useEffect(() => {
        if (selectedTab) {
            setValue(Number(selectedTab));
        }
    }, [selectedTab]);

    useEffect(() => {
        if (investorId) {
            dispatch(getInvestorById(investorId))
                .then(() => {
                    setOpenInvestorModal(true);
                })

        }
    }, [dispatch, investorId]);

    return <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}>
        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={sxStyles.title}>Investors</Typography>
            <Box sx={{ width: '100%' }}>
                {/*<Box sx={!screen.md ? { ...sxStyles.tabsBox } : null}>*/}
                {/*    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"*/}
                {/*        className={screen.md ? 'mobile-menu-tabs' : ''}>*/}
                {/*        <Tab*/}
                {/*            label="All" {...a11yProps(0)}*/}
                {/*            sx={{ ...sxStyles.tabs, fontSize: screen.md ? '14px' : '16px' }}*/}
                {/*        />*/}
                {/*        <Tab label="Accreditation" {...a11yProps(1)}*/}
                {/*            sx={{ ...sxStyles.tabs, fontSize: screen.md ? '14px' : '16px' }}*/}
                {/*            onClick={() => {}}*/}
                {/*        />*/}
                {/*    </Tabs>*/}
                {/*</Box>*/}

                <TabPanel value={value} index={0}>
                    <Box>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InvestorsTable getInvestorId={handleInvestorId} />
                        </Grid>
                    </Box>
                </TabPanel>

                {/*<TabPanel value={value} index={1}>*/}
                {/*    <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>*/}
                {/*    </Grid>*/}
                {/*</TabPanel>*/}
            </Box>
        </Grid>
        <InvestorProfileModal setInvestorId={setInvestorId} open={openInvestorModal} setOpen={setOpenInvestorModal} />
    </motion.div>
}

Investors.propTypes = {};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`,
    };
}

