import React from 'react';
import PropTypes from 'prop-types';
import {Box} from "@mui/material";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

AddSection.propTypes = {

};

function AddSection({text, addSection, ...props}) {

    let sectionText = 'Add Section' || text;

    const handleAddSection = () => {
        addSection(props);
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            gap: 2
        }}>
            <Box sx={{
                display: 'inline-block',
                width: '100%',
            }}>
                <Divider/>
            </Box>
            <Button sx={{
                width: '260px',
            }} onClick={handleAddSection}>{
                sectionText
            }</Button>
            <Box sx={{
                display: 'inline-block',
                width: '100%',
            }}>
                <Divider/>
            </Box>
        </Box>
    );
}

export default AddSection;