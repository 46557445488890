import React from 'react';
import ActionButton from "./ActionButton";
import {Box, Menu, MenuItem} from "@mui/material";
import {ReactComponent as ArrowDown} from "../style/icons/arrow-down.svg";
import Divider from "@mui/material/Divider";
import ventureType from "../utils/ventureType";


SaveButton.propTypes = {};

function SaveButton(props) {
    const {remove,submit, type, ...rest} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <ActionButton
                onClick={submit}
                sx={{
                minWidth: '75px',
                minHeight: '40px',
                marginRight: '1px'
            }} {...rest} variant={'contained'}>Save</ActionButton>
            {/*<div>*/}
            {/*    <ActionButton sx={{*/}
            {/*        minWidth: '40px',*/}
            {/*        minHeight: '40px',*/}
            {/*    }} {...rest} variant={'contained'}*/}
            {/*                  id="basic-button"*/}
            {/*                  aria-controls={open ? 'basic-menu' : undefined}*/}
            {/*                  aria-haspopup="true"*/}
            {/*                  aria-expanded={open ? 'true' : undefined}*/}
            {/*                  onClick={handleClick}>*/}
            {/*        <ArrowDown/>*/}
            {/*    </ActionButton>*/}
            {/*    <Menu*/}

            {/*        anchorOrigin={{*/}
            {/*            vertical: 'bottom',*/}
            {/*            horizontal: 'right',*/}
            {/*        }}*/}
            {/*        transformOrigin={{*/}
            {/*            vertical: 'top',*/}
            {/*            horizontal: 'right',*/}
            {/*        }}*/}
            {/*        id="basic-menu"*/}
            {/*        anchorEl={anchorEl}*/}
            {/*        open={open}*/}
            {/*        onClose={handleClose}*/}
            {/*        MenuListProps={{*/}
            {/*            'aria-labelledby': 'basic-button',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <MenuItem disabled={type === ventureType.NEW} sx={{*/}
            {/*            width: '262px',*/}
            {/*        }} onClick={handleClose}>Publish</MenuItem>*/}
            {/*        {*/}
            {/*            remove && <>*/}
            {/*                <Divider sx={{my: 0.5}}/>*/}
            {/*                <MenuItem sx={{*/}
            {/*                    width: '262px',*/}
            {/*                }} onClick={handleClose}>Remove</MenuItem>*/}
            {/*            </>*/}
            {/*        }*/}

            {/*    </Menu>*/}
            {/*</div>*/}
        </Box>
    );
}

export default SaveButton;