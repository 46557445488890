import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
    type: 'warning',
    autoHide: 1500,
    processing: false,
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        showNotification: (state, action) => {
            return  {...state, ...initialState, ...action.payload, open: true}
        },
        closeNotification: (state, action) => {
            return  {...state,  ...action.payload, open: false}
        }
    }
})

// Action creators are generated for each case reducer function
export const {showNotification,closeNotification} = notificationSlice.actions

export default notificationSlice.reducer