import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {CloseRounded} from "@mui/icons-material";
import SquareChip from "./ChipBox";
import {Theme_colors} from "../../style/theme_colors";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, value, theme) {
    return {
        borderRadius: 0,
        fontWeight:
            value.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const sxStyles = {
    deleteIcon: {
        '&.MuiChip-deleteIcon': {
            color: Theme_colors.black,
            '&:hover': {color: Theme_colors.black,},
        }
    },
}
export default function MultipleSelectChip({names, onChange, onDelete, value, label, options, error}) {
    const theme = useTheme();

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;

        if (onChange) {
            const splitValue = typeof value === 'string' ? value.split(',') : value;
            onChange(event, splitValue)
        }
    };

    const handleDelete = (event, name) => {
        if (onDelete) {
            const values = value.filter((el) => el !== name);
            onDelete(event, values)
        }
    }

    return (
        <>
            <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
            <Select
                sx={{
                    borderRadius: 0
                }}
                error={error}
                labelId="multiple-chip-label"
                id="multiple-chip"
                multiple
                value={value}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label={label}/>}
                renderValue={(selected) => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {selected?.map((value) => (
                            <SquareChip
                                key={value}
                                label={value}
                                clickable
                                deleteIcon={<CloseRounded
                                    onMouseDown={(event) => event.stopPropagation()}
                                    sx={sxStyles.deleteIcon}
                                />}
                                onDelete={(e) => handleDelete(e, value)}
                            />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {options.map((name) => (
                    <MenuItem
                        sx={{
                            borderRadius: 0
                        }}
                        key={name}
                        value={name}
                        style={getStyles(name, value, theme)}
                    >
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
}