import React, {lazy} from "react";
import SignIn from "../pages/login/SignIn";
import SignUp from "../pages/login/SignUp";
import Offerings from "../pages/offerings";
import Investors from "../pages/investors";

const AppLayout = lazy(() => import( "../layouts/AppLayout"));
const AuthLayout = lazy(() => import("../layouts/AuthLayout"));
const Test = lazy(() => import("../pages/Test"));


const routes =
    [
        {
            name: 'App', path: '/*', component: <AppLayout/>,
            allowedRoles: [
            ], children: [
                {
                    index: true, name: 'InvestorsIndex', component: <Investors/>
                },
                {
                    name: 'Offerings', path: 'offerings', component: <Offerings/>
                },
                {
                    name: 'Investors', path: 'investors', component: <Investors/>
                }
            ]
        },
        {
            name: 'Auth', path: 'auth/', component: <AuthLayout/>, children: [
                {
                    name: 'SignIn', path: 'sign-in', component: <SignIn/>
                },
                {
                    name: 'SignUp', path: 'sign-up', component: <SignUp/>
                },

            ]
        },
    ]

export default routes;