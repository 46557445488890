import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {Box, Container, FormControl} from "@mui/material";
import InputField from "../../../../common/styled-components/InputField";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
    setContactField,
    setFormValidation,
} from "../../../../redux/features/offerings/manageOfferingsSlice";
import {REGEX_EMAIL, REGEX_LINKEDIN, REGEX_PHONE, REGEX_TWITTER, REGEX_WEBSITE} from "../../../../utils/validateData";
import ventureType from "../../../../utils/ventureType";

function ContactUs(props, ref) {
    const dispatch = useDispatch();
    const {venture: {contactDetails}} = useSelector(state => state.manageOfferings);
    const {
        control,
        handleSubmit,
        setValue,
        formState: {errors, isValid},
        watch,
        trigger,
    } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        const formFields = watch();
        Object.keys(formFields).forEach((key) => {
            if (key in contactDetails.address) {
                setValue(key, contactDetails.address[key]);
            } else {
                setValue(key, contactDetails[key]);
            }
        });

        if (props.isDraft) trigger();
    }, []);

    useEffect(() => {
        dispatch(setFormValidation({
            field: 'contactUs',
            value: isValid,
        }));
    }, [isValid]);

    const submitFormAct = () => {
        handleSubmit(onSubmit)();
    };

    useImperativeHandle(ref, () => ({
        submit() {
            return submitFormAct();
        },
    }), [submitFormAct, errors]);

    const onSubmit = () => {};

    return (
        <Container maxWidth={'md'}>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2, marginBottom: 2}}>
                <Box onSubmit={handleSubmit(onSubmit)}
                     component="form"
                     noValidate
                     autoComplete="off" sx={{maxWidth: 'sm', width: '100%', marginBottom: 2}}
                >
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="websiteUrl"
                            control={control}
                            rules={{required: true, pattern: {value: REGEX_WEBSITE, message: "Entered value does not match url format"}}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'websiteUrl'}
                                        value={contactDetails.websiteUrl || ''}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setContactField({field: e.target.name, value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.websiteUrl)}
                                        label="Website Url"
                                        id="outlined-start-adornment"
                                        sx={{mb: 2}}
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{required: true, pattern: {value: REGEX_EMAIL, message: "Entered value does not match email format"}}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'email'}
                                        value={contactDetails.email || ''}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setContactField({field: e.target.name, value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.email)}
                                        label="Email"
                                        id="outlined-start-adornment"
                                        sx={{mb: 2}}
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="telegramUrl"
                            control={control}
                            rules={{required: true, pattern: {value: REGEX_WEBSITE, message: "Entered value does not match url format"}}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'telegramUrl'}
                                        value={contactDetails.telegramUrl || ''}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setContactField({field: e.target.name, value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.telegramUrl)}
                                        label="Official Telegram URL"
                                        id="outlined-start-adornment"
                                        sx={{mb: 2}}
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="twitterUrl"
                            control={control}
                            rules={{pattern: {value: REGEX_WEBSITE, message: "Entered value does not match url format"}}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'twitterUrl'}
                                        value={contactDetails.twitterUrl || ''}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setContactField({field: e.target.name, value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={contactDetails.twitterUrl !== '' ? Boolean(errors.twitterUrl) : false}
                                        label="Twitter (optional)"
                                        id="outlined-start-adornment"
                                        sx={{mb: 2}}
                                    />
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                        <Controller
                            name="discordUrl"
                            control={control}
                            rules={{required: true, pattern: {value: REGEX_WEBSITE, message: "Entered value does not match url format"}}}
                            render={
                                ({field}) =>
                                    <InputField
                                        name={'discordUrl'}
                                        value={contactDetails.discordUrl || ''}
                                        onChange={
                                            (e) => {
                                                field.onChange(e.target.value);
                                                dispatch(setContactField({field: e.target.name, value: e.target.value}));
                                            }
                                        }
                                        fullWidth={true}
                                        error={Boolean(errors.discordUrl)}
                                        label="Discord URL"
                                        id="outlined-start-adornment"
                                        sx={{mb: 2}}
                                    />
                            }
                        />
                    </FormControl>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2}}>
                        <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                            <Controller
                                name="country"
                                control={control}
                                rules={{required: true}}
                                render={
                                    ({field}) =>
                                        <InputField
                                            value={contactDetails?.address?.country || ''}
                                            name={'country'}
                                            onChange={
                                                (e) => {
                                                    field.onChange(e.target.value);
                                                    dispatch(setContactField({field: e.target.name, value: e.target.value}));
                                                }
                                            }
                                            fullWidth={true}
                                            error={Boolean(errors.country)}
                                            label="Country"
                                            id="outlined-start-adornment"
                                            sx={{mb: 2}}
                                        />
                                }
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{mt: 1, mb: 1}}>
                            <Controller
                                name="city"
                                control={control}
                                rules={{}}
                                render={
                                    ({field}) =>
                                        <InputField
                                            name={'city'}
                                            value={contactDetails?.address?.city || ''}
                                            onChange={
                                                (e) => {
                                                    field.onChange(e.target.value);
                                                    dispatch(setContactField({field: e.target.name, value: e.target.value}));
                                                }
                                            }
                                            fullWidth={true}
                                            // error={Boolean(errors.city)}
                                            label="City (optional)"
                                            id="outlined-start-adornment"
                                            sx={{mb: 2}}
                                        />
                                }
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default forwardRef(ContactUs);