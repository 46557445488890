import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {theme} from './style/theme';
import {store} from "./redux/store";
import routes from "./router/routes";
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "@emotion/react";
import AppProvider from "./context/AppContext";
import {BrowserRouter} from "react-router-dom";
import RouteGenerator from "./router/RouteGenerator";
import {Provider} from "react-redux";
import InterceptorComponent from "./interceptors/InterceptorComponent";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <InterceptorComponent />
        <AppProvider>
            <Provider store={store}>
                <BrowserRouter>
                    <RouteGenerator routes={routes}/>
                </BrowserRouter>
            </Provider>
        </AppProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
