import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import {Theme_colors} from '../../style/theme_colors';
import {Box, Grid,} from '@mui/material';
import {useDispatch} from 'react-redux';
import ActionButton from '../../common/ActionButton';
import {motion} from "framer-motion"
import {useContext} from 'react';
import {AppContext} from '../../context/AppContext';
import './tabs.scss';
import Modal from "../../common/modal/Modal";
import {showModal} from "../../redux/features/notifications/modalSlice";
import AddVentureView from "./components/AddVentureView";
import AddEditVentureModal from "./components/modal/AddVentureModal";
import VentureInvestorsModal from "./components/modal/VentureInvestorsModal";
import {getIcons} from "../../redux/features/app/appSlice";
import OfferingsTable from "./components/tables/OfferingsTable";
import ventureType from "../../utils/ventureType";
import {archivedColumns, draftColumns, publishedColumns} from "./components/tables/columns";
import {clearOfferings, getOfferings} from "../../redux/features/offerings/offeringsSlice";
import {archiveVenture, deleteVenture, publishVenture, unpublishVenture} from "../../redux/features/offerings/offeringActions";

const sxStyles = {
    title: {
        fontWeight: '700',
        fontSize: '1.75em',
        fontStyle: 'normal',
        color: Theme_colors.black,
        mt: '8px',
        ml: 1,
    }, description: {
        fontWeight: '400',
        fontSize: '1.125em',
        color: Theme_colors.black,
        lineHeight: '1.5em',
        mt: 5,
    }, fieldWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: `1px solid ${Theme_colors.greyFirst}`,
        p: '0.75em 0.75em',
        m: '1.5em 0',
        height: '96px',
    }, field: {
        fontWeight: '500',
        fontSize: '0.875em',
        fontStyle: 'normal',
        lineHeight: '1.125em',
        color: Theme_colors.black,
        m: '0.5em 0',
    }, link: {
        color: Theme_colors.dragonOrange, margin: '2px 2px',
    }, accredited: {
        backgroundColor: Theme_colors.greySecond, p: "1em 1em", display: 'flex', flexDirection: 'row', gap: 0.5,
    }, alertTitle: {
        fontSize: '1em', fontWeight: '400',
    }, alertContent: {
        color: Theme_colors.black, backgroundColor: Theme_colors.warning, m: '0em 2em 1em 2em',
    }, tabs: {
        textTransform: 'none', mt: '24px',
        color: Theme_colors.black,
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '1em',
        '&:hover': {
            color: Theme_colors.dragonOrange,
        }
    }, tabsBox: {
        borderBottom: 1,
        borderColor: 'divider',
        // ml: '1.5em',
        // mr: '2em'
    }, buttons: {
        height: '56px',
        color: Theme_colors.white,
        borderRadius: 0,
        mt: 2,
        boxShadow: 'none',
        fontSize: '0.875em',
        fontWeight: '500',
        width: '200px',
        "&:hover": {backgroundColor: Theme_colors.dragonOrange, boxShadow: 'none',},
        "&.Mui-disabled": {color: Theme_colors.white, boxShadow: 'none', backgroundColor: Theme_colors.greyThird,},
    },
    dialog: {
        '& .MuiPaper-root': {borderRadius: 0, alignItems: 'center',},
    },
}

export default function Offerings(props) {
    const {screen} = useContext(AppContext);
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);

    const publishedMenuElements = [
        {
            title: 'Archive',
            onClick: (id) => {
                // dispatch(archiveVenture(id));
                // dispatch(getOfferings({id: ventureType.PUBLISHED}));
            }
        },
        {
            title: 'Unpublish',
            onClick: (id) => {
                dispatch(unpublishVenture(id))
                    .then(() => {
                        dispatch(clearOfferings());
                        dispatch(getOfferings({id: ventureType.DRAFT}))
                            .then(() => {
                                setValue(1);
                            });
                    })
            }
        },
        {
            title: 'Remove',
            onClick: (id) => {
                dispatch(deleteVenture({id: id}))
                    .then(() => {
                        dispatch(clearOfferings());
                        dispatch(getOfferings({id: ventureType.PUBLISHED}));
                    });
            }
        }
    ]

    const draftMenuElements = [
        {
            title: 'Publish',
            onClick: (id) => {
                dispatch(publishVenture(id))
                    .then(() => {
                        dispatch(clearOfferings());
                        dispatch(getOfferings({id: ventureType.PUBLISHED}));
                        setValue(0);
                    });
            }
        },
        {
            title: 'Remove',
            onClick: (id) => {
                dispatch(deleteVenture({id: id, status: 'unpublished'}))
                    .then(() => {
                        dispatch(clearOfferings());
                        dispatch(getOfferings({id: ventureType.DRAFT}));
                    });
            }
        }
    ]

    useEffect(() => {
        dispatch(clearOfferings());
        dispatch(getIcons());
    }, []);

    const handleChange = (event, newValue) => {
        dispatch(clearOfferings());
        setValue(newValue);
    };

    const handleSubmittedVentureModalForm = () => {
        setValue(1);
    }

    return <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
    >
        <Grid container sx={{display: 'flex', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography sx={sxStyles.title}>Offerings</Typography>
                <ActionButton onClick={() => dispatch(showModal())} sx={{mt: '20px',}}>Create venture</ActionButton>
            </Box>
            <Box sx={{width: '100%'}}>
                <Box sx={!screen.md ? {...sxStyles.tabsBox} : null}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                          className={screen.md ? 'mobile-menu-tabs' : ''}
                    >
                        <Tab
                            label="Published" {...a11yProps(0)}
                            sx={{...sxStyles.tabs, fontSize: screen.md ? '14px' : '16px'}}
                        />
                        <Tab label="Draft" {...a11yProps(1)}
                             sx={{...sxStyles.tabs, fontSize: screen.md ? '14px' : '16px'}}
                        />
                        <Tab label="Archived" {...a11yProps(2)}
                             sx={{...sxStyles.tabs, fontSize: screen.md ? '14px' : '16px'}}
                        />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <OfferingsTable
                            menuElements={publishedMenuElements}
                            columnTitles={publishedColumns}
                            type={ventureType.PUBLISHED}
                        />
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <OfferingsTable
                            menuElements={draftMenuElements}
                            columnTitles={draftColumns}
                            type={ventureType.DRAFT}
                        />
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <OfferingsTable
                            columnTitles={archivedColumns}
                            type={ventureType.ARCHIVED}
                        />
                    </Grid>
                </TabPanel>
            </Box>
        </Grid>
        <Modal content={AddVentureView} title={'Add new venture'}/>
        <AddEditVentureModal onSubmittedForm={handleSubmittedVentureModalForm}/>
        <VentureInvestorsModal />
    </motion.div>
}

Offerings.propTypes = {};

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (<div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && (<Box sx={{m: 1}}>
            {children}
        </Box>)}
    </div>);
}

TabPanel.propTypes = {
    children: PropTypes.node, index: PropTypes.number.isRequired, value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`,
    };
}

