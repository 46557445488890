import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LinearProgress } from '@mui/material';
import { Theme_colors } from '../style/theme_colors';

const InterceptorComponent = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(config => {
            setLoading(true);
            return config;
        });

        const responseInterceptor = axios.interceptors.response.use(response => {
            setLoading(false);
            return response;
        }, error => {
            setLoading(false);
            return Promise.reject(error);
        });

        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    return (
        <div style={
            loading ? {
                position: 'fixed',
                width: '100%',
                height: '100%',
                zIndex: '100000',
            } : {}
        }
        >
            {
                loading
                ?
                <LinearProgress
                    sx={{
                        backgroundColor: Theme_colors.white,
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: Theme_colors.dragonOrange,
                        }
                    }}
                />
                :
                <></>
            }
        </div>
    );
};

export default InterceptorComponent;
