import { Typography, Box } from "@mui/material";
import { ReactComponent as ExclamationIcon } from "../../../style/icons/exclamation-mark.svg";
import { Theme_colors } from "../../../style/theme_colors";

export default function ActivityLog(props) {
    
    return (
        <p>Under construction</p>

    // <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt:5 }}>
    //         {/*<Box sx={{ maxWidth: '320px', }}>*/}
    //         {/*    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>*/}
    //         {/*        <ExclamationIcon width='32px' />*/}
    //         {/*        <Typography sx={{ fontSize: '24px', color: Theme_colors.default, fontWeight: '500', m: '24px 0' }}>*/}
    //         {/*            Under construction*/}
    //         {/*        </Typography>*/}
    //         {/*    </Box>*/}
    //         {/*</Box>*/}
        // </Box>
    )
}