import React, {useCallback, useEffect, useState} from 'react';
import {Box} from "@mui/system";
import {useDropzone} from "react-dropzone";
import {TextField} from "@mui/material";
import {Theme_colors} from "../style/theme_colors";
import {ReactComponent as ImageIcon} from "../style/icons/fa-image.svg";
import {useDispatch} from "react-redux";
import {fileUpload} from "../redux/features/upload-document/fileUploadReducer";
import fileTypes from "../utils/fileTypes";

UploadImage.propTypes = {};

function UploadImage(props) {

    const {title, existingImage, secondaryText, setValue, propName, propUrl, name, error, onChange} = props;
    const [files, setFiles] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const dispatch = useDispatch();

    const onDrop = useCallback(acceptedFiles => {
        setFiles(files => [...files, ...acceptedFiles]);
    }, []);

    useEffect(() => {
        // console.log(existingImage)
        if (existingImage) {
            // setValue(propUrl, existingImage);
            // setValue(propName, name);
        }
    }, [existingImage]);


    useEffect(() => {
        if (files.length > 0 && setValue && name) {
            if (setValue && name) {
                dispatch(fileUpload({
                    file: files[0], fileName: name, fileType: fileTypes.IMAGE,
                }))
                    .then((res) => {
                        if (res.payload) {
                            if (!('error' in res)) {
                                setImageUrl(URL.createObjectURL(files[0]));
                                setValue(propName, res.payload?.uploadedFileUrl);
                                if (onChange) {
                                    onChange({[propName]: res.payload?.uploadedFileUrl})
                                }
                            } else {
                                setFiles([]);
                                setImageUrl(null);
                                setValue(propName, null);
                                if (onChange) {
                                    onChange({[propName]: null});
                                }
                            }
                        }
                    });
            }
        } else {
            if (existingImage) {
                setImageUrl(existingImage);
            } else {
                setImageUrl(null);
            }
        }
    }, [files]);


    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop: onDrop, maxFiles: 1, accept: {
            'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'],
        }
    });

    const handleRemoveFile = (indexToRemove) => {
        setFiles(files.filter((file, index) => index !== indexToRemove));
        setImageUrl(null);
        setValue(propName, null);
        onChange({[propName]: null});
    }

    const fileList = files.map((file, index) => (<li key={file.path}>
            <span>
                {file.path} - {file.size} bytes
            </span>
        <span onClick={() => handleRemoveFile(index)}
              style={{paddingLeft: '1em', color: Theme_colors.dragonOrange, cursor: 'pointer'}}>
                Remove
            </span>
    </li>));

    return (<Box sx={{
        ...props.sx,
        border: '1px solid ' + ((error && files.length === 0 ? Theme_colors.alert : Theme_colors.greyThird)),
        p: 1,
        display: 'flex', // alignItems: 'flex-start',
        // justifyContent: 'space-between',
        flexGrow: 'full-with'
    }}>
        <img src={imageUrl ? imageUrl : '/icons/image.svg'} alt="placeholder"
             style={{
                 height: '56px', width: '50px', objectFit: files.length === 0 ? 'contain' : 'cover',
             }}/>
        <section className="container">

            <p style={{margin: '0 0 0 1em'}}>
                <span style={{fontSize: '16px', fontWeight: '500', color: Theme_colors.black}}>{title}</span>
                {secondaryText && <span
                    style={{
                        fontSize: '16px', fontWeight: '500', color: Theme_colors.greySixth
                    }}>({secondaryText})</span>}
            </p>
            {files.length === 0 && <div {...getRootProps({className: 'dropzone'})}>
                <p style={{
                    margin: '0 0 0 1em', fontSize: '16px', color: Theme_colors.greySixth, cursor: 'pointer',
                }}>Drop your file here or <span style={{color: Theme_colors.dragonOrange}}>{
                    imageUrl ? 'Update' : 'Browse'
                }</span></p>
                <input {...getInputProps()} />

            </div>}
            <aside>
                <ul style={{
                    margin: '0 0 0 1em', listStyle: 'none', padding: 0,
                }}>{fileList}</ul>
            </aside>
        </section>
    </Box>);
}

export default UploadImage;