export const Theme_colors = {
    white: '#FFFFFF', 
    alert: '#FE6B6B',   
    aqua: '#DBFAFF', 
    aquaDark: '#3AC8DB',
    aquaThird: '#1C707D',
    green:'#DBFFF2', 
    greenDark:'#17B786', 
    subTitle: '#2E3138', 
    warning: '#FFECDB',
    blurBackground:'#EAECEE66', 
    black: '#181818',
    blackSecond: '#1C1A19',
    orange: '#987801',
    dragonOrange: '#FF6C2F',
    greyFirst: '#EAECEE',
       //  backgroundAuthColor:'#EAECEE', //no usage in code
    greySecond: '#F1F3F5',
    greyThird:'#C6C6C6',
        //neutralGrey:'#C6C6C6',
    greyFourth:'#D2D4D6',
        //greyForth: '#D2D4D6', 
    greyFifth: '#4E535F',  
       // fontGrayThird:'#4E535F', //no usage in code
    greySixth: '#8E9195',
        //fontGrayFourth: '#8E9195', //no usage in code
    greySeventh: '#F8F9FA',
    greyEighth: '#D9D9D9',
    greyNinth: '#EDEFF1',
    warningDark: '#944A05',
    successLight: '#DBFFF2',
    successDark: '#117454',
    dangerLight: '#FFE5EB',
    dangerDark: '#882537',
    blueLight: '#DBFAFF',
    blueDark: '#1C707D',
    primaryColor: '#FF9846',
    yellowLight: '#FFECDB',
    yellowDark: '#944A05',
}


