import React, {useState} from 'react';
import {Box, Container, CssBaseline, TextField, Typography, Link as LinkMui} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {login} from '../../redux/features/auth/authUserSlice';
import {useFormUniversal} from '../../utils/useFormUniversal';
import ActionButton from '../../common/ActionButton';
import {motion} from "framer-motion";
import {AppContext} from '../../context/AppContext';
import {useContext} from 'react';
import {sha512} from "js-sha512";
import {Theme_colors} from '../../style/theme_colors';

const Logo = require('../../style/icons/brand-assets/Logo.svg');

const initialFormState = {
    email: {
        value: null,
        validationRules: {
            required: true,
            validateEmail: true,
        }
    },
    password: {
        value: null,
        helperText: '',
        validationRules: {
            required: true,
            validatePassword: true,
        }
    },
}

const sxStyles = {
    formItem: {
        fontWeight: '500',
        fontSize: '14px',
        mb: 3, mt: 3,
        alignItems: 'center',
        verticalAlign: 'middle',
    },
    captions: {
        margin: 0,
        fontWeight: '500',
        fontSize: '14px',
        alignItems: 'center',
        verticalAlign: 'middle',
    },
    linkTerms: {
        fontWeight: '500',
        color: Theme_colors.dragonOrange,
        textDecoration: 'none',
        margin: '0 2px',
    },
    title: {
        fontSize: '40px',
        color: Theme_colors.black,
        letterSpacing: '0.0025em',
        fontStyle: 'normal',
        fontWeight: '500',
        mb: 3,
    },
    linkItem: {
        fontSize: '20px',
        color: Theme_colors.black,
        letterSpacing: '0.0025em',
        fontStyle: 'normal',
        fontWeight: '500',
        mb: 3,
    },
    subTitle: {
        fontWeight: '400',
        fontSize: '18px',
        letterSpacing: '0.0015em',
        color: Theme_colors.subTitle,
        mb: 3, mt: 3,
    },
    inputItem: {
        mb: 3,
        '& .MuiInputLabel-root': {color: Theme_colors.greySixth},
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
        },
    },
    fixArrow: {
        margin: '0 8px',
        width: '20px',
        height: '20px',
    },
    link: {
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        cursor: 'pointer',
        color: Theme_colors.dragonOrange,
        textDecoration: 'none',
        display: 'inline-flex',
        alignItems: 'center',
    },
    linkDecoration: {
        color: Theme_colors.black,
        fontSize: '14px',
        fontWeight: '500',
        margin: '0 2px',
        textDecorationColor: Theme_colors.black,
        cursor: 'pointer',
    },
    checkbox: {
        alignItems: 'inherit',
        display: 'flex',
        '& .MuiFormControlLabel-root': {marginRight: 0, marginLeft: '-10px',},
        mb: 3,
    },
    button: {
        fontSize: '18px',
        color: Theme_colors.white,
        letterSpacing: '0.0025em',
        fontStyle: 'normal',
        fontWeight: '400',
        borderRadius: 0,
        boxShadow: 'none',
        height: '56px',
        width: '100%',
        "&:hover": {backgroundColor: Theme_colors.dragonOrange, boxShadow: 'none',},
    },
    logo: {
        mb: 3,
    },
    motionDiv: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}

function SignIn() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const {isLoading, accessToken, decodedToken} = useSelector(state => state.authUser);
    const {captchaToken} = useContext(AppContext);
    const [isChecked, setIsChecked] = useState(false);
    const [formValues, setFormValues] = useState(initialFormState);
    const {isFormValid, errors, updateValues} = useFormUniversal(formValues, setFormValues);

    const handleChange = (e) => {
        const {name, value} = e.target;
        updateValues({[name]: {...formValues[name], value}});
        setFormValues(prev => {
            return {
                ...prev, ...{
                    [name]: {...prev[name], value: value}
                }
            }
        })
    }
    const submitLogin = (e) => {
        setIsChecked(true);
        e.preventDefault();
        if (isFormValid) {
            setDisabled(true);
            dispatch(login({email: formValues.email.value, password: sha512(formValues.password.value)}))
                .then(async (resp) => {
                    if (!resp.error) {
                        await setFormValues(initialFormState);
                        navigate('/');
                        // await dispatch(getAppData()).then(()=>{
                        //
                        // });
                    }
                    setDisabled(false);
                })
        } else {
            for (let field in formValues) {
                if (formValues[field].value === null) {
                    updateValues({[field]: {...formValues[field], value: ''}});
                }
            }
        }
    }

    return (<motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        style={sxStyles.motionDiv}
    >
        <Container maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={sxStyles.logo}
            >
                <img
                    src="/Logo.svg"
                    style={{width: '100%'}}
                />
            </Box>
            <Typography variant='h5' align="left" sx={sxStyles.title}>Log in</Typography>

            <form onSubmit={submitLogin} style={sxStyles.formItem}>
                <TextField
                    name={'email'}
                    sx={sxStyles.inputItem}
                    fullWidth
                    variant="outlined"
                    label="Email"
                    autoComplete="off"
                    autoFocus
                    error={isChecked && errors.email.error}
                    helperText={(isChecked && errors.email.helperText) ? errors.email.helperText : null}
                    value={formValues.email.value || ""}
                    onChange={handleChange}
                    InputLabelProps={{shrink: true}}
                    InputProps={{inputProps: {style: {textAlign: 'start',},}, style: {borderRadius: 0,},}}
                />
                <TextField
                    name={'password'}
                    sx={sxStyles.inputItem}
                    fullWidth
                    variant="outlined"
                    label={"Password"}
                    autoComplete="off"
                    type='password'
                    helperText={(isChecked && errors.password.helperText) ? errors.password.helperText : null}
                    error={isChecked && errors.password.error}
                    value={formValues.password.value || ""}
                    onChange={handleChange}
                    InputLabelProps={{shrink: true}}
                    InputProps={{inputProps: {style: {textAlign: 'start',},}, style: {borderRadius: 0,},}}
                />

                <ActionButton
                    type="submit"
                    isLoading={isLoading}
                    variant={"contained"}
                    sx={sxStyles.button}
                    disabled={disabled}
                    onClick={submitLogin}>
                    CONTINUE
                </ActionButton>

            </form>
            {/*<Typography*/}
            {/*    sx={sxStyles.formItem}*/}
            {/*    variant="caption"*/}
            {/*    display="block"*/}
            {/*>*/}
            {/*    This site is protected by reCAPTCHA and the Google*/}
            {/*    <LinkMui href='https://policies.google.com/privacy' target={'_blank'}*/}
            {/*             style={sxStyles.linkDecoration}> Privacy Policy </LinkMui>*/}
            {/*    and*/}
            {/*    <LinkMui href='https://policies.google.com/terms' target={'_blank'}*/}
            {/*             style={sxStyles.linkDecoration}>Terms of service </LinkMui>*/}
            {/*    apply.*/}
            {/*</Typography>*/}
        </Container>
    </motion.div>)
}

SignIn.propTypes = {};

export default SignIn;