import React, {useCallback, useState} from 'react';
import {Box} from "@mui/system";
import {useDropzone} from "react-dropzone";
import {TextField} from "@mui/material";
import {Theme_colors} from "../style/theme_colors";
import {useDispatch} from "react-redux";
import {fileUpload} from "../redux/features/upload-document/fileUploadReducer";
import fileTypes from "../utils/fileTypes";
import {
    setSourceOfFunds,
    setWorkflowSettings,
    setWorkflowSettingsDirect
} from "../redux/features/offerings/manageOfferingsSlice";

UploadDocument.propTypes = {};

function UploadDocument({title, existingDocument, setValue, propName, propUrl, name, error, onChange, border = true, active = true, ...props}) {
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(files => [...files, ...acceptedFiles]);
        dispatch(fileUpload({
            file: acceptedFiles[0], fileName: acceptedFiles[0].name, fileType: fileTypes.CONTRACT
        })).then(res => {
            if (res.payload) {
                setValue(propUrl, res.payload?.uploadedFileUrl);
                dispatch(setWorkflowSettings({field: propName, value: res.payload?.uploadedFileUrl, name: name}));
                if (onChange) {
                    onChange({[propName]: res.payload?.uploadedFileUrl, name})
                }
            }
        });

    }, [dispatch, props]);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop: onDrop, maxFiles: 1, accept: {
            'application/pdf': []
        }
    });

    const handleRemoveFile = (indexToRemove) => {
        setFiles(files.filter((file, index) => index !== indexToRemove));
        setValue(propUrl, null);

        if (onChange) {
            dispatch(setWorkflowSettingsDirect({field: propName, value: null}));
            onChange({[propName]: null});
        }
    }

    const fileList = files.map((file, index) => (<li key={file.path}>
            <span>
                {file.path} - {file.size} bytes
            </span>
            <span onClick={() => handleRemoveFile(index)}
                  style={{paddingLeft: '1em', color: Theme_colors.dragonOrange, cursor: 'pointer'}}>
                Remove
            </span>
        </li>));

    const existingFile = () => {
        return (<Box key={existingDocument?.name}>
            <span>
                {existingDocument?.name}
            </span>
            <span onClick={() => {
                setValue(propUrl, null);
                // dispatch(setWorkflowSettingsDirect({field: propName, value: null}));
                // TODO: Refactor when multiple files are integrated
                handleRemoveFile(0);
            }}
                  style={{paddingLeft: '1em', color: Theme_colors.dragonOrange, cursor: 'pointer'}}>
                Remove
            </span>
        </Box>)
    }

    return (<Box sx={{
            ...props.sx,
            border: border ? '1px solid ' + ((error && files.length === 0 ? Theme_colors.alert : Theme_colors.greyThird)) : '',
            p: 1, // display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexGrow: 'full-with'
        }}>
            <section className="container">
                {
                    active
                    ?
                        <>
                            <h3 style={{
                                fontWeight: '500', fontSize: '1em',
                            }}>Document to sign</h3>
                            {(files.length === 0 && !existingDocument?.link) &&
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <p style={{
                                        color: Theme_colors.dragonOrange, cursor: 'pointer',
                                    }}>Upload document</p>
                                    <input {...getInputProps()} />

                                </div>}
                            <aside>
                                <ul style={{
                                    listStyle: 'none', padding: 0,
                                }}>
                                    {files.length > 0 && !existingDocument?.link && fileList}
                                    {existingDocument?.link && existingFile()}
                                </ul>
                            </aside>
                        </>
                        :
                        <>
                            <h3 style={{
                                fontWeight: '500', fontSize: '1em',
                            }}>Document to sign</h3>
                        </>
                }

            </section>
    </Box>);
}

export default UploadDocument;