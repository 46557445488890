import React from 'react';
import {Box} from "@mui/system";
import {Divider} from "@mui/material";

function VerticalLine(props) {
    return <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2, marginBottom: 2
    }}>
        <Divider textAlign={'center'} sx={{
            height: '24px',
        }} orientation={'vertical'}/>
    </Box>
}

export default VerticalLine;

