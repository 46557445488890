import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {Theme_colors} from "../../style/theme_colors";

const PaperStyled = styled(Paper)({
    '& .MuiPaper-root': {
        borderRadius: 0,
    },
    backgroundColor: Theme_colors.greySeventh,
    padding: '16px',
    borderRadius: 0,
    boxShadow: 'none',
});

export default PaperStyled;