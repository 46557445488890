import * as React from 'react';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Theme_colors } from '../style/theme_colors';
import {LinearProgress} from "@mui/material";
import {DataGridPro} from "@mui/x-data-grid-pro";

const StyledDataGrid = styled(DataGrid)(({ }) => ({
    border: 0,
    color: Theme_colors.greySixth,
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
    '& .MuiDataGrid-columnHeader': {
        padding: '0 16px 0 16px',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        '&:last-child': {
            borderRight: 'none',
        },
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        padding: '0 16px 0 16px',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        '&:last-child': {
            borderRight: 'none',
        },
        wordBreak: 'break-word',
    },
}));

export default function DataTable({
    sx,
    columns,
    rows,
    options,
    onRowClick,
    pageSize,
    handleStatusColumnSort,
    handleInvestedAmountColumnSort,
    handleSignUpDateColumnSort,
}) {
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    
    const handleSortModelChange = (sortModel) => {

    if (sortModel.length > 0) {
        const field = sortModel[0].field;
         if (field === "investedAmount") {
            handleInvestedAmountColumnSort(sortModel[0].sort);
        } else if (field === 'signUpDate') {
            handleSignUpDateColumnSort(sortModel[0].sort);
        } else {
          handleStatusColumnSort(sortModel[0].sort);
        }
      }
    };

    return (
        <div style={{  width: '100%',}}
        >
            <StyledDataGrid
                options={options}
                sx={sx ? sx : null}
                rows={rows}
                columns={columns}
                showCellRightBorder={true}
                autoHeight
                onRowClick={onRowClick}
                rowSelectionModel={rowSelectionModel}
                disableVirtualization
                disableColumnMenu
                autoWidth
                pageSizeOptions={[5,10,15]}
                initialState={{pagination: { paginationModel: { pageSize: pageSize ? pageSize : 5 } }}}
                onSortModelChange={handleSortModelChange}
                multiline
            />
        </div>
    );
}