import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showNotification } from "../notifications/notificationSlice";
import axios from "axios";
import { store } from "../../store";
import { serviceEnvBaseUrl } from "../../../helpers/helpers";

const initialState = {
    isLoading: false,
    hasError: false,
}

export const confirmPayment = createAsyncThunk('payments/confirm-payment', async ({ ventureId, transactionId
}, { dispatch, rejectWithValue }) => {
    const { accessToken } = store.getState().authUser;
    const token = accessToken;
    return await axios.post(serviceEnvBaseUrl() + `/ventures/${ventureId}/confirm-payment`,
        {
            'transactionId': transactionId,
        }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            dispatch(showNotification({ message: response.data.message || 'You have confirmed this payment successfully', type: "success" }));
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({ message: error.response.data.message || 'Payment confirmation unavailable', type: "error" }));
            return rejectWithValue(error.message);
        });
});

export const rejectPayment = createAsyncThunk('payments/reject-payment', async ({ ventureId, transactionId },
    { dispatch, rejectWithValue }) => {
    const { accessToken } = store.getState().authUser;
    const token = accessToken;
    return await axios.post(serviceEnvBaseUrl() + `/ventures/${ventureId}/reject-payment`,
        {
            'transactionId': transactionId,
        }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            dispatch(showNotification({ message: response.data.message || 'Payment rejected', type: "success" }));
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({ message: error.response.data.message || 'Payment rejection confirmation unavailable', type: "error" }));
            return rejectWithValue(error.message);
        });
});

export const generateNewBill = createAsyncThunk('payments/generate-new-bill', async ({ ventureId, transactionId
}, { dispatch, rejectWithValue }) => {
    const { accessToken } = store.getState().authUser;
    const token = accessToken;
    return await axios.post(serviceEnvBaseUrl() + `/ventures/${ventureId}/bill`,
        {
            'transactionId': transactionId,
        }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            dispatch(showNotification({ message: response.data.message || 'Bill regeneration successful', type: "success" }));
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({ message: error.response.data.message || 'Unable to renew this bill', type: "error" }));
            return rejectWithValue(error.message);
        });
});

export const paymentsSlice = createSlice({
    name: 'payments', initialState,
    extraReducers: (builder) => {
        const pendingAndRejectedCases = (state, action) => {
            state.hasError = action.error;
            state.isLoading = action.meta.requestStatus === 'pending';
        };
        builder.addCase(confirmPayment.fulfilled, (state, action) => {
        })
        builder.addCase(rejectPayment.fulfilled, (state, action) => {
        })
        builder.addCase(generateNewBill.fulfilled, (state, action) => {
        })
        builder
            .addMatcher(
                (action) =>
                    [confirmPayment.pending.type,
                    rejectPayment.pending.type,
                    generateNewBill.pending.type,
                    ].includes(action.type),
                pendingAndRejectedCases
            )
        builder
            .addMatcher(
                (action) =>
                    [confirmPayment.rejected.type,
                    rejectPayment.rejected.type,
                    generateNewBill.rejected.type,
                    ].includes(action.type),
                pendingAndRejectedCases
            )
    },
    reducers: {}
});

export const { } = paymentsSlice.actions

export default paymentsSlice.reducer