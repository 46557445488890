import {styled} from "@mui/system";
import {Box} from "@mui/material";
import Chip from "@mui/material/Chip";
import {Theme_colors} from "../../style/theme_colors";

const SquareChip = styled(Chip)({
    borderRadius: 0,
    margin: '2px',
});

export default SquareChip;