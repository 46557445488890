import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {showNotification} from "../notifications/notificationSlice";
import axios from "axios";
import {store} from "../../store";
import {serviceEnvBaseUrl} from "../../../helpers/helpers";

const initialState = {
    document: null, isLoading: true, hasError: false,
}

export const fileUpload = createAsyncThunk('file/upload', async ({file, fileName, fileType}, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    const formData = new FormData();

    formData.append("file", file);
    formData.append("data", JSON.stringify({fileType: fileType, ventureId: "e04ba727-0414-49ef-909a-ec617e36326e"}));

    return axios.post(serviceEnvBaseUrl() + `/file-upload`, formData, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const fileUploadSlice = createSlice({
    name: 'upload-file', initialState, extraReducers: (builder) => {
        const pendingAndRejectedCases = (state, action) => {
            state.hasError = action.error;
            state.isLoading = action.meta.requestStatus === 'pending';
        };
        builder
            .addCase(fileUpload.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
        builder
            .addMatcher((action) => [fileUpload.pending.type,].includes(action.type), pendingAndRejectedCases)
            .addMatcher((action) => [fileUpload.rejected.type,].includes(action.type), pendingAndRejectedCases)
    }, reducers: {}
});

export const {} = fileUploadSlice.actions

export default fileUploadSlice.reducer