import React, {useEffect, useImperativeHandle, useState} from 'react';
import Member from "../Member";
import {Box, Container} from "@mui/material";
import ActionButton from "../../../../common/ActionButton";
import AddTeamMemberModal from "../modal/AddTeamMemberModal";
import {useSelector} from "react-redux";


function Team(props, ref) {
    const [members, setMembers] = useState([]);
    const [open, setOpen] = useState(false);
    const {venture: {team}} = useSelector(state => state.manageOfferings);
    const addTeamMember = () => {
        setOpen(true);
    }

    useEffect(() => {
        if (team.length > 0) {
            const memberComponentsArray = [];
            team.forEach((member, id) => {
                // console.log(member)
                memberComponentsArray.push(<Member key={id} {...member}/>);
            });

            setMembers(prevState => {
                return memberComponentsArray;
            });
        }
    }, [team]);


    useImperativeHandle(ref, () => ({
        submit() {
            return ()=>{};
        },
    }), []);

    return (
        <Container maxWidth={'sm'}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center'
            }}>
                {
                    team.map((member, id) => {
                        return <Box sx={{
                            width: '100%',
                        }} key={id}>
                            <Member key={id} index={id} id={team[id]}/>
                        </Box>
                    })
                }
                <ActionButton title={'Add team member'} variant={'outlined'} onClick={addTeamMember}>
                    Add team member
                </ActionButton>
            </Box>
            <AddTeamMemberModal member={null} open={open} setOpen={setOpen}/>
        </Container>
    );
}

export default React.forwardRef(Team);