import React from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import RequireAuth from "./RequireAuth";
function RouteGenerator(props) {

    const location = useLocation();
    const {routes} = props;
    return (
        <AnimatePresence mode={(location.pathname.indexOf("auth") > -1 ? "wait" : null)} initial={false}>
            <Routes location={location} key={location.pathname}>
                {
                    routes.map(rts => {
                        if (rts.name !== 'Auth') {
                            return <Route key={rts.name}
                                          element={<RequireAuth routeName={rts.name} allowedRoles={rts.allowedRoles}/>}>
                                <Route element={rts.component} path={rts.path}>
                                    {rts?.children.map(c => {
                                        return (<Route key={c.name}
                                                       element={<RequireAuth routeName={c.name}
                                                                             allowedRoles={c.allowedRoles}/>}>
                                            <Route index={c.index === true} key={c.name} element={c.component}
                                                   path={c.path}/>
                                        </Route>)
                                    })}
                                </Route>
                            </Route>
                        } else {
                            return (
                                <Route key={rts.name} element={rts.component} path={rts.path}>
                                    {rts?.children.map(c => {
                                        return <Route index={c.index === true} key={c.name} element={c.component}
                                                      path={c.path}/>
                                    })}
                                </Route>)
                        }
                    })
                }
            </Routes></AnimatePresence>);
}

export default RouteGenerator;