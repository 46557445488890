import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {approveInvestorById, getInvestors, blockInvestorById} from "../../../redux/features/investors/investorsSlice";
import {Typography, Link, Box} from "@mui/material";
import {AppContext} from "../../../context/AppContext";
import {Theme_colors} from "../../../style/theme_colors";
import DataTable from "../../../common/DataTable";
import {priceFormatter, dateFormatter, walletAddressFormatter, copyToClipboard} from "../../../helpers/helpers";
import MenuButton from "../../../common/MenuButton";
import IconButton from "@mui/material/IconButton";
import {ReactComponent as CopyIcon} from '../../../style/icons/copy-icon.svg';

InvestorsTable.propTypes = {};

const sxStyles = {
    ventureWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        borderRadius: '40px 40px 40px 40px',
        marginRight: '8px'
    },
    typo: {
        fontSize: '16px',
        color: Theme_colors.black,
    },
    badge: {
        fontSize: '13px',
    },
    actionButton: {
        fontSize: '14px',
        color: Theme_colors.black,
        backgroundColor: Theme_colors.white,
        fontStyle: 'normal',
        fontWeight: '500',
        borderRadius: 0,
        border: `1px solid ${Theme_colors.greyThird}`,
        boxShadow: 'none',
        height: '40px',
        "&:hover": {
            backgroundColor: Theme_colors.white,
            boxShadow: 'none',
            border: `1px solid ${Theme_colors.greyThird}`,
        },
    },
}

function getDataMapper(data) {
    let temp = [];

    data?.map((item) => {
        temp.push({
            id: item.id,
            address: item.walletAddress,
            signUpDate: dateFormatter(item.createdAt),
            twitter: item.twitterUrl,
            telegram: item.telegramUrl,
            referredBy: item.referredBy ? item.referredBy : '-',
            submittedInterest: (item.investedAmount && item.investedAmount !== '') ? item.investedAmount : item.submittedInterest,
            investedAmount: item.investedAmount,
            status: item.isBlocked
                ? 'Blocked'
                : (
                    !item.accreditation
                        ? 'Pending'
                        : (
                            item.accreditation === 'SelfAccredited'
                                ? 'Approved'
                                : '-'
                        )
                ),
        });
    });

    return temp;
}

function InvestorsTable({getInvestorId}) {
    const {screen} = useContext(AppContext);
    const {investors} = useSelector(state => state.investors);
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();

    const [columnTitles, setColumnTitles] = useState([
        {field: 'address', headerName: 'Address', sortable: false, width: 260},
        {field: 'signUpDate', headerName: 'Sign up date', sortable: true, width: 160},
        {field: 'twitter', headerName: 'Twitter (X)', sortable: false, width: 160},
        {field: 'telegram', headerName: 'Telegram', sortable: false, width: 160},
        {field: 'referredBy', headerName: 'Referred by', sortable: false, width: 260},
        {field: 'submittedInterest', headerName: 'Submitted interest', align: "right", headerAlign: "right", sortable: false, width: 160},
        {field: 'investedAmount', headerName: 'Invested amount', sortable: true, align: "right", headerAlign: "right", width: 160},
        {field: 'status', headerName: 'Status', sortable: false, width: 100},
        {field: 'action', headerName: '', sortable: false, width: 72},
    ]);

    const handleStatusColumnSort = () => {
        const sorted = [...rows];
        const isColumnSorted = columnTitles.find((column) => column.field === 'name').sortable;
        const isColumnSortedAscending = columnTitles.find((column) => column.field === 'name').sortDirection === 'asc';
        sorted.sort((a, b) => {
            const nameA = a.firstName.toLowerCase();
            const nameB = b.firstName.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
            });
        
            if (isColumnSorted && isColumnSortedAscending) {
                setRows(sorted.reverse());
            } else {
                setRows(sorted);
            }
            const updatedColumnTitles = columnTitles.map((column) => {
                if (column.field === 'name') {
                    return {
                        ...column,
                        sortable: true,
                        sortDirection: isColumnSortedAscending ? 'desc' : 'asc',
                    };
                }
                return column;
            });
            setColumnTitles(updatedColumnTitles);
      };

    const handleInvestedAmountColumnSort = () => {
        const sorted = [...rows];
        const isColumnSorted = columnTitles.find((column) => column.field === 'investedAmount').sortable;
        const isColumnSortedDescending = columnTitles.find((column) => column.field === 'investedAmount').sortDirection === 'desc';
        sorted.sort((a, b) => {
          return a.investedAmount - b.investedAmount;
        });

        if (isColumnSorted && isColumnSortedDescending) {
            setRows(sorted);
        } else {
            setRows(sorted.reverse());
        }

        const updatedColumnTitles = columnTitles.map((column) => {
          if (column.field === 'investedAmount') {
            return {
              ...column,
              sortable: true,
              sortDirection: isColumnSortedDescending ? 'asc':'desc',
            };
          }
          return column;
        });

        setColumnTitles(updatedColumnTitles);
    };

    const handleSignUpDateColumnSort = () => {
        const sorted = [...rows];
        const isColumnSorted = columnTitles.find((column) => column.field === 'signUpDate').sortable;
        const isColumnSortedDescending = columnTitles.find((column) => column.field === 'signUpDate').sortDirection === 'desc';
        sorted.sort((a, b) => {
            return a.signUpDate - b.signUpDate;
        });

        if (isColumnSorted && isColumnSortedDescending) {
            setRows(sorted);
        } else {
            setRows(sorted.reverse());
        }

        const updatedColumnTitles = columnTitles.map((column) => {
            if (column.field === 'signUpDate') {
                return {
                    ...column,
                    sortable: true,
                    sortDirection: isColumnSortedDescending ? 'asc':'desc',
                };
            }
            return column;
        });

        setColumnTitles(updatedColumnTitles);
    }

    const menuElements = (row) => {
        const elements = [];

        if (row.status === 'Pending') {
            elements.push({
                title: 'Approve investor',
                onClick: (id) => {
                    dispatch(approveInvestorById(id))
                        .then(() => {
                            dispatch(getInvestors());
                        });
                },
            });
        }

        elements.push({
            title: 'Block investor',
            onClick: (id) => {
                dispatch(blockInvestorById(id))
                    .then(() => {
                        dispatch(getInvestors());
                    });
            },
        });

        return elements;
    };

    useEffect(() => {
        dispatch(getInvestors());
    }, [dispatch])

    useEffect(() => {
        if (investors) {
            setRows(getDataMapper(investors))
        }
    }, [investors]);

    const columns = columnTitles.map((title) => ({
        ...title,
        renderCell: (params) => {
            switch (title.field) {
                case 'address':
                    return (
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                            {/*<Link*/}
                            {/*    href={`https://etherscan.io/address/${params.row.address}`}*/}
                            {/*    target={'_blank'}*/}
                            {/*    style={sxStyles.link}*/}
                            {/*    sx={{'&:hover': {cursor: 'pointer'}, textDecoration: 'none'}}*/}
                            {/*>*/}
                                <Typography
                                    sx={{
                                        ...sxStyles.typo,
                                        color: Theme_colors.primaryColor,
                                        '&:hover': {cursor: 'pointer'},
                                    }}
                                    onClick={() => getInvestorId(params.row.id)}
                                >
                                    {walletAddressFormatter(params.row.address)}
                                </Typography>
                            {/*</Link>*/}
                            <IconButton
                                disableRipple
                                onClick={() => copyToClipboard(params.row.address)}
                            >
                                <CopyIcon />
                            </IconButton>
                        </Box>
                    );
                case 'twitter':
                    return (
                        <Link
                            href={params.row.twitter.includes('http') ? params.row.twitter : `https://${params.row.twitter}`}
                            target={'_blank'}
                            style={sxStyles.link}
                            sx={{'&:hover': {cursor: 'pointer'}, textDecoration: 'none'}}
                        >
                            <Typography sx={{...sxStyles.typo, color: Theme_colors.primaryColor}}>
                                {params.row.twitter}
                            </Typography>
                        </Link>
                    );
                case 'telegram':
                    return (
                        <Link
                            href={`https://t.me/${params.row.telegram}`}
                            target={'_blank'}
                            style={sxStyles.link}
                            sx={{'&:hover': {cursor: 'pointer'}, textDecoration: 'none'}}
                        >
                            <Typography sx={{...sxStyles.typo, color: Theme_colors.primaryColor}}>
                                {params.row.telegram}
                            </Typography>
                        </Link>
                    );
                case 'signUpDate':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {params.row.signUpDate}
                        </Typography>
                    );
                case 'referredBy':
                    return (
                        params.row.referredBy !== '-'
                        ?
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                            <Typography
                                sx={{
                                    ...sxStyles.typo,
                                    color: Theme_colors.primaryColor,
                                    '&:hover': {cursor: 'pointer'},
                                }}
                            >
                                {walletAddressFormatter(params.row.referredBy)}
                            </Typography>
                            <IconButton
                                disableRipple
                                onClick={() => copyToClipboard(params.row.referredBy)}
                            >
                                <CopyIcon />
                            </IconButton>
                        </Box>
                        :
                        <Typography sx={sxStyles.typo}>-</Typography>
                    );
                case 'submittedInterest':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {(params.row.submittedInterest > 0 ? priceFormatter(params.row.submittedInterest, false) : '-')}
                        </Typography>
                    );
                case 'investedAmount':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {(params.row.investedAmount > 0 ? priceFormatter(params.row.investedAmount, false) : '-')}
                        </Typography>
                    );
                case 'status':
                    return (
                        <Typography
                            sx={{
                                ...sxStyles.typo,
                                color: params.row.status === 'Blocked'
                                    ? Theme_colors.dangerDark
                                    : (
                                        params.row.status === 'Approved'
                                        ? Theme_colors.successDark
                                        : (
                                            params.row.status === 'Pending'
                                                ? Theme_colors.yellowDark
                                                : Theme_colors.black
                                        )
                                    ),
                                backgroundColor: params.row.status === 'Blocked'
                                    ? Theme_colors.dangerLight
                                    : (
                                        params.row.status === 'Approved'
                                            ? Theme_colors.successLight
                                            : (
                                                params.row.status === 'Pending'
                                                    ? Theme_colors.yellowLight
                                                    : Theme_colors.greySixth
                                            )
                                    ),
                                fontSize: '13px',
                                fontWeight: 500,
                                padding: '3px 8px',
                            }}
                        >
                            {params.row.status}
                        </Typography>
                    );
                case 'action':
                    return (
                        <MenuButton rowId={params.row.id} elements={menuElements(params.row)}/>
                    );
                default:
                    return null;
            }
        }
    }));

    return (
        <DataTable
            sx={{
                mt: '40px',
                '&.MuiDataGrid-root': {
                    color: Theme_colors.greySixth,
                    border: 'none',
                    borderRadius: 'none',
                    fontSize: '16px',
                },
            }}
            pageSize={10}
            columns={columns}
            rows={rows}
            handleStatusColumnSort={handleStatusColumnSort}
            handleInvestedAmountColumnSort={handleInvestedAmountColumnSort}
            handleSignUpDateColumnSort={handleSignUpDateColumnSort}
        />
    );
}

export default InvestorsTable;