export default [
    'Fintech',
    'Insurtech',
    'Medtech',
    'SaaS',
    'Web3',
    'G-AI',
    'HR & Recruitment',
    'Infrastructure/Deep Tech',
    'Consumer Tech',
    'Entertainment',
    'Games',
    'DTC/Commerce',
    'Other'
]