import {Box} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";


const sxStyles = {

}
export default function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (<div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && (<Box sx={{ m: 1 }}>
            {children}
        </Box>)}
    </div>);
}

TabPanel.propTypes = {
    children: PropTypes.node, index: PropTypes.number.isRequired, value: PropTypes.number.isRequired,
};