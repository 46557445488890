import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import {Container, Stack} from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {Box} from "@mui/system";
import {useDispatch, useSelector} from "react-redux";
import {
    closeVentureModal,
    editVenture,
} from "../../../../redux/features/offerings/manageOfferingsSlice";
import Workflow from "../tabs/Workflow";
import General from "../tabs/General";
import Summary from "../tabs/Summary";
import Documents from "../tabs/Documents";
import Team from "../tabs/Team";
import ContactUs from "../tabs/ContactUs";
import {useEffect, useRef, useState} from "react";
import SaveButton from "../../../../common/SaveButton";
import {ReactComponent as BackIcon } from "../../../../style/icons/arrow-left.svg";
import {clearOfferings, getOfferings} from "../../../../redux/features/offerings/offeringsSlice";
import ventureType from "../../../../utils/ventureType";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const sxStyles = {
    tab: {
        p: 3,
    }
};

export default function AddVentureModal({onSubmittedForm}) {
    const {open, name, validatedForms, venture, id, isLoading, type} = useSelector(state => state.manageOfferings);
    const dispatch = useDispatch();
    const [value, setValue] = useState('1');
    const contactUsRef = useRef(null);
    const teamRef = useRef(null);
    const documentsRef = useRef(null);
    const summaryRef = useRef(null);
    const generalRef = useRef(null);
    const workflowRef = useRef(null);
    const [disabled, setDisabled] = useState(false);
    const [invalidFormSubmitted, setInvalidFormSubmitted] = useState(false);

    useEffect(() => {
        setDisabled(false);
    }, [venture]);

    useEffect(() => {
        if (invalidFormSubmitted) {
            navigateToError();
            setInvalidFormSubmitted(false);
        }
    }, [invalidFormSubmitted]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        dispatch(closeVentureModal({open: false}));
    };

    const isFormValid = () => {
        return !Object.values(validatedForms).some((value) => !value);
    }

    const navigateToError = () => {
        if (!validatedForms.workflowSettings) {
            setValue('1');
        } else if (!validatedForms.general) {
            setValue('2');
        } else if (!validatedForms.contactUs) {
            setValue('6');
        }
    }

    const submitForm = (data) => {
        workflowRef.current.submit();
        generalRef.current.submit();
        summaryRef.current.submit();
        documentsRef.current.submit();
        contactUsRef.current.submit();

        if (isFormValid()) {
            dispatch(editVenture({id: venture.id, venture: venture}))
                .then(() => {
                    dispatch(clearOfferings());
                    dispatch(getOfferings({id: 'Unpublished'}))
                        .then(() => {
                            dispatch(closeVentureModal({open: false}));
                            onSubmittedForm();
                        });
                });
        } else {
            setInvalidFormSubmitted(true);
            setDisabled(true);
        }
    };

    return (<div>
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <Container maxWidth={'xl'} sx={{p: 0}}>
                <AppBar elevation={0} sx={{position: 'relative', backgroundColor: 'transparent !important'}}>
                    <Toolbar sx={{
                        backgroundColor: 'transparent !important',
                    }}>
                        <Stack  sx={{flex: 1}} direction="row" alignItems="center" gap={1}>
                            <BackIcon onClick={handleClose} style={{
                                cursor: 'pointer',
                            }} />
                            <Typography sx={{ml: 1}} variant="h6" component="div">
                                {name}
                            </Typography>
                        </Stack>
                        <SaveButton
                            type={type}
                            disabled={disabled}
                            submit={submitForm}
                            title={'Save'}
                        />
                    </Toolbar>
                </AppBar>
                <Box sx={{width: '100%', ml: 2, typography: 'body1'}}>
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Workflow" value="1"/>
                                <Tab label="General" value="2"/>
                                <Tab label="Summary" value="3"/>
                                <Tab label="Document" value="4"/>
                                <Tab label="Team" value="5"/>
                                <Tab label="Contact us" value="6"/>
                            </TabList>
                        </Box>
                        <Box sx={sxStyles.tab} value="1" hidden={value !== '1'}>
                            <Workflow ref={workflowRef} isDraft={type === ventureType.DRAFT}/>
                        </Box>
                        <Box sx={sxStyles.tab} value="2" hidden={value !== '2'}>
                            <General ref={generalRef} isDraft={type === ventureType.DRAFT}/>
                        </Box>
                        <Box sx={sxStyles.tab} value="3" hidden={value !== '3'}>
                            <Summary ref={summaryRef} isDraft={type === ventureType.DRAFT}/>
                        </Box>
                        <Box sx={sxStyles.tab} value="4" hidden={value !== '4'}>
                            <Documents ref={documentsRef} isDraft={type === ventureType.DRAFT}/>
                        </Box>
                        <Box sx={sxStyles.tab} value="5" hidden={value !== '5'}>
                            <Team ref={teamRef} isDraft={type === ventureType.DRAFT}/>
                        </Box>
                        <Box sx={sxStyles.tab} value="6" hidden={value !== '6'}>
                            <ContactUs ref={contactUsRef} isDraft={type === ventureType.DRAFT}/>
                        </Box>
                    </TabContext>
                </Box>
            </Container>
        </Dialog>
    </div>);
}