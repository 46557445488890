export const REGEX_EMAIL = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/;
export const REGEX_PASS = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
export const REGEX_LINKEDIN = /(^(https?:\/\/)?(([\w]{2,3})?\.)?linkedin\.com\/)(([\w]{2,3})\/)?(([\w-]+\/){0,})?([\w-]+\/?)?$/;
export const REGEX_GUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
export const REGEX_TWITTER = /^@?(\w){1,15}$/;
export const REGEX_WEBSITE = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
export const REGEX_PHONE = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/

export const REGEX_POSITIVE_NUMBERS = /^[+]?([.]\d+|\d+[.]?\d*)$/
export const validateLinkedin = (value) => {
    let responseObject = {
        helperText: '',
        error: false
    }
    if (REGEX_LINKEDIN.test(value)) {
        responseObject.error = false;
        responseObject.helperText = "";
    } else {
        responseObject.helperText = "Please enter a valid Linkedin address";
        responseObject.error = true;
    }
    return responseObject;
}

export const validateEmail = (object) => {

    let responseObject = {
        helperText: '',
        error: false
    }
    if (REGEX_EMAIL.test(object.value)) {
        responseObject.error = false;
        responseObject.helperText = "";
    } else {
        responseObject.helperText = "Please enter a valid email address";
        responseObject.error = true;
    }
    return responseObject;
}

export const validatePassword = (object) => {
    let responseObject = {
        helperText: '',
        error: false
    }

    if (!REGEX_PASS.test(object.value)) {
        responseObject.helperText = "Password: 6-20 chars, 1 num, 1 uppercase, 1 lowercase.";
        responseObject.error = true;
    }
    return responseObject;
}


export const confirmPassword = (object, formValues) => {
    let responseObject = {
        helperText: '',
        error: false
    }
    if (object.value !== formValues.password.value) {
        responseObject.helperText = "Passwords do not match";
        responseObject.error = true;
    }
    return responseObject;
}

export const validateName = (object) => {
    let responseObject = {
        helperText: '',
        error: false
    }

    // let response = nameSeparator(object);
    let response = object.value.trim().length > 2 ? true : false;
    if (response) {
        responseObject.error = false;
        responseObject.helperText = '';
    } else {
        responseObject.error = true;
        responseObject.helperText = `Plese enter your name`;
    }
    return responseObject;
}

export const nameSeparator = (object) => {
    if (object.value.trim().split(" ").length >= 2) {
        let nameArr = object.value.split(" ")
        let [firstName, lastName] = nameArr;
        if (firstName.trim().length >= 2 && lastName.trim().length >= 2) {
            const name = {
                firstName: firstName,
                lastName: lastName,
            }
            return name;
        }
    }
    return false;
}
export const checkForError = (errorsData, setIsFormValid, formValues) => {
    let isFormValid = true;
    for (const field of Object.keys(errorsData)) {
        if (errorsData[field].error === true || (errorsData[field].error === false && formValues[field].value === null)) {
            isFormValid = false;
        }
    }
    setIsFormValid(isFormValid);
}

export const required = (formValues) => {
    let responseObject = {
        helperText: "",
        error: false,
    }
    if (!formValues.value) {
        responseObject.helperText = "This field is required";
        responseObject.error = true;
    }

    return responseObject;
}
export const validateAmount = (object) => {
    let responseObject = {
        helperText: '',
        error: false
    }
    if (object.value > 0) {
        responseObject.error = false;
        responseObject.helperText = "";
    } else {
        responseObject.helperText = "Amount is required";
        responseObject.error = true;
    }
    return responseObject;
}

export const guidIsValid = (value) => {
    if(REGEX_GUID.test(value)){
        return true;
    }
    return false;
}

export const validationRulesData = {
    validateName,
    validateEmail,
    validatePassword,
    required,
    confirmPassword,
    validateAmount,
    validateLinkedin,
}


