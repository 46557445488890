import { Typography, Box, Button, MenuList, Divider, Paper } from "@mui/material";
import { Theme_colors } from "../../../style/theme_colors";
import { ReactComponent as Dots } from "../../../style/icons/dots.svg";
import { ReactComponent as BlackDots } from "../../../style/icons/black-dots.svg";
import { ReactComponent as TwitterIcon } from "../../../style/icons/twittersm.svg";
import { ReactComponent as TelegramIcon } from "../../../style/icons/telegram.svg";
import { ReactComponent as TicketIcon } from "../../../style/icons/ticket-size-icon.svg";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { approveInvestorById, blockInvestorById, getInvestorById } from "../../../redux/features/investors/investorsSlice";
import { showNotification } from "../../../redux/features/notifications/notificationSlice";

const sxStyles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        justifyContent: 'flex-start',
        alignItems: 'center',
        mt: 2
    },
    box: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: `1px solid ${Theme_colors.greyFirst}`,
        padding: '20px 16px'
    },
    secondBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: `1px solid ${Theme_colors.greyFirst}`,
        padding: '20px 16px',
        gap: '24px',
        maxWidth: '728px',
        minWidth: '320px',
        width: '100%'
    },
    thirdBox: {
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${Theme_colors.greyFirst}`,
        padding: '20px 16px',
        gap: '24px',
        maxWidth: '728px',
        minWidth: '320px',
        width: '100%'
    },
    summaryBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '12px'
    },
    statusBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    typo: {
        fontSize: '16px',
        fontWeight: 400,
        color: Theme_colors.black,
    },
    button: {
        borderRadius: 0,
        minWidth: '40px',
        width: '40px',
        height: '40px',
        border: `1px solid ${Theme_colors.greyThird}`,
        '&:hover': { backgroundColor: 'inherit', }
    },
    menu: {
        position: 'absolute',
        m: '1em 0em',
        p: '0em 0em',
        alignItems: 'center',
        borderRadius: 0,
        '& .MuiPaper-root': { borderRadius: 0, },
    },
    listItem: {
        '&:hover': { backgroundColor: 'inherit', },
        p: '0 16px',
        fontWeight: '500',
        lineHeight: '16px',
        fontSize: '16px',
    },
    warning: {
        backgroundColor: Theme_colors.warning,
        color: Theme_colors.warningDark,
        fontSize: '13px',
        padding: '3px 8px',
        borderRadius: 0,
    },
    pending: {
        backgroundColor: Theme_colors.greyEighth,
        color: Theme_colors.black,
        fontSize: '13px',
        padding: '3px 8px',
        borderRadius: 0,
    },
    success: {
        backgroundColor: Theme_colors.successLight,
        color: Theme_colors.successDark,
        fontSize: '13px',
        padding: '3px 8px',
        borderRadius: 0,
    },
    blocked: {
        ml: 2,
        color: Theme_colors.alert,
        fontSize: '12px',
        fontWeight: 500,
        padding: '2px 8px',
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
    }
}

export default function Profile() {
    const dispatch = useDispatch();
    const { investorInfo } = useSelector((state) => state.investors);
    const [anchorEl, setAnchorEl] = useState(null);
    const opp = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const blockInvestor = () => {
        if (investorInfo.investor?.isBlocked) {
            dispatch(showNotification({message: 'Investor is already blocked', type: 'error'}));
        } else {
            dispatch(blockInvestorById(investorInfo.investor?.id))
                .then(() => {
                    dispatch(getInvestorById(investorInfo.investor?.id));
                });
        }
        handleClose();
    };

    const approveInvestor = () => {
        if (investorInfo.investor?.isBlocked) {
            dispatch(showNotification({message: 'Investor is blocked', type: 'error'}));
        } else {
            dispatch(approveInvestorById(investorInfo.investor?.id))
                .then(() => {
                    dispatch(getInvestorById(investorInfo.investor?.id));
                });
        }
    }

    return (
        <Box sx={sxStyles.wrapper}>
            <Box sx={{ position: 'relative', maxWidth: '728px', minWidth: '320px', width: '100%' }} >
                <Box sx={{ ...sxStyles.box, minHeight: '72px', }}>
                    <Typography sx={{ display: 'flex', fontSize: '18px', fontWeight: 400, color: Theme_colors.black }} component={'div'}>
                        {investorInfo ? investorInfo.investor.walletAddress : ''}
                        {
                            investorInfo.investor?.isBlocked
                            &&
                            <Paper sx={sxStyles.blocked}>
                                BLOCKED
                            </Paper>
                        }
                    </Typography>
                    <Box sx={{ position: 'absolute', right: '16px' }}>
                        <Button
                            disableRipple
                            sx={sxStyles.button}
                            id="basic-button"
                            aria-controls={opp ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={opp ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            {!opp ? <BlackDots /> : <Dots />}
                        </Button>
                        <Menu
                            sx={sxStyles.menu}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={opp}
                            onClose={handleClose}
                        >
                            <MenuList sx={{ boxShadow: false }}>
                                {
                                    !investorInfo.investor?.accreditation
                                    &&
                                    <MenuItem
                                        sx={{ ...sxStyles.listItem }}
                                        onClick={() => approveInvestor()}
                                    >
                                        Approve investor
                                    </MenuItem>
                                }
                                <MenuItem
                                    sx={{ ...sxStyles.listItem, color: Theme_colors.alert, mt: '16px' }}
                                    onClick={() => blockInvestor()}
                                >
                                    Block Investor
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', maxWidth: '728px', minWidth: '320px', width: '100%' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 400, color: Theme_colors.greySixth, }}>
                    {`Summary`}
                </Typography>
            </Box>
            <Box sx={sxStyles.secondBox}>
                <Box sx={sxStyles.summaryBox}>
                    <TwitterIcon width='16px' height='16px' />
                    <Typography sx={sxStyles.typo}>
                        {investorInfo ? investorInfo.investor.twitterUrl : ''}
                    </Typography>
                </Box>
                <Box sx={sxStyles.summaryBox}>
                    <TelegramIcon width='16px' height='16px' />
                    <Typography sx={sxStyles.typo}>
                        {investorInfo ? investorInfo.investor.telegramUrl : ''}
                    </Typography>
                </Box>
                <Box sx={sxStyles.summaryBox}>
                    <TicketIcon width='16px' height='16px' />
                    <Typography sx={sxStyles.typo}>
                        Referred by: {investorInfo.investor.referredBy}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', maxWidth: '728px', minWidth: '320px', width: '100%' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 400, color: Theme_colors.greySixth }}>
                    {`Status`}
                </Typography>
            </Box>
            <Box sx={sxStyles.thirdBox}>
                <Box sx={sxStyles.statusBox}>
                    <Typography sx={sxStyles.typo}>
                        {'Admin approval'}
                    </Typography>
                    <Paper elevation={0} sx={sxStyles[investorInfo.investor?.accreditation ? 'success' : 'pending']}>
                        {investorInfo.investor?.accreditation ? 'Approved' : 'Pending'}
                    </Paper>
                </Box>
            </Box>
        </Box>
    )
}