import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {Box} from "@mui/system";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Editor} from '@tinymce/tinymce-react';
import {Theme_colors} from "../../../style/theme_colors";
import ImageButton from "../../../style/images/image-button.png";
import AddIconModal from "./modal/AddIconModal";
import {ReactComponent as MoreIcon} from "../../../style/icons/more-button.svg";
import {Menu, MenuItem} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {
    sectionExpand,
    setListValidation,
    setSummaryValue
} from "../../../redux/features/offerings/manageOfferingsSlice";
import InputField from "../../../common/styled-components/InputField";

const sxStyles = {
    color: 'white',
    accordion: {
        backgroundColor: Theme_colors.greySeventh,
        mb: 2,
        p: 1,
        borderRadius: '0px !important',
        '& .MuiPaper-root': {
            borderRadius: 0,
        }

    },
    expandIcon: {
        wrapper: {
            border: '1px solid ' + Theme_colors.greyThird,
            width: '47px',
            height: '47px',
            verticalAlign: 'middle',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '100',
        },
        icon: {
            display: 'block',
            position: 'relative',
        }
    },
    accordionSummary: {
        backgroundColor: Theme_colors.greySeventh,
        '& .MuiExpanded': {
            margin: 0
        }
    },
    iconWrapper: {
        display: 'block',
        position: 'relative',
        flexShrink: 0,
        width: '48px',
        height: '48px',
        backgroundColor: Theme_colors.white,
        '&:hover> div': {
            cursor: 'pointer',
            backgroundColor: '#0000000f',
            transition: 'all 0.3s ease-in-out',
        }
    },
    imageMask: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}

const CustomExpand = ({expanded, onClick}) => {

    const iconStyles = {...sxStyles.expandIcon?.icon, ...{transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)'}};

    return <Box onClick={onClick} id={'expand'} sx={sxStyles.expandIcon?.wrapper}>
        <ExpandMoreIcon id={'expand-icon'} style={iconStyles}/>
    </Box>
}

const editorConfig = {
    height: 500,
    menubar: true,
    plugins: [
        "image",
        "code",
        "table",
        "link",
        "media",
        "codesample",
        "advlist",
        "autolink",
        "lists",
        "charmap",
        "preview",
        "anchor",
        "searchreplace",
        "visualblocks",
        "fullscreen",
        "insertdatetime",
        "media",
        "table",
        "help",
        "wordcount"
    ],
    toolbar: 'undo redo | formatselect | ' +
        'bold italic backcolor | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'removeformat | help',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
};

function EditorComponent({expand = false, id, removeSection}, ref) {

    const {venture: {summary}, validatedForms} = useSelector(state => state.manageOfferings);
    const [expanded, setExpanded] = React.useState(expand);
    const editorRef = useRef(null);
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const {icons} = useSelector(state => state.app);
    const dispatch = useDispatch();

    const [icon, setIcon] = useState({
        name: '',
        url: ''
    });

    const {
        control,
        handleSubmit,
        formState: {errors},
        getValues,
        setValue,
        reset,
        watch
    } = useForm({
        defaultValues: {},
        mode: "onChange"
    });

    useEffect(() => {
        if (summary.length > 0) {
            const currentSummary = summary[id];
            setValue('title', currentSummary.title);
            const icon = icons.find(icon => icon.name === currentSummary.icon);
            setValue('description', currentSummary.description);
            setValue('icon', icon);
            setIcon(icon);
        }
    }, [])

    useEffect(() => {
        if (Object.keys(errors).length) {
            dispatch(setListValidation({
                entity: 'summary',
                index: id,
                value: false
            }))
        }
    }, [errors.title, errors.description, errors.icon]);

    useEffect(() => {
        if (editorRef.current) {
            const currentSummary = summary[id];
            editorRef.current.setContent(currentSummary.description);
        }
    }, [editorRef]);

    useImperativeHandle(ref, () => ({
        submit() {
            return submitFormAct();
        },
    }), []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    }

    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const handleExpand = () => {
        dispatch(sectionExpand({id}))
    }

    const handleRemoveSection = () => {
        removeSection(id);
    }

    const onSubmit = (data) => {
        dispatch(setListValidation({
            entity: 'summary',
            index: id,
            value: true
        }))
    }

    const submitFormAct = () => {
        return handleSubmit(onSubmit)()
    }

    const handleIconChange = (icon) => {
        setIcon(icon);
        setValue('icon', icon.name);
        dispatch(setSummaryValue({field: 'icon', value: icon.name, index: id}))
        handleClose();
    }


    return (
        <Box component={'form'} onSubmit={handleSubmit(onSubmit)} sx={{
            mb: 2,
            borderRadius: 0
        }}>
            <Accordion
                elevation={0}
                sx={sxStyles.accordion} expanded={summary[id].expand === true}>
                <AccordionSummary
                    style={sxStyles.accordionSummary}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Box sx={sxStyles.iconWrapper} onClick={handleClickOpen}>
                        <Box sx={sxStyles.imageMask}></Box>
                        <Controller
                            name="icon"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({field}) =>
                                <>
                                    {
                                        icon?.name ? <img src={icon.url} alt={icon?.name} style={{
                                            width: '100%',
                                            height: '100%',
                                            border: '1px solid ' + Theme_colors.greyThird,
                                        }}/> : <img src={ImageButton} alt={'add icon'} style={{
                                            width: '100%',
                                            height: '100%',
                                            border: '1px solid ' + (Boolean(errors.icon) ? Theme_colors.alert : Theme_colors.greyThird),
                                        }}/>
                                    }
                                </>}
                        />
                    </Box>
                    <Controller
                        name="title"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({field}) =>
                            <InputField
                                name={'title'}
                                value={summary[id].title}
                                onChange={(e) => {
                                    field.onChange(e.target.value)
                                    dispatch(setSummaryValue({field: e.target.name, value: e.target.value, index: id}))
                                }}
                                fullWidth={true}
                                error={Boolean(errors.title)}
                                label="Title"
                                id="outlined-start-adornment"
                                sx={{
                                    ml: 2, mr: 2,
                                }}
                            />}
                    />
                    <CustomExpand onClick={handleExpand} expanded={expanded}/>
                </AccordionSummary>
                <AccordionDetails>
                    <Editor
                        apiKey={'0z2361vznz5ey985ymleu971vlu82f04ib99yf37ecjjg112'}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={useMemo(() => {
                            return summary[id].description;
                        }, [])}
                        // value={summary[id].description}
                        init={editorConfig}
                        onChange={(e) => {
                            dispatch(setSummaryValue({field: 'description', value: e.target.getContent(), index: id}))
                        }}
                    />
                    <div>
                        <MoreIcon style={{
                            marginTop: '10px',
                            float: 'right'
                        }} onClick={handleClick} className={'more-icon'}/>
                        <Menu
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: Theme_colors.dragonOrange
                                }
                            }} onClick={handleRemoveSection}>Remove section</MenuItem>
                        </Menu>
                    </div>
                </AccordionDetails>
                <AddIconModal setIcon={handleIconChange} onClose={handleClickClose} open={open} setOpen={setOpen}/>
            </Accordion>
        </Box>
    );
}

export default React.forwardRef(EditorComponent);