import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box} from "@mui/system";
import {Theme_colors} from "../../../style/theme_colors";
import Typography from "@mui/material/Typography";
import AddTeamMemberModal from "./modal/AddTeamMemberModal";
import {useSelector} from "react-redux";

const sxStyles = {
    member: {
        border: '1px solid' + Theme_colors.greyFourth,
        height: '88px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 2,
        padding: 1,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: Theme_colors.greySecond,
            transition: 'background-color 0.2s ease-in-out',
        }
    },
    image: {
        height: '50px', width: '50px', objectFit: 'contain',
        borderRadius: '40px',
        border: '1px solid' + Theme_colors.greyFourth,
    }
}

function Member({index, photoUrl}) {

    const [open, setOpen] = useState(false);
    const {venture: {team}} = useSelector(state => state.manageOfferings);
    const [member, setMember] = useState(team[index]);

    useEffect(() => {
        setMember(team[index]);
    },[team,index]);

    const handleClickOpen = (event) => {
        if (event.target.getAttribute('name') === 'member') {
            setOpen(true);
        }
    }

    return (
        <Box name={'member'} onClick={handleClickOpen} sx={sxStyles.member}>
            <img src={member?.photoUrl || '/icons/image.svg'} alt="placeholder" style={sxStyles.image}/>
            <Typography>
                {member?.name}
            </Typography>
            <AddTeamMemberModal key={Math.random()} index={index} member={member} open={open} setOpen={setOpen}/>
        </Box>
    );
}

export default Member;