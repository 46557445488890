import {createSlice} from '@reduxjs/toolkit'
const initialState = {
    open: false,
    editVentureModal: false,
}

export const modalSlice = createSlice({
    name: 'notifications', initialState, reducers: {
        showModal: (state, action) => {
            return {...state, ...initialState, ...action.payload, open: true}
        }, closeModal: (state, action) => {
            return {
                ...state, ...action.payload, open: false,
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    showModal, closeModal
} = modalSlice.actions

export default modalSlice.reducer