import React, {useEffect, useState} from 'react';
import {Box, Container} from "@mui/system";
import {Theme_colors} from "../../../../style/theme_colors";
import {useDispatch, useSelector} from "react-redux";
import {getOfferings} from "../../../../redux/features/offerings/offeringsSlice";
import {CircularProgress, Typography} from "@mui/material";
import {priceFormatter, numberWithSeparator} from "../../../../helpers/helpers";
import DataTable from "../../../../common/DataTable";
import moment from "moment";
import ActionButton from "../../../../common/ActionButton";
import {ReactComponent as InfoIcon} from "../../../../style/icons/info-icon.svg";
import {showModal} from "../../../../redux/features/notifications/modalSlice";
import {
    getVenture,
    showVentureModal,
    showVentureInvestorsModal,
    getVentureInvestors,
} from "../../../../redux/features/offerings/manageOfferingsSlice";
import ventureType from "../../../../utils/ventureType";
import sxStyles from "./sxStyles";
import './style.scss'
import MenuButton from "../../../../common/MenuButton";

function getDataMapper(data) {
    let temp = [];

    data?.forEach((item) => {
        const logoUrl = item.ventureInfo.logoUrl ? item.ventureInfo.logoUrl : '/icons/service_avatar.svg';
        const startDate = new Date(item?.startDate);
        const endDate = new Date(item?.dueDate);
        const nowDate = new Date();

        temp.push({
            id: item.ventureInfo.id,
            name: {
                name: item.ventureInfo.name, logoUrl: logoUrl,
            },
            startDate: item?.startDate,
            dueDate: item?.dueDate,
            ticketsSold: numberWithSeparator(item?.ticketsInfo?.investorTickets),
            investors: item?.offeringDetails?.ticketsTotal,
            submittedInterest: item?.submittedInterestAmount,
            investedAmount: item?.investedAmount,
            ticketSize: item?.offeringDetails?.ticketSize,
            valuation: item?.offeringDetails?.valuation,
            offeringSize: item?.offeringDetails?.offeringSize,
            investorsCount: item?.investmentsCount,
            status: nowDate > endDate ? 'Filled' : (nowDate > startDate && nowDate < endDate ? 'Active' : 'Upcoming'),
            investedCount: item?.investmentsCount ? item?.investmentsCount.payment : 0,
            interestAndInvestedCount: item?.investmentsCount ? item?.investmentsCount.interest + item?.investmentsCount.payment : 0,
        })
    });

    return temp;
}

function OfferingsTable({type, columnTitles, menuElements}) {
    const {offerings, isLoading} = useSelector(state => state.offerings);
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOfferings({id: type}));
    }, [dispatch])

    useEffect(() => {
        if (offerings?.length > 0) {
            setRows(getDataMapper(offerings))
        } else {
            setRows([])
        }
    }, [offerings]);

    const showVenture = (params, event) => {
        dispatch(getVenture({id: params.id, status: type}))
            .then(r => {
                dispatch(showVentureModal({id: params[0], name: r.payload.venture.name, type: type}));
            });
    };

    const showVentureInvestors = (params, event) => {
        dispatch(getVentureInvestors({id: params.id}))
            .then(() => {
                dispatch(showVentureInvestorsModal());
            });
    }

    const columns = columnTitles.map((title) => ({
        ...title,
        renderCell: (params) => {
            switch (title.field) {
                case 'name':
                    return (
                        <Box
                            sx={{display: 'inline-block', verticalAlign: 'middle', '&:hover': {cursor: 'pointer'}}}
                            onClick={(event) => showVenture(params, event)}
                        >
                            <img alt={params.row.name.name} style={sxStyles.logoUrl} src={params.row.name.logoUrl} />
                            <Typography
                                component={'span'}
                                sx={sxStyles.name}
                            >
                                {params.row.name.name}
                            </Typography>
                        </Box>
                    );
                case 'valuation':
                    return (
                        <Typography sx={sxStyles.date}>
                            {
                                (
                                    params.row?.valuation > 0
                                        ? priceFormatter(params.row?.valuation, true)
                                        : '-'
                                )
                            }
                        </Typography>
                    );
                case 'offeringSize':
                    return (<Typography sx={sxStyles.date}>
                        {
                            (
                                params.row?.offeringSize > 0
                                    ? priceFormatter(params.row?.offeringSize, true)
                                    : '-'
                            )
                        }
                    </Typography>);
                case 'ticketSize':
                    return (<Typography sx={sxStyles.date}>
                        {
                            (
                                params.row?.ticketSize > 0
                                    ? priceFormatter(params.row?.ticketSize, false)
                                    : '-'
                            )
                        }
                    </Typography>);
                case 'startDate':
                    return (<Typography sx={sxStyles.date}>
                        {moment(params.row.startDate).format('DD MMM YYYY')}
                    </Typography>);
                case 'dueDate':
                    return (<Typography sx={sxStyles.date}>
                        {moment(params.row.dueDate).format('DD MMM YYYY')}
                    </Typography>);
                case 'ticketsSold':
                    return <Typography sx={sxStyles.typo}>
                        {params.row.ticketsSold}
                    </Typography>
                case 'investors':
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${Theme_colors.primaryColor}`,
                                minWidth: '96px',
                                height: '40px',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={(event) => showVentureInvestors(params, event)}
                        >
                            <Typography sx={{
                                ...sxStyles.typo,
                                userSelect: 'none',
                                color: Theme_colors.primaryColor,
                            }}
                            >
                                {params.row.investedCount}
                            </Typography>
                            <Typography sx={{
                                ...sxStyles.typo,
                                userSelect: 'none',
                                textAlign: 'center',
                                width: '16px',
                            }}
                            >
                                /
                            </Typography>
                            <Typography
                                sx={{
                                    ...sxStyles.typo,
                                    userSelect: 'none',
                                    color: Theme_colors.primaryColor,
                                }}
                            >
                                {params.row.interestAndInvestedCount}
                            </Typography>
                        </Box>
                    );
                case 'status':
                    return (
                        <Typography
                            sx={{
                                ...sxStyles.typo,
                                color: params.row.status === 'Active'
                                    ? Theme_colors.successDark
                                    : (
                                        params.row.status === 'Upcoming'
                                            ? Theme_colors.black
                                            : Theme_colors.blueDark),
                                backgroundColor:
                                    params.row.status === 'Active'
                                        ? Theme_colors.successLight
                                        : (
                                            params.row.status === 'Upcoming'
                                                ? Theme_colors.greySixth
                                                : Theme_colors.blueLight),
                                fontSize: '13px',
                                fontWeight: 500,
                                padding: '3px 8px',
                            }}
                        >
                            {params.row.status}
                        </Typography>
                    );
                case 'submittedInterestAmount':
                    return (<Typography sx={sxStyles.typo}>
                        {priceFormatter(params.row.submittedInterest, false)}
                    </Typography>);
                case 'investedAmount':
                    return (<Typography sx={sxStyles.typo}>
                        {priceFormatter(params.row.investedAmount, false)}
                    </Typography>);
                case 'publishedMenu':
                    return (<MenuButton rowId={params.row.id} elements={menuElements}/>);
                case 'draftMenu':
                    return (<MenuButton rowId={params.row.id} elements={menuElements}/>);
                default:
                    return null;
            }
        }
    }));

    return (
        <Box>
        {
            rows.length
                ?
                <DataTable
                    options={{
                        textLabels: {
                            body: {
                                noMatch: true ? <CircularProgress/> : 'Sorry, there is no matching data to display',
                            },
                        },
                    }}
                    sx={{
                        mt: '40px',
                        '&.MuiDataGrid-root': {
                            color: Theme_colors.greySixth,
                            border: 'none',
                            borderRadius: 'none',
                            fontSize: '16px',
                        },
                    }}
                    columns={columns}
                    rows={rows}
                />
                :
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {
                        isLoading || offerings === null
                            ?
                            <CircularProgress sx={{mt: 14}}/>
                            :
                            <Box className={'smooth-appear'} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <h2 style={{fontWeight: 500}}>There are no ventures</h2>
                                <InfoIcon style={{width: '32px', height: '32px', display: 'block'}}/>
                                <ActionButton onClick={() => dispatch(showModal())} sx={{mt: '20px',}}>
                                    Create venture
                                </ActionButton>
                            </Box>
                    }
                </Box>
        }
    </Box>);
}

export default OfferingsTable;