import React from 'react';
import PropTypes from 'prop-types';
import {styled} from "@mui/material/styles";
import {TextField} from "@mui/material";
import {Theme_colors} from "../../style/theme_colors";

const InputField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        backgroundColor: `${Theme_colors.white} !important`,
        background: 'white',
        height: '48px',
        '& fieldset': {
            borderColor: Theme_colors.greyThird,
            boxShadow: 'none',
        },
    },
    '& .Mui-focused fieldset': {
        // Remove focus background
        boxShadow: 'none',
    },
    '& .MuiAccourdionSummary-root': {
        backgroundColor: 'none',
    },
});

// function InputField2(props) {
//     return <StyledTextField {...props}/>;
// }

export default InputField;