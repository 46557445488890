import {Typography, Box, Button, MenuList, Divider, Link} from "@mui/material";
import { ReactComponent as ExclamationIcon } from "../../../style/icons/exclamation-mark.svg";
import { Theme_colors } from "../../../style/theme_colors";
import { calcDateAndTime } from "../../../utils/calulateDate";
import DataTable from "../../../common/DataTable";
import { ReactComponent as Dots } from "../../../style/icons/dots.svg";
import { ReactComponent as BlackDots } from "../../../style/icons/black-dots.svg";
import { MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "../../../common/modal/Modal";
import BillActionsView from "./modal/components/BillActionsView";
import { showModal } from "../../../redux/features/notifications/modalSlice";
import { useDispatch, useSelector } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {MAIN_CURRENCY} from '../../../helpers/globals';
import MenuButton from "../../../common/MenuButton";
import {dateFormatter, priceFormatter} from "../../../helpers/helpers";

const sxStyles = {
    ventureWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        borderRadius: '40px 40px 40px 40px',
        marginRight: '8px'
    },
    typo: {
        fontSize: '16px',
        color: Theme_colors.default,
    },
    button: {
        borderRadius: 0,
        minWidth: '40px',
        width: '40px',
        height: '40px',
        border: `1px solid ${Theme_colors.greyThird}`,
        '&:hover': { backgroundColor: 'inherit', }
    },
    listItem: {
        fontSize: '16px',
        fontWeight: 500,
    },
    statusSubmitted: {
        color: Theme_colors.black,
        backgroundColor: Theme_colors.greyNinth,
        fontSize: '13px',
        fontWeight: 500,
        padding: '3px 8px',
    },
    Pending: {
        color: Theme_colors.aquaThird,
        backgroundColor: Theme_colors.aqua,
        fontSize: '13px',
        fontWeight: 500,
        padding: '3px 8px',
    },
    Paid: {
        color: Theme_colors.successDark,
        backgroundColor: Theme_colors.successLight,
        fontSize: '13px',
        fontWeight: 500,
        padding: '3px 8px',
    },
    Expired: {
        color: Theme_colors.dangerDark,
        backgroundColor: Theme_colors.dangerLight,
        fontSize: '13px',
        fontWeight: 500,
        padding: '3px 8px',
    },
    Rejected: {
        color: Theme_colors.black,
        backgroundColor: Theme_colors.greyThird,
        fontSize: '13px',
        fontWeight: 500,
        padding: '3px 8px',
    },
};

function getInvestments(investments, rows) {
    investments?.map((item, index) => {
        console.log('item: ', item);
        rows.push({
            id: item.ventureInfo.id,
            venture: item.ventureInfo.name,
            ventureId: item.ventureInfo.id,
            logo: item.ventureInfo.logoUrl,
            equity: item.equity,
            date: calcDateAndTime(item.date),
            tickets: item.ticketsInfo.investorTickets,
            totalTickets: item.ticketsInfo.totalTickets,
            status: item.investmentStatus,
            reference: item.billReference,
            isOpen: false,
            transactionId: item.transactionId,
            index: ++index,
            saft: item.saftLink,
            submittedInterest: priceFormatter(item.submittedInterestAmount),
            investedAmount: item.investmentStatus === 'Paid' ? priceFormatter(item.submittedInterestAmount) : '-',
        });
    });
    return rows;
};

function getStatusText(status) {
    switch (status) {
        case 'Pending':
            return 'Investment intent';
        case 'Paid':
            return 'Invested';
        case 'Rejected':
            return 'Rejected';
        case 'Expired':
            return 'Expired';
        default:
            return 'Submitted interest';
    }
};


export default function InvestmentsAndInterest({investments}) {
    const {investorInfo} = useSelector((state) => state.investors);
    const [rows, setRows] = useState(getInvestments(investorInfo.investments, []));
    const [investmentRow, setInvestmentRow] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        setRows(getInvestments(investorInfo.investments, []));
    }, [investorInfo.investments]);

    const handleClose = (rowId) => {
        const rowIndex = rows.findIndex((row) => row.id === rowId);
        if (rowIndex !== -1) {
            const updatedRows = [...rows];
            updatedRows[rowIndex].isOpen = false;
            setRows(updatedRows);
        }
    };

    const confirmOrReject = (row, action) => {
        console.log('row: ', row);
        setInvestmentRow(prev => (
            {
                action: action,
                venture: {
                    id: row.ventureId,
                    name: row.venture,
                },
                investment: {
                    transactionId: row.transactionId,
                    amount: row.submittedInterest,
                },
            }
        ));
        handleClose(row.id);
        dispatch(showModal());
    };

    // TODO:
    const menuElements = (menuElementsRow) => {
        const elements = [];

        if (menuElementsRow.status === null) {
            // TODO: enable when approve interest by admin endpoint is done
            // elements.push({
            //     title: 'Approve',
            //     onClick: (row) => confirmOrReject(row, 'approve'),
            // });
        }

        if ([null].includes(menuElementsRow.status)) {
            elements.push({
                title: 'Adjust amount',
                onClick: (row) => confirmOrReject(row, 'adjust'),
            });
        }

        if ([null, 'Pending'].includes(menuElementsRow.status)) {
            elements.push({
                title: 'Reject',
                onClick: (row) => confirmOrReject(row, 'reject'),
            });
        }

        if (![null, 'Pending'].includes(menuElementsRow.status)) {
            elements.push({
                title: 'Renew',
                onClick: (row) => confirmOrReject(row, 'generate'),
            });
        }

        return elements;
    }

    const [columnTitles, setColumnTitles] = useState([
        { field: 'venture', headerName: 'Ventures', width: 320, sortable: false },
        { field: 'date', headerName: 'Date', width: 160, sortable: true },
        { field: 'tickets', headerName: 'Tokens', width: 160, align: "right", headerAlign: "right", sortable: false, },
        { field: 'equity', headerName: 'Allocation share', width: 160, align: "right", headerAlign: "right", sortable: false },
        { field: 'saft', headerName: 'SAFT', width: 100, align: "right", headerAlign: "right", sortable: false },
        { field: 'submittedInterest', headerName: 'Submitted interest', width: 160, align: "right", headerAlign: "right", sortable: false },
        { field: 'invested', headerName: 'Invested', width: 160, align: "right", headerAlign: "right", sortable: false },
        { field: 'reference', headerName: 'Txn hash', width: 100, sortable: false, align: "right", headerAlign: "right" },
        { field: 'status', headerName: 'Status', width: 160, align: "right", headerAlign: "right", sortable: true, },
        // { field: 'action', headerName: 'Actions', width: 72, sortable: false, align: "right", headerAlign: "right" },
    ]);

    const handleStatusColumnSort = () => {
        const updatedRows = [...rows];
        const isColumnSorted = columnTitles.find((column) => column.field === 'status').sortable;
        const isColumnSortedAscending = columnTitles.find((column) => column.field === 'status').sortDirection === 'asc';

        updatedRows.sort((a, b) => {
            const statusOrder = {
                'Submitted interest': 1,
                'Investment intent': 2,
                'Invested': 3,
                'Expired': 4,
                'Rejected': 5,
            };

            if (isColumnSorted && isColumnSortedAscending) {
                return statusOrder[getStatusText(b.status)] - statusOrder[getStatusText(a.status)];
            } else {
                return statusOrder[getStatusText(a.status)] - statusOrder[getStatusText(b.status)];
            }
        });
        const updatedColumnTitles = columnTitles.map((column) => {
            if (column.field === 'status') {
                return {
                    ...column,
                    sortable: true,
                    sortDirection: isColumnSortedAscending ? 'desc' : 'asc',
                };
            }
            return column;
        });
        setRows(updatedRows);
        setColumnTitles(updatedColumnTitles);
    };

    const columns = columnTitles.map((title) => ({
        ...title,
        renderCell: (params) => {
            switch (title.field) {
                case 'venture':
                    return (
                        <Box sx={sxStyles.ventureWrapper}>
                            <img src={params.row.logo} alt='' width='32px' height='32px' style={sxStyles.logo} />
                            <Typography sx={{ fontSize: '14px', color: Theme_colors.default }}>
                                {params.row.venture}
                            </Typography>
                        </Box>
                    );
                case 'date':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {dateFormatter(params.row.date)}
                        </Typography>
                    );
                case 'equity':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {params.row.equity}%
                        </Typography>
                    );
                case 'tickets':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {params.row.tickets}
                        </Typography>
                    );
                case 'saft':
                    return (
                        (params.row.saft && params.row.saft !== '')
                            ?
                            <Link
                                href={params.row.saft}
                                target={'_blank'}
                                style={sxStyles.link}
                                sx={{'&:hover': {cursor: 'pointer'}, textDecoration: 'none'}}
                            >
                                <Typography sx={{...sxStyles.typo, color: Theme_colors.primaryColor}}>
                                    Link
                                </Typography>
                            </Link>
                            :
                            <Typography sx={sxStyles.typo}>
                                -
                            </Typography>
                    )
                case 'submittedInterest':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {params.row.submittedInterest}
                        </Typography>);
                case 'invested':
                    return (
                        <Typography sx={sxStyles.typo}>
                            {params.row.investedAmount}
                        </Typography>);
                case 'reference':
                    return (
                        params.row?.reference && params.row.reference !== ''
                        ?
                        <Link
                            href={params.row.reference}
                            target={'_blank'}
                            style={sxStyles.link}
                            sx={{'&:hover': {cursor: 'pointer'}, textDecoration: 'none'}}
                        >
                            <Typography sx={{...sxStyles.typo, color: Theme_colors.primaryColor}}>
                                Link
                            </Typography>
                        </Link>
                        :
                        <Typography sx={sxStyles.typo}>
                            -
                        </Typography>
                    );
                case 'status':
                    return (
                        <Typography sx={params.row.status ? sxStyles[params.row.status] : sxStyles.statusSubmitted}>
                            {getStatusText(params.row.status)}
                        </Typography>);
                // case 'action':
                //     return (
                //         <MenuButton
                //             rowId={params.row}
                //             elements={menuElements(params.row)}
                //         />
                //     );
                default:
                    return null;
            }
        },
    }));

    return (
        investments?.length
            ?
            <>
                <DataTable
                    sx={{
                        position: 'relative', zIndex: 1150,
                        mt: '40px',
                        '&.MuiDataGrid-root': {
                            color: Theme_colors.greySixth,
                            border: 'none',
                            borderRadius: 'none',
                            fontSize: '16px',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            padding: '0px 0px 130px 0px',
                        },
                    }}
                    pageSize={10}
                    columns={columns}
                    rows={rows}
                    handleStatusColumnSort={handleStatusColumnSort}
                />
                <Modal
                    content={BillActionsView}
                    info={investmentRow}
                />
            </>
            :
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5 }}>
                <Box sx={{ maxWidth: '320px', }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <ExclamationIcon width='32px' />
                        <Typography sx={{ fontSize: '24px', color: Theme_colors.default, fontWeight: '500', m: '24px 0' }}>
                            {'There are no investments or submitted interest'}
                        </Typography>
                    </Box>
                </Box>
            </Box>
    )
}
