import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {showNotification} from "../notifications/notificationSlice";
import axios from "axios";
import {store} from "../../store";
import {serviceEnvBaseUrl} from "../../../helpers/helpers";

const initialState = {
    data: null,
    validatedForms: {
        workflowSettings: false,
        general: false,
        contactUs: false,
    },
    venture: {
        id: "",
        name: "",
        startDate: '',
        dueDate: '',
        isDisclosed: false,
        // createdAt: '',
        logoUrl: '',
        coverUrl: '',
        description: '',
        industry: '',
        offeringDetails: {
            ticketsTotal: '',
            ticketSize: '',
            valuation: '',
            equity: '',
            ticketsPerPerson: '',
            offeringSize: '',
            tokensPrefix: '',
        },
        workflowSettings: {
            preliminaryContract: null,
            intermediaryContract: null,
            finalContract: null,
            sourceOfFundsRequired: false,
            adminApprovalRequired: true,
            saftContractRequired: true,
            saftContract: null,
            walletForTransfer: '',
            stepOne: '',
            stepTwo: '',
            stepThree: '',
            stepFour: '',
            trnDueTime: '',
        },
        tags: [],
        team: [],
        documents: [],
        summary: [],
        contactDetails: {
            email: '',
            phone: '',
            address: {
                country: '',
                city: '',
                streetAndNumber: '',
            },
            websiteUrl: '',
            linkedInUrl: '',
            twitterUrl: '',
            callBookingUrl: '',
            telegramUrl: '',
            discordUrl: '',
        },
    },
    ventureInvestors: {
        venture: {},
        investors: [],
    },
    open: false,
    isLoading: false,
    hasError: false,
    ventureModalId: null,
    openVentureInvestorsModal: false,
    type: '',
};


export const addVenture = createAsyncThunk('manageOfferings/add', async (data, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    return await axios.post(serviceEnvBaseUrl() + `/ventures`, {
        name: data.name
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            dispatch(setVentureId({id: response.data.id}));
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const editVenture = createAsyncThunk('manageOfferings/edit', async ({venture, id}, {
    dispatch,
    rejectWithValue
}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    // return await axios.put(serviceEnvBaseUrl() + `/ventures/${id}`, {...venture, ...{id: id}}, {
    return await axios.post(serviceEnvBaseUrl() + `/ventures/update/${id}`, {...venture, ...{id: id}}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const publishVenture = createAsyncThunk('manageOfferings/publish', async ({id}, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    return await axios.put(serviceEnvBaseUrl() + `/ventures/${id}/publish`, {}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const getVenture = createAsyncThunk('manageOfferings/get-venture', async ({id, status}, {
    dispatch,
    rejectWithValue
}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    return await axios.get(serviceEnvBaseUrl() + `/admin/ventures/${id}?status=${status}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const getVentureInvestors = createAsyncThunk('manageOfferings/get-venture/investors', async ({id}, {
    dispatch,
    rejectWithValue
}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    return await axios.get(serviceEnvBaseUrl() + `/admin/ventures/investments/${id}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            console.log('response: ', response);
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const manageOfferingsSlice = createSlice({
    name: 'add-offerings', initialState, extraReducers: (builder) => {
        const pendingAndRejectedCases = (state, action) => {
            state.hasError = action.error;
            state.isLoading = action.meta.requestStatus === 'pending';
        };
        builder
            .addCase(addVenture.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(editVenture.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(publishVenture.fulfilled, (state, action) => {
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(getVenture.fulfilled, (state, action) => {
                const venture = action.payload.venture;

                // Response can return null values of Drafts
                // Set default values to avoid issues in Form components
                if (venture.workflowSettings.adminApprovalRequired === null)
                    venture.workflowSettings.adminApprovalRequired = state.venture.workflowSettings.adminApprovalRequired;

                if (venture.workflowSettings.saftContractRequired === null)
                    venture.workflowSettings.saftContractRequired = state.venture.workflowSettings.saftContractRequired;

                if (venture.workflowSettings.walletForTransfer === null)
                    venture.workflowSettings.walletForTransfer = state.venture.workflowSettings.walletForTransfer;

                if (venture.workflowSettings.stepOne === null)
                    venture.workflowSettings.stepOne = state.venture.workflowSettings.stepOne;

                if (venture.workflowSettings.stepTwo === null)
                    venture.workflowSettings.stepTwo = state.venture.workflowSettings.stepTwo;

                if (venture.workflowSettings.stepThree === null)
                    venture.workflowSettings.stepThree = state.venture.workflowSettings.stepThree;

                if (venture.workflowSettings.stepFour === null)
                    venture.workflowSettings.stepFour = state.venture.workflowSettings.stepFour;

                if (venture.workflowSettings.trnDueTime === null)
                    venture.workflowSettings.trnDueTime = state.venture.workflowSettings.trnDueTime;

                if (venture.workflowSettings.tgeDate === null)
                    venture.workflowSettings.tgeDate = state.venture.workflowSettings.tgeDate;

                if (venture.workflowSettings.vestingDate === null)
                    venture.workflowSettings.vestingDate = state.venture.workflowSettings.vestingDate;

                if (venture.offeringDetails.tokensPrefix === null)
                    venture.offeringDetails.tokensPrefix = state.venture.offeringDetails.tokensPrefix;

                if (venture.description === null)
                    venture.description = state.venture.description;

                if (venture.industry === null)
                    venture.industry = state.venture.industry;

                if (venture.tags === null)
                    venture.tags = state.venture.tags;

                if (venture.offeringDetails.valuation === null)
                    venture.offeringDetails.valuation = state.venture.offeringDetails.valuation;

                if (venture.offeringDetails.equity === null)
                    venture.offeringDetails.equity = state.venture.offeringDetails.equity;

                if (venture.offeringDetails.ticketSize === null)
                    venture.offeringDetails.ticketSize = state.venture.offeringDetails.ticketSize;

                if (venture.offeringDetails.valuation === null)
                    venture.offeringDetails.valuation = state.venture.offeringDetails.valuation;

                if (venture.offeringDetails.ticketsPerPerson === null)
                    venture.offeringDetails.ticketsPerPerson = state.venture.offeringDetails.ticketsPerPerson;

                if (venture.offeringDetails.offeringSize === null)
                    venture.offeringDetails.offeringSize = state.venture.offeringDetails.offeringSize;

                if (venture.offeringDetails.tokensPrefix === null)
                    venture.offeringDetails.tokensPrefix = state.venture.offeringDetails.tokensPrefix;

                if (venture.offeringDetails.startDate === null)
                    venture.offeringDetails.startDate = state.venture.offeringDetails.startDate;

                if (venture.offeringDetails.dueDate === null)
                    venture.offeringDetails.dueDate = state.venture.offeringDetails.dueDate;

                if (venture.offeringDetails.ticketsTotal === null)
                    venture.offeringDetails.ticketsTotal = state.venture.offeringDetails.ticketsTotal;

                if (venture.summary.length > 0) {
                    for (let i = 0; i < venture.summary.length; i++) {
                        if (!venture.summary[i].description && !venture.summary[i].title && !venture.summary[i].icon) {
                            venture.summary.splice(i, 1)
                        }
                    }
                }

                state.venture = venture;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(getVentureInvestors.fulfilled, (state, action) => {
                state.ventureInvestors = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
        builder
            .addMatcher((action) => [
                addVenture.pending.type,
                editVenture.pending.type,
                publishVenture.pending.type,
                getVenture.pending.type,
            ].includes(action.type), pendingAndRejectedCases)
            .addMatcher((action) => [
                addVenture.rejected.type,
                editVenture.rejected.type,
                publishVenture.rejected.type,
                getVenture.rejected.type,
            ].includes(action.type), pendingAndRejectedCases)
    },
    reducers: {
        showVentureModal: (state, action) => {
            return {...state, ...action.payload, open: true}
        },
        closeVentureModal: (state, action) => {
            return {
                ...state, ...initialState, ...action.payload, open: false,
            }
        },
        addTeamMember: (state, action) => {
            const tempObj = action.payload;
            tempObj.id = state.venture.team.length + 1;
            state.venture.team.push(tempObj);
        },
        editTeamMember: (state, action) => {
            const objIndex = state.venture.team.findIndex((obj => obj.id === action.payload.id));
            state.venture.team[objIndex] = {...state.venture.team[objIndex], ...action.payload};
        },
        removeTeamMember: (state, action) => {
            const index = action.payload;
            state.venture.team.splice(index, 1)
        },
        submitContact: (state, action) => {
            const tempObj = {
                email: action.payload.email,
                phone: action.payload.phone,
                address: {
                    country: action.payload.country,
                    city: action.payload.city,
                    streetAndNumber: action.payload.streetAndNumber,
                },
                websiteUrl: action.payload.websiteUrl,
                linkedInUrl: action.payload.linkedInUrl,
                twitterUrl: action.payload.twitterUrl,
                callBookingUrl: action.payload.callBookingUrl,
            }
            state.venture.contactDetails = tempObj;
        },
        setContactField: (state, action) => {
            if (action.payload.field === 'country' || action.payload.field === 'city' || action.payload.field === 'streetAndNumber') {
                state.venture.contactDetails.address[action.payload.field] = action.payload.value;
            } else {
                state.venture.contactDetails[action.payload.field] = action.payload.value;
            }
        },
        setDocument: (state, action) => {
            state.venture.documents.push({
                title: '',
                link: '',
                icon: '',
                // isValidated: false
            });
        },
        removeDocument: (state, action) => {
            const index = action.payload;
            state.venture.documents.splice(index, 1)
        },
        validateDocument: (state, action) => {
            // const index = action.payload;
            // state.venture.documents[index].isValidated = true;
        },
        setDocumentValue: (state, action) => {
            const index = action.payload.index;
            const field = action.payload.field;
            const value = action.payload.value;

            state.venture.documents[index][field] = value;
            // state.venture.documents[index][field] = value;
        },
        addSummary: (state, action) => {
            const expand = state.venture.summary.expand || false;
            state.venture.summary.push({
                title: '',
                description: '',
                icon: '',
                expand: expand,
                // isValidated: false
            });
        },
        removeSummary: (state, action) => {
            const index = action.payload;
            state.venture.summary.splice(index, 1)
        },
        sectionExpand: (state, action) => {
            const index = action.payload.id;
            const currentState = current(state)
            state.venture.summary[index].expand = !currentState.venture.summary[index].expand;
            // state.venture.summary = currentState.venture.summary;
        },
        setSummaryValue: (state, action) => {
            const index = action.payload.index;
            const field = action.payload.field;
            const value = action.payload.value;
            state.venture.summary[index][field] = value;
        },
        setVentureValue: (state, action) => {
            const field = action.payload.field;
            const value = action.payload.value;
            state.venture[field] = value;
        },
        setOfferingValue: (state, action) => {
            const field = action.payload.field;
            const value = action.payload.value;
            state.venture.offeringDetails[field] = value;
        },
        setOfferingField: (state, action) => {
            const field = action.payload.field;
            const value = action.payload.value;
            state.venture.offeringDetails[field] = value;

            // if(field === 'ticketsTotal'  && state.venture.offeringDetails['ticketSize']){
            //     state.venture.offeringDetails['offeringSize'] = state.venture.offeringDetails['ticketSize'] *  state.venture.offeringDetails['ticketsTotal'];
            // }
            //
            // if(field === 'ticketSize'  && state.venture.offeringDetails['ticketsTotal']){
            //     state.venture.offeringDetails['offeringSize'] = state.venture.offeringDetails['ticketSize'] *  state.venture.offeringDetails['ticketsTotal'];
            // }
        },
        setFormValidation: (state, action) => {
            const field = action.payload.field;
            const value = action.payload.value;
            state.validatedForms[field] = value;
        },
        setWorkflowSettings: (state, action) => {
            const field = action.payload.field;
            const value = action.payload.value;
            const name = action.payload.name;
            state.venture.workflowSettings[field] = {
                name: name,
                link: value
            };
        },
        setWorkflowSettingsDirect: (state, action) => {
            const field = action.payload.field;
            const value = action.payload.value;
            state.venture.workflowSettings[field] = value;
        },
        setListValidation: (state, action) => {
            // const index = action.payload.index;
            // const entity = action.payload.entity;
            // const value = action.payload.value;
            // state.validatedForms[entity][index] = {isValidated: value};
        },
        setVentureId: (state, action) => {
            state.venture.id = action.payload.id;
        },
        showVentureInvestorsModal: (state, action) => {
            return {...state, ...action.payload, openVentureInvestorsModal: true};
        },
        closeVentureInvestorsModal: (state, action) => {
            return {
                ...state, ...initialState, ...action.payload, openVentureInvestorsModal: false,
            }
        },
    }
});

export const {
    showVentureModal,
    closeVentureModal,
    addTeamMember,
    removeTeamMember,
    editTeamMember,
    submitContact,
    setContactField,
    validateDocument,
    setDocumentValue,
    setDocument,
    removeDocument,
    addSummary,
    sectionExpand,
    removeSummary,
    setSummaryValue,
    setVentureValue,
    setOfferingField,
    setFormValidation,
    setWorkflowSettings,
    setWorkflowSettingsDirect,
    setListValidation,
    setVentureId,
    showVentureInvestorsModal,
    closeVentureInvestorsModal,
} = manageOfferingsSlice.actions

export default manageOfferingsSlice.reducer