import React, {useEffect, useState} from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {styled} from "@mui/material/styles";
import {
    DialogActions, DialogContent, Dialog, TextField, Menu, MenuItem,
} from "@mui/material";
import ActionButton from "../../../../common/ActionButton";
import {Controller, useForm} from "react-hook-form";
import UploadImage from "../../../../common/UploadImage";
import {useDispatch} from "react-redux";
import {
    addTeamMember, editTeamMember, removeTeamMember
} from "../../../../redux/features/offerings/manageOfferingsSlice";
import {Theme_colors} from "../../../../style/theme_colors";
import {ReactComponent as MoreIcon} from "../../../../style/icons/more-button.svg";
import {Box} from "@mui/system";


const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    }, '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const {children, onClose, ...other} = props;


    return (<DialogTitle sx={{m: 0, p: 2}} {...other}>
        {children}
        {onClose ? (<IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon/>
        </IconButton>) : null}
    </DialogTitle>);
}

function AddTeamMemberModal({open, setOpen, member, index}) {

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const openRemoveMenu = Boolean(anchorEl);
    const [data, setData] = useState({
        name: '', jobTitle: '', photoUrl: '', linkedInUrl: '',
    });
    const {
        control,
        handleSubmit,
        formState: {errors},
        getValues,
        setValue,
        reset,
    } = useForm({
         mode: "onChange"
    });

    useEffect(() => {

        if (member && Object.values(member).length > 0) {
            setValue('name', member.name);
            setValue('jobTitle', member.jobTitle);
            setValue('photoName', member.photoName);
            setValue('photoUrl', member.photoUrl);
            setValue('linkedInUrl', member.linkedInUrl);
            setData(member);
        }
    }, [member]);


    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open]);


    const handleRemoveElement = () => {
        dispatch(removeTeamMember(index));
        setOpen(false);
    }

    const onSubmit = data => {
        const memberValues = getValues();

        // if (Object.keys(errors).length === 0) {
        if (!member) {
            dispatch(addTeamMember(memberValues));
            setOpen(false);
        } else {
            dispatch(editTeamMember({
                ...memberValues, id: member.id,
            }));
            setOpen(false);
        }
        // }
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    }


    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenRemoveMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClickCloseRemoveMenu = () => {
        setAnchorEl(null);
    };


    return (<BootstrapDialog
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
    >
        <Box onSubmit={handleSubmit(onSubmit)} component="form">
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Add Team Member
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Controller
                    name="name"
                    control={control}
                    rules={{required: true}}
                    render={({field, onChange}) => {
                        return (<TextField
                            name={'name'}
                            value={data.name}
                            onChange={(e) => {
                                field.onChange(e.target.value)
                                handleChange(e);
                            }}
                            fullWidth={true}
                            id="name"
                            label="Name"
                            error={Boolean(errors.name)}
                            // helperText={errors.name && "Name is required"}
                            sx={{mb: 2}}
                        />);
                    }}
                />

                <Controller
                    name="photoUrl"
                    control={control}
                    rules={{required: true}}
                    render={({field}) =>
                        <UploadImage existingImage={member?.photoUrl} propName={'photoUrl'}
                                     name={'Team Member Image'}
                                     setValue={setValue} error={errors.photoUrl} sx={{mb: 2}}/>}
                />

                <Controller
                    name="jobTitle"
                    control={control}
                    rules={{required: true}}
                    render={({field}) =>
                        <TextField
                            name={'jobTitle'}
                            value={data.jobTitle}
                            onChange={(e) => {
                                field.onChange(e.target.value)
                                handleChange(e);
                            }}
                            fullWidth={true}
                            error={Boolean(errors.jobTitle)}
                            // helperText={errors.jobTitle && "Job title is required"}
                            label="Job title"
                            id="outlined-start-adornment"
                            sx={{mb: 2}}
                        />}
                />

                <Controller
                    name="linkedInUrl"
                    control={control}
                    rules={{required: true}}
                    render={({field}) =>
                        <TextField
                            name={'linkedInUrl'}
                            value={data.linkedInUrl}
                            onChange={(e) => {
                                field.onChange(e.target.value)
                                handleChange(e);
                            }}
                            fullWidth={true}
                            error={Boolean(errors.linkedInUrl)}
                            // helperText={errors.linkedIn && "LinkedIn is required"}
                            label="LinkedIn URL"
                            id="outlined-start-adornment"
                            sx={{mb: 2}}
                        />}
                />
            </DialogContent>
            <DialogActions>
                {<Box sx={{
                    display: 'block', width: '100%',
                }}>
                    <div>
                        {member && <MoreIcon style={{
                            float: 'left', marginLeft: '10px',
                        }} onClick={handleClickOpenRemoveMenu} className={'more-icon'}/>}
                        <Menu
                            anchorOrigin={{
                                vertical: 'bottom', horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top', horizontal: 'left',
                            }}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openRemoveMenu}
                            onClose={handleClickCloseRemoveMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent', color: Theme_colors.dragonOrange
                                }
                            }} onClick={handleRemoveElement}>Remove section</MenuItem>
                        </Menu>
                    </div>
                    <Box sx={{
                        display: 'block', float: 'right'
                    }}>
                        <ActionButton style={{
                            marginRight: '10px', height: '48px'
                        }} variant={'outlined'}
                                      type={'button'}
                                      onClick={handleClose}>
                            Cancel
                        </ActionButton>
                        {!member ? <ActionButton style={{
                            marginRight: '10px', height: '48px'
                        }} autoFocus
                                                 type={'submit'}>
                            Save changes
                        </ActionButton> : <ActionButton style={{
                            marginRight: '10px', height: '48px'
                        }} autoFocus
                                                        type={'submit'}>
                            Edit member
                        </ActionButton>}
                    </Box>
                </Box>}

            </DialogActions>
        </Box>
    </BootstrapDialog>);
}

export default AddTeamMemberModal;