import React, {useEffect, useState} from 'react';
import { Box } from "@mui/system";
import {DialogActions, DialogContent, TextField, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import ActionButton from "../../../../../common/ActionButton";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../../redux/features/notifications/modalSlice";
import { Theme_colors } from '../../../../../style/theme_colors';
import { confirmPayment, generateNewBill, rejectPayment } from '../../../../../redux/features/investors/paymentsSlice';
import { updateInterestAmount } from '../../../../../redux/features/investors/investorsSlice';
import { getInvestorById, approveInterest } from '../../../../../redux/features/investors/investorsSlice';
import { useFormUniversal } from '../../../../../utils/useFormUniversal';
import { getVentureInvestors, showVentureInvestorsModal } from "../../../../../redux/features/offerings/manageOfferingsSlice";

const sxStyles = {
    button: {
        position: 'relative',
        borderRadius: 0,
        minWidth: '40px',
        width: '40px',
        height: '40px',
        border: `1px solid ${Theme_colors.greyThird}`,
        '&:hover': { backgroundColor: 'inherit', }
    },
    menu: {
        position: 'absolute',
        top: '-104px',
        p: '0em 0em',
        alignItems: 'center',
        borderRadius: 0,
        '& .MuiPaper-root': { borderRadius: 0, },
    },
    listItem: {
        '&:hover': { backgroundColor: 'inherit', },
        p: '0 16px',
        fontWeight: '500',
        lineHeight: '16px',
        fontSize: '16px',
    },
}

function BootstrapDialogTitle({ children, onClose, ...other }) {
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function BillActionsView({info}) {
    const dispatch = useDispatch();
    const initialFormState = {
        adjustedAmount: {
            value: null,
            validationRules: {
                required: true,
                validateAmount: true,
            }
        },
    };
    const [formValues, setFormValues] = useState(initialFormState);
    const {isFormValid, errors, updateValues} = useFormUniversal(formValues, setFormValues);

    const approve = () => {
        dispatch(approveInterest({ transactionId: info.investment.transactionId }))
            .then(() => {
                dispatch(getVentureInvestors({id: info.venture.id}))
                    .then(() => {
                        dispatch(showVentureInvestorsModal());
                    });
            });
        dispatch(closeModal());
    }

    const reject = () => {
        dispatch(rejectPayment({ ventureId: info.venture.id, transactionId: info.investment.transactionId }))
            .then(() => {
                dispatch(getVentureInvestors({id: info.venture.id}))
                    .then(() => {
                        dispatch(showVentureInvestorsModal());
                    });
            });
        dispatch(closeModal());
    };

    const confirm = () => {
        dispatch(confirmPayment({ ventureId: info.venture.id, transactionId: info.investment.transactionId }))
            .then(() => {
                dispatch(getVentureInvestors({id: info.venture.id}))
                    .then(() => {
                        dispatch(showVentureInvestorsModal());
                    });
            });
        dispatch(closeModal());
    };

    const generateNewTransfer = () => {
        // dispatch(generateNewBill({ ventureId: info.id, transactionId: info.transactionId }))
        //     .then(() => dispatch(getInvestorById(investorInfo.investor.id)));
        // dispatch(closeModal());
    };

    const adjustInterestAmount = (e) => {
        e.preventDefault();
        if (isFormValid) {
            dispatch(updateInterestAmount({
                amount: formValues.adjustedAmount.value,
                ventureId: info.venture.id,
                investorId: info.investor.id,
            }))
                .then(() => {
                    dispatch(getVentureInvestors({id: info.venture.id}))
                        .then(() => {
                            dispatch(showVentureInvestorsModal());
                        });
                });
            dispatch(closeModal());
        } else {
            for (let field in formValues) {
                if (formValues[field].value === null) {
                    updateValues({ [field]: { ...formValues[field], value: '' } });
                }
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateValues({ [name]: { ...formValues[name], value } });
        setFormValues(prev => {
            return {
                ...prev, ...{
                    [name]: { ...prev[name], value: value }
                }
            }
        })
    }

    return (
        <Box>
            <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={() => dispatch(closeModal())}
            >
                {
                    info.action === 'approve'
                    ?
                    'Approve interest'
                    :
                    (
                        info.action === 'reject'
                            ? 'Reject investment'
                            :
                            (info.action === 'confirm'
                                    ?
                                    'Confirm investment'
                                    :
                                    (
                                        info.action === 'renew'
                                            ?
                                            'Renew token transfer time'
                                            :
                                            'Adjust submitted interest'
                                    )
                            )
                    )
                }
            </BootstrapDialogTitle>
            <DialogContent
                dividers
                sx={{minHeight: '144px'}}

            >
                {
                    info.action === 'approve'
                    &&
                    <Typography>
                        Are you sure you want to approve the interest amount of {info.investment.amount} for “{info.venture.name}”?
                    </Typography>
                }
                {
                    info.action === 'reject'
                    &&
                    <>
                        <Typography>
                            Are you sure you want to reject the Submitted interest / Investment of {info.investment.amount} for “{info.venture.name}” venture?
                        </Typography>
                        <Typography sx={{marginTop: 1}}>
                            The investor will be returned to the initial state and the option to “Submit interest” will be active.
                        </Typography>
                        <Typography sx={{color: Theme_colors.dangerDark, marginTop: 1}}>
                            This action cannot be undone.
                        </Typography>
                    </>
                }
                {
                    info.action === 'adjust'
                    &&
                    <TextField
                        name={'adjustedAmount'}
                        sx={sxStyles.inputItem}
                        fullWidth
                        variant="outlined"
                        autoFocus
                        helperText={(errors.adjustedAmount.helperText) ? errors.adjustedAmount.helperText : null}
                        error={errors.adjustedAmount.error}
                        value={formValues.adjustedAmount.value || ""}
                        onChange={handleChange}
                        InputProps={{ inputProps: { style: { textAlign: 'start', }, }, style: { borderRadius: 0, }, }}
                    />
                }
                {
                    info.action === 'confirm'
                    &&
                    <Typography>
                        `Are you sure you want to approve the investment amount of {info.investment.amount} for “{info.venture.name}” venture from investor ${info.investor.walletAddress}?`
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                <ActionButton
                    sx={{ height: '40px' }}
                    variant={'outlined'}
                    onClick={() => dispatch(closeModal())}
                >
                    CANCEL
                </ActionButton>
                {
                    info.action === 'approve'
                    &&
                    <ActionButton
                        sx={{ height: '40px' }}
                        variant={'contained'}
                        onClick={() => approve()}
                    >
                        Approve interest
                    </ActionButton>
                }

                {
                    info.action === 'reject'
                    &&
                    <ActionButton
                        sx={{ height: '40px' }}
                        variant={'contained'}
                        onClick={() => reject()}
                    >
                        Reject investment
                    </ActionButton>
                }

                {
                    info.action === 'confirm'
                    &&
                    <ActionButton
                        sx={{ height: '40px' }}
                        variant={'contained'}
                        onClick={() => confirm()}
                    >
                        Confirm investment
                    </ActionButton>
                }

                {
                    info.action === 'adjust'
                    &&
                    <ActionButton
                        sx={{ height: '40px' }}
                        variant={'contained'}
                        onClick={(e) => adjustInterestAmount(e)}
                        autoFocus
                    >
                        Save changes
                    </ActionButton>
                }

                {
                    info.action === 'generate'
                    &&
                    <ActionButton
                        sx={{ height: '40px' }}
                        variant={'contained'}
                        onClick={() => generateNewTransfer()}
                        autoFocus
                    >
                        Generate new transfer
                    </ActionButton>
                }
            </DialogActions>
        </Box>
    );
}
