import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {showNotification} from "../notifications/notificationSlice";
import axios from "axios";
import {store} from "../../store";
import {serviceEnvBaseUrl} from "../../../helpers/helpers";

const initialState = {
    investors: null, 
    theme: null, 
    isLoading: true, 
    hasError: false, 
    investorInfo: null
}

export const getInvestors = createAsyncThunk('investors/get-all', async (data, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    return await axios.get(serviceEnvBaseUrl() + `/investors`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const getInvestorById = createAsyncThunk('investors/getInfoById', async (investorId, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    return await axios.get(serviceEnvBaseUrl() + `/investors/${investorId}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message || 'Something whent wrong', type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const blockInvestorById = createAsyncThunk('investors/blockInvestorById', async (investorId, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    return await axios.put(serviceEnvBaseUrl() + `/investors/${investorId}/block`,{}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            dispatch(getInvestorById(investorId));
            dispatch(showNotification({message: response.data.message || 'You have blocked the investor successfully', type: "success"}));
            return response;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const approveInvestorById = createAsyncThunk('investors/approveInvestorById', async (investorId, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;

    return await axios.put(serviceEnvBaseUrl() + `/investors/${investorId}/self-accredit-confirm`,{}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            dispatch(showNotification({message: response.data.message || 'Investor was successfully accredited', type: "success"}));
            return response;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const updateInterestAmount = createAsyncThunk('investor/adjustInterestAmount', async (props, {
    dispatch, rejectWithValue
}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    const {amount, ventureId, investorId} = props;

    return await axios.post(serviceEnvBaseUrl() + `/admin/ventures/update/interest-amount`, {
        amount, ventureId, investorId
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            console.log('response: ', response);
            dispatch(showNotification({message: response.data.message, type: "success"}));
            return response;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const approveInterest = createAsyncThunk('investor/approveInterest', async (props, {
    dispatch, rejectWithValue
}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    const {transactionId} = props;

    return await axios.post(serviceEnvBaseUrl() + `/admin/ventures/approve-interest`, {
        transactionId
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            console.log('response: ', response);
            dispatch(showNotification({message: response.data.message, type: "success"}));
            return response;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const investorsSlice = createSlice({
    name: 'investors', initialState,
    extraReducers: (builder) => {
        const pendingAndRejectedCases = (state, action) => {
            state.hasError = action.error;
            state.isLoading = action.meta.requestStatus === 'pending';
        };
        builder.addCase(getInvestors.fulfilled, (state, action) => {
            state.investors = action.payload;
            sessionStorage.setItem('user-data', JSON.stringify(action.payload))
            state.isLoading = false;
            state.hasError = false;
        })
        builder.addCase(getInvestorById.fulfilled, (state, action) => {
            state.investorInfo = action.payload;
            state.isLoading = false;
            state.hasError = false;
        })
        builder.addCase(blockInvestorById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.hasError = false;
        })
        builder.addCase(updateInterestAmount.fulfilled, (state, action) => {
            state.isLoading = false;
            state.hasError = false;
        })
        builder
            .addMatcher(
                (action) =>
                    [getInvestors.pending.type, 
                    getInvestorById.pending.type,
                    blockInvestorById.pending.type,
                ].includes(action.type),
                pendingAndRejectedCases
            )
        builder
            .addMatcher(
                (action) =>
                    [getInvestors.rejected.type, 
                    getInvestorById.rejected.type,
                    blockInvestorById.rejected.type,
                    ].includes(action.type),
                pendingAndRejectedCases
            )       
    },
    reducers: {}
});

export const {} = investorsSlice.actions

export default investorsSlice.reducer