import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from "react";

export default function BasicSelect({onChange, value, label, options,error}) {

    return (
        <>
            <InputLabel id="simple-select-label">{
                label
            }</InputLabel>
            <Select
                error={error}
                sx={{
                    borderRadius: 0,
                }}
                labelId="simple-select-label"
                id="simple-select"
                value={value}
                label={label}
                onChange={onChange}
            >
                {
                    options?.map((option, index) => {
                        return <MenuItem key={index} value={option}>{option}</MenuItem>
                    })
                }
            </Select>
        </>)
        ;
}