import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {showNotification} from "../notifications/notificationSlice";
import axios from "axios";
import {store} from "../../store";
import {serviceEnvBaseUrl} from "../../../helpers/helpers";

const initialState = {
    data: null, icons:[], theme: null, isLoading: true, hasError: false,
}


export const getAppData = createAsyncThunk('app/getAppData', async (data, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    return await axios.get(serviceEnvBaseUrl() + `/investors/me`,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});

export const getIcons = createAsyncThunk('app/getIcons', async (data, {dispatch, rejectWithValue}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;
    return await axios.get(serviceEnvBaseUrl() + `/ventures/summary/icons`,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
});


export const appSlice = createSlice({
    name: 'app', initialState,
    extraReducers: (builder) => {
        const pendingAndRejectedCases = (state, action) => {
            state.hasError = action.error;
            state.isLoading = action.meta.requestStatus === 'pending';
        };

        builder.addCase(getAppData.fulfilled, (state, action) => {
            state.data = action.payload;
            sessionStorage.setItem('user-data',JSON.stringify(action.payload))
            state.isLoading = false;
            state.hasError = false;
        })
        builder.addCase(getIcons.fulfilled, (state, action) => {
            state.icons = action.payload;
            state.isLoading = false;
            state.hasError = false;
        })
        builder
            .addMatcher(
                (action) =>
                    [getAppData.pending.type,getIcons.pending.type,].includes(action.type),
                pendingAndRejectedCases
            )
        builder
            .addMatcher(
                (action) =>
                    [getAppData.rejected.type,getIcons.rejected.type,].includes(action.type),
                pendingAndRejectedCases
            )
    },
    reducers: {
        testFn: (state, action) => {
            return {...state, test: action.payload}
        },
        setTheme: (state, action)=> {
            return {...state, theme: action.payload}
        }
    }
});

export const {} = appSlice.actions

export default appSlice.reducer