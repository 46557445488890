import React, {useEffect, useImperativeHandle, useState} from 'react';
import {Box} from "@mui/system";
import PaperStyled from "../../../common/styled-components/PaperStyled";
import ImageButton from "../../../style/images/image-button.png";
import {Menu, MenuItem} from "@mui/material";
import {Theme_colors} from "../../../style/theme_colors";
import {ReactComponent as MoreIcon} from "../../../style/icons/more-button.svg";
import AddIconModal from "./modal/AddIconModal";
import InputField from "../../../common/styled-components/InputField";
import {Controller, useForm} from "react-hook-form";
import {
    setDocumentValue,  setListValidation,
} from "../../../redux/features/offerings/manageOfferingsSlice";
import {useDispatch, useSelector} from "react-redux";
import {REGEX_WEBSITE} from "../../../utils/validateData";

const sxStyles = {
    paper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        mb: '24px',
        width: '100%',
    },
    iconWrapper: {
        display: 'block',
        position: 'relative',
        flexShrink: 0,
        width: '48px',
        height: '48px',
        backgroundColor: Theme_colors.white,
        '&:hover> div': {
            cursor: 'pointer',
            backgroundColor: '#0000000f',
            transition: 'all 0.3s ease-in-out',
        }
    },
    imageMask: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}


function AddDocumentView({removeDocumentSection, sectionsRefs, id}, ref) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [openIcons, setOpenIcons] = useState(false);
    const {icons} = useSelector(state => state.app);

    const [icon, setIcon] = useState({
        name: '',
        url: ''
    });

    const [document, setDocument] = useState({
        title: '',
        link: '',
        icon: '',
    })


    const {venture: {documents},validatedForms} = useSelector(state => state.manageOfferings);
    const dispatch = useDispatch();
    const {
        control,
        handleSubmit,
        formState: {errors},
        getValues,
        setValue,
        reset,
    } = useForm({
        mode: "onChange"
    });
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (documents.length > 0) {
            const currentDocument = documents[id];
            setValue('title', currentDocument.title);
            const icon = icons.find(icon => icon.name === currentDocument.icon);
            setValue('icon', icon);
            setValue('link', currentDocument.link);
            setIcon(icon);
        }
    }, [])

    useEffect(() => {
        if (Object.keys(errors).length) {
            dispatch(setListValidation({
                entity: 'documents',
                index: id,
                value: false
            }))
        }
    }, [errors.title, errors.icon, errors.link]);

    useEffect(() => {
        if (documents.length > 0) {
            setDocument(documents[id]);
        }
    }, [documents])

    const submitFormAct = () => {
        handleSubmit(onSubmit)();
    }

    useImperativeHandle(ref, () => ({
        submit: () => submitFormAct()
    }));

    const onSubmit = data => {
        dispatch(setListValidation({
            entity: 'documents',
            index: id,
            value: true
        }))
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenIcons = () => {
        setOpenIcons(true);
    }
    const handleClickCloseIcons = () => {
        setOpenIcons(false);
    }

    const handleRemoveDocumentSection = () => {
        removeDocumentSection(id, sectionsRefs);
    }

    const handleSetIcon = (icon) => {
        setIcon(icon);
        setValue('icon', icon.name);
        dispatch(setDocumentValue({field: 'icon', value: icon.name, index: id}))
    }

    return (
        <Box component={'form'} onSubmit={handleSubmit(onSubmit)} sx={{
            mb: 2,
            borderRadius: 0
        }}>
            <PaperStyled>
                <Box sx={sxStyles.paper}>
                    <Box sx={sxStyles.iconWrapper}>
                        <Box onClick={handleOpenIcons} sx={sxStyles.imageMask}></Box>
                        <Controller
                            name="icon"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({field}) =>
                                <>
                                    {
                                        icon?.name ? <img src={icon?.url} alt={icon?.name} style={{
                                            width: '100%',
                                            height: '100%',
                                            border: '1px solid ' + Theme_colors.greyThird,
                                        }}/> : <img src={ImageButton} alt={'add icon'} style={{
                                            width: '100%',
                                            height: '100%',
                                            border: '1px solid ' + (Boolean(errors.icon) ? Theme_colors.alert : Theme_colors.greyThird),
                                        }}/>
                                    }
                                </>}
                        />

                    </Box>

                    <Controller
                        name="title"
                        control={control}
                        rules={{required: true}}
                        render={({field}) =>
                            <InputField
                                placeholder={'Document name'}
                                name={'title'}
                                value={documents[id].title}
                                onChange={(e) => {
                                    field.onChange(e.target.value)
                                    dispatch(setDocumentValue({field: e.target.name, value: e.target.value, index: id}))
                                }}
                                error={Boolean(errors.title)}
                                sx={{
                                    ml: 2, mr: 2,
                                }} fullWidth={true}/>}
                    />


                    <MoreIcon onClick={handleClick} className={'more-icon'}/>
                    <div>
                        <Menu
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: Theme_colors.dragonOrange
                                }
                            }} onClick={handleRemoveDocumentSection}>Remove section</MenuItem>
                        </Menu>
                    </div>
                </Box>
                <Box sx={sxStyles.paper}>

                    <Controller
                        name="link"
                        control={control}
                        rules={{
                            required: true, pattern: {
                                value: REGEX_WEBSITE,
                                message: "Entered value does not match link format",
                            }
                        }}
                        render={({field}) =>
                            <InputField
                                placeholder={'https://'}
                                name={'link'}
                                value={documents[id].link}
                                onChange={(e) => {
                                    field.onChange(e.target.value)
                                    dispatch(setDocumentValue({field: e.target.name, value: e.target.value, index: id}))
                                }}
                                error={Boolean(errors.link)}
                                sx={{
                                    width: '100%'
                                }} fullWidth={true}/>}
                    />
                </Box>
            </PaperStyled>
            <AddIconModal setIcon={handleSetIcon} onClose={handleClickCloseIcons} open={openIcons}
                          setOpen={setOpenIcons}/>
        </Box>
    );
}

export default React.forwardRef(AddDocumentView);