import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {showNotification} from "../notifications/notificationSlice";
import axios from "axios";
import {store} from "../../store";
import {serviceEnvBaseUrl} from "../../../helpers/helpers";

const initialState = {
    offerings: null, draft: [], theme: null, isLoading: true, hasError: false,
}

const getOfferingsList = async (token, id, dispatch, rejectWithValue) => {
    return await axios.get(serviceEnvBaseUrl() + `/ventures/status?id=${id}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            dispatch(showNotification({message: error.message, type: "error"}));
            return rejectWithValue(error.message);
        });
}

export const getOfferings = createAsyncThunk('offerings/published', async ({id = 'published'}, {
    dispatch,
    rejectWithValue
}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;

    return getOfferingsList(token, id, dispatch, rejectWithValue);
});

export const getDraft = createAsyncThunk('offerings/draft', async ({id = 'unpublished'}, {
    dispatch,
    rejectWithValue
}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;

    return getOfferingsList(token, id, dispatch, rejectWithValue);
});

export const getArchived = createAsyncThunk('offerings/archived', async ({id = 'archived'}, {
    dispatch,
    rejectWithValue
}) => {
    const {accessToken} = store.getState().authUser;
    const token = accessToken;

    return getOfferingsList(token, id, dispatch, rejectWithValue);
});


export const offeringsSlice = createSlice({
    name: 'offerings', initialState,
    extraReducers: (builder) => {
        const pendingAndRejectedCases = (state, action) => {
            state.hasError = action.error;
            state.isLoading = action.meta.requestStatus === 'pending';
        };

        builder.addCase(getOfferings.fulfilled, (state, action) => {
            state.offerings = action.payload;
            state.isLoading = false;
            state.hasError = false;
        })
        builder.addCase(getDraft.fulfilled, (state, action) => {
            state.draft = action.payload;
            state.isLoading = false;
            state.hasError = false;
        })
        builder.addCase(getArchived.fulfilled, (state, action) => {
            state.archived = action.payload;
            state.isLoading = false;
            state.hasError = false;
        })
        builder
            .addMatcher((action) => [
                getOfferings.pending.type,
                getDraft.pending.type,
                getArchived.pending.type,
            ].includes(action.type), pendingAndRejectedCases)
            .addMatcher((action) => [
                getOfferings.rejected.type,
                getDraft.rejected.type,
                getArchived.rejected.type,
            ].includes(action.type), pendingAndRejectedCases)
    },
    reducers: {
        clearOfferings: (state, action) => {
            state.offerings = null;
            // return {...state, test: action.payload}
        },
        setTheme: (state, action) => {
            return {...state, theme: action.payload}
        }
    }
});

export const {clearOfferings} = offeringsSlice.actions

export default offeringsSlice.reducer